import { useCallback, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { WatchView, useShortListViewsMutation, useWatchViewListQuery } from 'gql';
import { usePageFilter } from 'hooks';
import { customToast, truncate } from 'utils';
import { Button, Card, Header } from 'components';

const ViewCard = ({ view }: { view: any }) => {
  return (
    <Card className="flex-1 w-full p-6 rounded-b-none">
      <div className="flex items-center gap-6 relative">
        <img src="/SessionIcon.svg" alt="session-icon" className="min-w-[68px]" />
        <div className="flex flex-col gap-2 w-[calc(100%-200px)] ">
          <div className="text-2xl whitespace-pre-line break-word line-clamp-2">{view.name}</div>
          <div className="text-sm text-gray whitespace-pre-line break-word line-clamp-2">
            {view.description || 'No description available'}
          </div>
          {view.tags && (
            <div className="flex flex-wrap flex-1 gap-2">
              {view.tags.map((tag: { name?: string; slug?: string }, index: number) => (
                <span
                  className="text-white text-sm py-1 px-2 bg-steel-gray rounded-lg break-all"
                  key={index}
                >
                  {tag?.name || tag?.slug}
                </span>
              ))}
            </div>
          )}
        </div>

        <Link
          to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/view/${view.hashId}`}
          target="_blank"
          className="text-lg absolute right-0 cursor-pointer bg-primary-gradient bg-clip-text text-transparent"
        >
          View details
        </Link>
      </div>
      <div className="flex justify-between mt-4">
        <div className="flex flex-col ">
          <span className="text-gray font-text-bold">Publisher</span>{' '}
          <Link
            to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/publisher/${view.publisherAddress}`}
            target="_blank"
            className="cursor-pointer bg-primary-gradient bg-clip-text text-transparent"
          >
            {truncate(view.publisherAddress)}
          </Link>
        </div>
        <div className="flex flex-col">
          <span className="text-gray font-text-bold">Hash Id</span> {truncate(view.hashId)}
        </div>
        <div className="flex flex-col">
          <span className="text-gray font-text-bold">Date created</span>{' '}
          {moment(view.createdAt).format('DD-MM-YYYY')}
        </div>
      </div>
    </Card>
  );
};

export default function ShortlistView() {
  const [{ accessToken }] = useCookies(['accessToken']);
  const [searchView, setSearchView] = useState('');

  const [approveView, { loading: shortlistLoading }] = useShortListViewsMutation();
  const { controller, pageIndex, pageSize, setPageIndex, setMaxPageIndex } = usePageFilter([], 10);

  const {
    data,
    loading: fetchLoading,
    refetch,
  } = useWatchViewListQuery({
    variables: {
      accessToken,
      page: pageIndex + 1,
      limit: pageSize || 10,
      searchTerm: searchView,
    },
    fetchPolicy: 'no-cache',
    onError: (err) => {
      customToast(err.message, true);
    },
    onCompleted(data) {
      if (data?.watchViewList.count) {
        setMaxPageIndex(Math.ceil(data?.watchViewList.count / pageSize) - 1);
      }
    },
  });

  const [approvedView, setApprovedView] = useState<number>();
  const sortedViews = useMemo(() => {
    return [...(data?.watchViewList.views || [])];
  }, [data?.watchViewList]);

  const handleApprove = useCallback(
    (item: any) => {
      approveView({
        variables: {
          view: item,
          accessToken,
        },
        refetchQueries: ['WatchViewList'],
        onCompleted: () => {
          customToast(`Shortlisted ${item?.name}`);
          refetch();
        },
        onError: (err) => {
          customToast(err.message, true);
        },
      });
    },
    [accessToken]
  );

  return (
    <div className="flex w-full flex-col gap-5 text-white">
      <Header className="bg-primary-gradient bg-clip-text text-transparent">
        Shortlist views ({data?.watchViewList.count || 0})
      </Header>
      <div className="flex justify-center items-center gap-2 mt-1 w-full">
        <input
          placeholder="Search View..."
          className="w-52 p-2 rounded-md border border-solid border-steel-gray flex-1 h-10 after:from-[#5b346b] after:to-[#6951a3]"
          name="trip-start"
          autoComplete="off"
          onChange={(e) => {
            setPageIndex(0);
            setSearchView(e.target.value);
          }}
        />
      </div>
      {sortedViews.length ? (
        sortedViews.map((item, index) => {
          return (
            <div className="flex flex-col" key={index}>
              <ViewCard view={item as WatchView} />
              <Button
                variant="primary"
                classNames={{ base: '!rounded-t-none', container: 'text-base font-text-bold' }}
                isLoading={index == approvedView && (shortlistLoading || fetchLoading)}
                onClick={() => {
                  setApprovedView(index);
                  handleApprove(item as WatchView);
                }}
                isDisabled={item.isShortlisted}
              >
                {item.isShortlisted ? 'ShortListed' : 'Approve'}
              </Button>
            </div>
          );
        })
      ) : (
        <div className="text-white flex justify-center text-base">No views available</div>
      )}
      {controller}
    </div>
  );
}
