import {
  CustomLink,
  DollarIcon,
  EntryIcon,
  Eye2Icon,
  GameIcon,
  HeartIcon,
  KeyIcon,
  Link2Icon,
  ProfileWithBGIcon,
  SearchIcon,
  SmartContractIcon,
  TypeFormButton,
} from 'components';
import { useApi, useAuth } from 'context';
import { DeveloperQuestSubType, useSubmittedFeedbackQuestMutation } from 'gql';
import { useQuestPageData } from 'pages/Quests/useQuestPageData';
import { useCallback } from 'react';
import { QuestTaskButton } from './useCommunityQuests';

export enum RewardType {
  FirstTime = 'Rewarded 1st time',
  MultipleTime = 'Rewarded Multiple Times',
  OnceDaily = 'Rewarded Once',
  FiveTimeDaily = 'Rewarded Five Times Daily',
}

export const useDeveloperQuests = () => {
  const { account, userPoints, trackEvent } = useApi();
  const { isSuccessLogin } = useAuth();
  const { completedQuests } = useQuestPageData();

  const [submitUserFeedback] = useSubmittedFeedbackQuestMutation();

  const submitFeedbackHandler = useCallback(() => {
    if (
      account?.email &&
      !completedQuests.find((quest) => quest?.type === DeveloperQuestSubType.SubmittedFeedback)
    )
      submitUserFeedback &&
        submitUserFeedback({ variables: { email: account.email }, refetchQueries: 'active' });
  }, [submitUserFeedback, account?.email, completedQuests]);

  return [
    {
      heading: 'Analog Watch',
      blockText:
        'To claim your ATP Points for completing Watch quests, please connect your Substrate account used on the Watch Portal!',
      blockCondition: !(!account || !!account.walletAddress) && isSuccessLogin,
      tasks: [
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.SignInWithWalletOnTestnet,
          title: 'Sign in with your Substrate Wallet to claim your Watch quest points',
          description:
            'Please connect your Substrate wallet used for Watch quests to claim your points.',
          primaryPoints: '1 ATP',
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.ConnectedWalletOnWatch,
          title: 'Connect your Substrate Wallet on the Watch',
          description: 'Please refer to the guides for assistance with Watch wallet connections.',
          primaryPoints: '1 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/wallet-support',
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  developerQuest: 'Connect your Substrate Wallet on the Watch',
                });
              }}
            >
              Connect Wallet
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.FirstTime,
          type: DeveloperQuestSubType.CreatedApiKey,
          title: 'Create an API Key',
          description: 'Generate an API key using the Watch SDK or on your Watch Portal profile.',
          primaryPoints: '2 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(import.meta.env.VITE_WATCH_FRONTEND_URL, '_blank');
                trackEvent('quest_clicks', {
                  developerQuest: 'Create an API Key Watch Portal',
                });
              }}
            >
              Watch Portal
            </QuestTaskButton>
          ),
          secondaryTaskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://docs.analog.one/documentation/developers/analog-watch/quickstart',
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  developerQuest: 'Create an API Key Watch SDK',
                });
              }}
            >
              Watch SDK
            </QuestTaskButton>
          ),
        },

        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.ListedSmartContract,
          title: 'List Smart Contract',
          description: <div>Refer to the guides for help with listing smart contracts.</div>,
          primaryPoints: '5 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/listing-smart-contracts',
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  developerQuest: 'List Smart Contract',
                });
              }}
            >
              List Smart Contract
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.CreatedView,
          title: 'Build and deploy a View',
          description: <div>Refer to the guides for help with building a View.</div>,
          primaryPoints: '7 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/deploying-views',
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  developerQuest: 'Build a View',
                });
              }}
            >
              Build a View
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.SponsorView,
          title: 'Sponsor/Fund a Unique View',
          description: <div>Refer to the guides for help with funding a View.</div>,
          primaryPoints: '10 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/funding-views',
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  developerQuest: 'Fund a View',
                });
              }}
            >
              Fund a View
            </QuestTaskButton>
          ),
        },

        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.SubmittedViewForGame,
          title: 'Submit a View for Watch Game',
          description:
            "Create a new View to be considered for shortlisting in the Watch Game, or select one you've already deployed.",
          primaryPoints: '5 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(import.meta.env.VITE_WATCH_FRONTEND_URL, '_blank');
                trackEvent('quest_clicks', {
                  developerQuest: 'Submit a View for Watch Game',
                });
              }}
            >
              Submit View
            </QuestTaskButton>
          ),
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.SponsorViewQueriedByUser,
          title: 'When your funded View gets queried by a unique user',
          description:
            'The most recent 5 sponsors get rewarded when the view is queried by a unique user. Check your rewards in your profile page in the Funded Views tab.',
          primaryPoints: '5 ATP',
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.OwnerViewQueriedByUser,
          title: 'When your View gets queried by a unique user',
          description: 'You get rewarded when your View gets queried by a unique user.',
          primaryPoints: '3 ATP',
        },
        {
          rewardType: RewardType.OnceDaily,
          type: DeveloperQuestSubType.QueryView,
          onceInADayReward: true,
          title: 'Query a Unique View',
          description: <div>Refer to the guides for help with querying a View. </div>,
          primaryPoints: '15 ATP',
          taskButton: (
            <QuestTaskButton
              handleClick={() => {
                window.open(
                  'https://docs.analog.one/documentation/developers/analog-watch/developers-guide/querying-data',
                  '_blank'
                );
                trackEvent('quest_clicks', {
                  developerQuest: 'Query a View',
                });
              }}
            >
              Query a View
            </QuestTaskButton>
          ),
        },
      ],
    },
    {
      heading: 'Watch Game',
      tasks: [
        {
          rewardType: RewardType.MultipleTime,
          title: 'Your view has been shortlisted in a Voting Session!',
          description:
            'Every time a View you built gets shortlisted for a Voting Session, you get rewarded with 10 ATP.',
          primaryPoints: '10 ATP',
          pointsRewarded: userPoints?.UserPoints?.viewShortListed,
        },
        {
          rewardType: RewardType.MultipleTime,
          title: 'Your view wins a Voting Session!',
          description:
            'Every time the View you built wins a Voting Session, you get rewarded with 80 ATP.',
          primaryPoints: '80 ATP',
          pointsRewarded: userPoints?.UserPoints?.winnerAsDesigner,
        },
      ],
    },
  ];
};
