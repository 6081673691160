import { HTMLAttributes } from 'types';

export function EyeIcon(props: HTMLAttributes<HTMLOrSVGElement> & { stroke?: string }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M9.03004 14C8.64004 13.43 8.42004 12.74 8.42004 12C8.42004 10.02 10.02 8.41998 12 8.41998C13.98 8.41998 15.58 10.02 15.58 12C15.58 13.98 13.98 15.58 12 15.58"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.56 5.57998C15.87 4.37998 13.97 3.72998 12 3.72998C8.46997 3.72998 5.17997 5.80998 2.88997 9.40998C1.98997 10.82 1.98997 13.19 2.88997 14.6C5.17997 18.2 8.46997 20.28 12 20.28C15.53 20.28 18.82 18.2 21.11 14.6C22.01 13.19 22.01 10.82 21.11 9.40998"
        stroke={props.stroke || 'white'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function ThumbsUpIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M21.48 11.95C21.98 10.55 21.08 9.35003 19.58 9.35003H15.58C14.98 9.35003 14.48 8.85003 14.58 8.15003L15.08 4.95003C15.28 4.05003 14.68 3.05003 13.78 2.75003C12.98 2.45003 11.98 2.85003 11.58 3.45003L7.47998 9.55003"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.47998 18.35L10.58 20.75C10.98 21.15 11.88 21.35 12.48 21.35H16.28C17.48 21.35 18.78 20.45 19.08 19.25L20.32 15.48"
        stroke="white"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.37988 18.35V8.55002C2.37988 7.15002 2.97988 6.65002 4.37988 6.65002H5.37988C6.77988 6.65002 7.37988 7.15002 7.37988 8.55002V18.35C7.37988 19.75 6.77988 20.25 5.37988 20.25H4.37988C2.97988 20.25 2.37988 19.75 2.37988 18.35Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function WinCup(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/bold/cup">
        <g id="cup">
          <path
            id="Vector"
            d="M6.5625 10.6459H5.25C4.60833 10.6459 4.08333 11.1709 4.08333 11.8126V11.9584H3.5C3.26083 11.9584 3.0625 12.1567 3.0625 12.3959C3.0625 12.6351 3.26083 12.8334 3.5 12.8334H10.5C10.7392 12.8334 10.9375 12.6351 10.9375 12.3959C10.9375 12.1567 10.7392 11.9584 10.5 11.9584H9.91667V11.8126C9.91667 11.1709 9.39167 10.6459 8.75 10.6459H7.4375V9.31006C7.29167 9.32756 7.14583 9.33339 7 9.33339C6.85417 9.33339 6.70833 9.32756 6.5625 9.31006V10.6459Z"
            fill="url(#paint0_linear_5335_105267)"
          />
          <path
            id="Vector_2"
            d="M10.7801 6.78984C11.1651 6.644 11.5034 6.40484 11.7717 6.1365C12.3142 5.53567 12.6701 4.81817 12.6701 3.97817C12.6701 3.13817 12.0109 2.479 11.1709 2.479H10.8442C10.4651 1.70317 9.67174 1.1665 8.75008 1.1665H5.25008C4.32841 1.1665 3.53508 1.70317 3.15591 2.479H2.82924C1.98924 2.479 1.33008 3.13817 1.33008 3.97817C1.33008 4.81817 1.68591 5.53567 2.22841 6.1365C2.49674 6.40484 2.83508 6.644 3.22008 6.78984C3.82674 8.28317 5.28508 9.33317 7.00008 9.33317C8.71508 9.33317 10.1734 8.28317 10.7801 6.78984ZM8.65674 4.929L8.29508 5.37234C8.23674 5.4365 8.19591 5.56484 8.20174 5.65234L8.23674 6.224C8.26008 6.574 8.00924 6.75484 7.68258 6.6265L7.15174 6.4165C7.07008 6.38734 6.93008 6.38734 6.84841 6.4165L6.31758 6.6265C5.99091 6.75484 5.74008 6.574 5.76341 6.224L5.79841 5.65234C5.80424 5.56484 5.76341 5.4365 5.70508 5.37234L5.34341 4.929C5.11591 4.66067 5.21508 4.36317 5.55341 4.27567L6.10758 4.13567C6.19508 4.11234 6.30008 4.03067 6.34674 3.95484L6.65591 3.4765C6.84841 3.179 7.15174 3.179 7.34424 3.4765L7.65341 3.95484C7.70008 4.03067 7.80508 4.11234 7.89258 4.13567L8.44674 4.27567C8.78508 4.36317 8.88424 4.66067 8.65674 4.929Z"
            fill="url(#paint1_linear_5335_105267)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5335_105267"
          x1="3.97902"
          y1="9.71553"
          x2="4.80225"
          y2="11.9008"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5335_105267"
          x1="2.64987"
          y1="2.10635"
          x2="5.21426"
          y2="6.33539"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function WinStar(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/bold/star">
        <g id="star">
          <path
            id="Vector"
            d="M3.34829 9.33317C3.41246 9.04734 3.29579 8.639 3.09163 8.43484L1.67413 7.01734C1.23079 6.574 1.05579 6.1015 1.18413 5.69317C1.31829 5.28484 1.73246 5.00484 2.35079 4.89984L4.17079 4.5965C4.43329 4.54984 4.75412 4.3165 4.87662 4.07734L5.87996 2.06484C6.17162 1.48734 6.56829 1.1665 6.99996 1.1665C7.43162 1.1665 7.82829 1.48734 8.11996 2.06484L9.12329 4.07734C9.19912 4.229 9.35662 4.37484 9.52579 4.474L3.24329 10.7565C3.16163 10.8382 3.02162 10.7623 3.04496 10.6457L3.34829 9.33317Z"
            fill="url(#paint0_linear_5335_144857)"
          />
          <path
            id="Vector_2"
            d="M10.9083 8.4351C10.6983 8.6451 10.5816 9.0476 10.6516 9.33344L11.0541 11.0893C11.2233 11.8184 11.1183 12.3668 10.7566 12.6293C10.6108 12.7343 10.4358 12.7868 10.2316 12.7868C9.93414 12.7868 9.58413 12.6759 9.19913 12.4484L7.48997 11.4334C7.22163 11.2759 6.7783 11.2759 6.50997 11.4334L4.8008 12.4484C4.1533 12.8276 3.59913 12.8918 3.2433 12.6293C3.10913 12.5301 3.00997 12.3959 2.9458 12.2209L10.0391 5.1276C10.3075 4.85927 10.6866 4.73677 11.0541 4.80094L11.6433 4.9001C12.2616 5.0051 12.6758 5.2851 12.81 5.69344C12.9383 6.10177 12.7633 6.57427 12.32 7.0176L10.9083 8.4351Z"
            fill="url(#paint1_linear_5335_144857)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5335_144857"
          x1="2.11868"
          y1="2.27408"
          x2="5.52487"
          y2="5.79763"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5335_144857"
          x1="4.0986"
          y1="5.70531"
          x2="6.73512"
          y2="9.58032"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function WinMedal(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/bold/medal-star">
        <g id="medal-star">
          <path
            id="Vector"
            d="M12.396 10.7739L11.4335 11.0014C11.2176 11.0539 11.0485 11.2172 11.0018 11.4331L10.7976 12.2906C10.6868 12.7572 10.0918 12.9031 9.78264 12.5356L8.03847 10.5289C7.89847 10.3656 7.97431 10.1089 8.18431 10.0564C9.21681 9.80557 10.1443 9.22807 10.8268 8.40557C10.9376 8.27141 11.136 8.25391 11.2585 8.37641L12.5535 9.67141C12.9968 10.1147 12.8393 10.6689 12.396 10.7739Z"
            fill="url(#paint0_linear_5335_84851)"
          />
          <path
            id="Vector_2"
            d="M1.57485 10.7739L2.53735 11.0014C2.75318 11.0539 2.92235 11.2172 2.96902 11.4331L3.17318 12.2906C3.28402 12.7572 3.87902 12.9031 4.18818 12.5356L5.93235 10.5289C6.07235 10.3656 5.99652 10.1089 5.78652 10.0564C4.75402 9.80557 3.82652 9.22807 3.14402 8.40557C3.03318 8.27141 2.83485 8.25391 2.71235 8.37641L1.41735 9.67141C0.974018 10.1147 1.13152 10.6689 1.57485 10.7739Z"
            fill="url(#paint1_linear_5335_84851)"
          />
          <path
            id="Vector_3"
            d="M6.99984 1.1665C4.74234 1.1665 2.9165 2.99234 2.9165 5.24984C2.9165 6.09567 3.16734 6.8715 3.599 7.519C4.229 8.45234 5.2265 9.1115 6.38734 9.28067C6.58567 9.31567 6.78984 9.33317 6.99984 9.33317C7.20984 9.33317 7.414 9.31567 7.61234 9.28067C8.77317 9.1115 9.77067 8.45234 10.4007 7.519C10.8323 6.8715 11.0832 6.09567 11.0832 5.24984C11.0832 2.99234 9.25734 1.1665 6.99984 1.1665ZM8.78484 5.1215L8.30067 5.60567C8.219 5.68734 8.17234 5.84484 8.2015 5.9615L8.3415 6.56234C8.45234 7.03484 8.2015 7.2215 7.7815 6.97067L7.19817 6.6265C7.09317 6.56234 6.91817 6.56234 6.81317 6.6265L6.22984 6.97067C5.80984 7.21567 5.559 7.03484 5.66984 6.56234L5.80984 5.9615C5.83317 5.85067 5.79234 5.68734 5.71067 5.60567L5.21484 5.1215C4.929 4.83567 5.02234 4.54984 5.419 4.48567L6.04317 4.38067C6.14817 4.36317 6.27067 4.26984 6.31734 4.1765L6.6615 3.48817C6.84817 3.11484 7.1515 3.11484 7.33817 3.48817L7.68234 4.1765C7.729 4.26984 7.8515 4.36317 7.96234 4.38067L8.5865 4.48567C8.97734 4.54984 9.07067 4.83567 8.78484 5.1215Z"
            fill="url(#paint2_linear_5335_84851)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5335_84851"
          x1="8.53177"
          y1="8.80593"
          x2="10.056"
          y2="10.7796"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5335_84851"
          x1="1.7178"
          y1="8.80593"
          x2="3.24208"
          y2="10.7796"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5335_84851"
          x1="3.86697"
          y1="2.10635"
          x2="6.71674"
          y2="5.49088"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function ProfileIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M15.02 3.01001C14.18 2.37001 13.14 2 12 2C9.24 2 7 4.24 7 7C7 9.76 9.24 12 12 12C14.76 12 17 9.76 17 7"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5899 22C20.5899 18.13 16.7399 15 11.9999 15C7.25991 15 3.40991 18.13 3.40991 22"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function GraphIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      {...props}
    >
      <path
        d="M8.12573 18.0004L13.3757 12.7504L17.1257 16.5004L21.6257 12.0004M5.5 27.0001C3.84314 27.0001 2.5 25.657 2.5 24.0001V6.00012C2.5 4.34327 3.84315 3.00012 5.5 3.00012H23.5C25.1569 3.00012 26.5 4.34327 26.5 6.00012V24.0001C26.5 25.657 25.1569 27.0001 23.5 27.0001H5.5Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function StarIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      {...props}
    >
      <path
        d="M13.6137 4.46515C14.0899 3.17828 15.9101 3.17828 16.3863 4.46515L18.7564 10.8703C18.9061 11.2749 19.2251 11.5939 19.6297 11.7436L26.0348 14.1137C27.3217 14.5899 27.3217 16.4101 26.0348 16.8863L19.6297 19.2564C19.2251 19.4061 18.9061 19.7251 18.7564 20.1297L16.3863 26.5348C15.9101 27.8217 14.0899 27.8217 13.6137 26.5348L11.2436 20.1297C11.0939 19.7251 10.7749 19.4061 10.3703 19.2564L3.96515 16.8863C2.67828 16.4101 2.67828 14.5899 3.96515 14.1137L10.3703 11.7436C10.7749 11.5939 11.0939 11.2749 11.2436 10.8703L13.6137 4.46515Z"
        stroke="white"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export function LockIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      {...props}
    >
      <path
        d="M8.25 11V9.85714C8.25 6.0585 11.2607 3 15 3C18.7393 3 21.75 6.0585 21.75 9.85714V11M8.25 11C7.0125 11 6 12.0286 6 13.2857V24.7143C6 25.9714 7.0125 27 8.25 27H21.75C22.9875 27 24 25.9714 24 24.7143V13.2857C24 12.0286 22.9875 11 21.75 11M8.25 11H21.75M15 16.7143C16.2375 16.7143 17.25 17.7429 17.25 19C17.25 20.2571 16.2375 21.2857 15 21.2857C13.7625 21.2857 12.75 20.2571 12.75 19C12.75 17.7429 13.7625 16.7143 15 16.7143Z"
        stroke="white"
      />
    </svg>
  );
}

export function AtomIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.4997 12.0901V11.9999M21.4624 20.9626C19.5667 22.8583 14.0173 20.3823 9.0674 15.4325C4.1175 10.4826 1.64159 4.9331 3.53729 3.03739C5.433 1.14169 10.9825 3.6176 15.9323 8.5675C20.8822 13.5174 23.3582 19.0668 21.4624 20.9626ZM3.53749 20.9626C1.64178 19.0669 4.11769 13.5174 9.06759 8.56755C14.0175 3.61766 19.5669 1.14174 21.4626 3.03745C23.3583 4.93315 20.8824 10.4826 15.9325 15.4325C10.9826 20.3824 5.43319 22.8583 3.53749 20.9626Z"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
}

export function FlameIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      {...props}
    >
      <path
        d="M8.5 3.95511C7.98695 3.23269 7.60408 2.44126 7.56566 1.42011C7.5628 1.34392 7.47988 1.29637 7.41483 1.33615C4.89296 2.87865 4.89962 6.6442 4.92989 7.43212C4.93262 7.50312 4.8651 7.55125 4.80159 7.51939C4.47885 7.35747 3.6887 6.82084 3.62863 5.42837C3.62534 5.35208 3.54298 5.30405 3.47761 5.34354C1.99301 6.24031 1 7.87565 1 9.68752C1 12.4835 3.3505 14.75 6.25 14.75C9.1495 14.75 11.5 12.4835 11.5 9.68752C11.5 8.09062 10.9065 6.98651 10.1804 6.02959"
        stroke="url(#paint0_linear_825_629)"
        strokeLinecap="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_825_629"
          x1="2.22203"
          y1="2.80365"
          x2="6.9877"
          y2="7.20584"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function WalletIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      {...props}
    >
      <path
        d="M11.0833 7.5H6.08325"
        stroke="url(#paint0_linear_785_84732)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.5836 9.14158V10.8583C18.5836 11.3167 18.2169 11.6916 17.7503 11.7083H16.1169C15.2169 11.7083 14.3919 11.0499 14.3169 10.1499C14.2669 9.62492 14.4669 9.13325 14.8169 8.79158C15.1253 8.47492 15.5503 8.29163 16.0169 8.29163H17.7503C18.2169 8.30829 18.5836 8.68325 18.5836 9.14158Z"
        stroke="url(#paint1_linear_785_84732)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.91675 7.08332C1.91675 4.81666 3.28341 3.23332 5.40841 2.96666C5.62508 2.93332 5.85008 2.91666 6.08341 2.91666H13.5834C13.8001 2.91666 14.0084 2.92498 14.2084 2.95831C16.3584 3.20831 17.7501 4.79999 17.7501 7.08332V8.29167H16.0167C15.5501 8.29167 15.1251 8.47499 14.8167 8.79166C14.4667 9.13332 14.2667 9.62499 14.3167 10.15C14.3917 11.05 15.2167 11.7083 16.1167 11.7083H17.7501V12.9167C17.7501 15.4167 16.0834 17.0833 13.5834 17.0833H6.08341C3.58341 17.0833 1.91675 15.4167 1.91675 12.9167V10.2167"
        stroke="url(#paint2_linear_785_84732)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_785_84732"
          x1="6.66517"
          y1="7.61508"
          x2="6.78115"
          y2="8.3038"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_785_84732"
          x1="14.8068"
          y1="8.68482"
          x2="15.9277"
          y2="10.3502"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_785_84732"
          x1="3.75948"
          y1="4.547"
          x2="8.58158"
          y2="10.9477"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function WaveImage(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="761"
      height="223"
      viewBox="0 0 761 223"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_535_13041)">
        <path
          d="M0 -31.1241C106.852 4.60239 164.449 51.7214 197.667 90.268C225.358 122.405 224.867 135.551 254.27 159.527C318.079 211.555 440.311 247.433 499.551 202.722C528.733 180.697 517.939 152.786 531.897 99.9486C544.454 52.4176 576.921 -12.9542 667.565 -84"
          stroke="url(#paint0_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M0.213745 -19.5975C104.145 11.1067 161.166 55.4342 194.367 91.5088C222.303 121.871 224.574 134.013 255.806 157.577C321.323 207.01 436.115 241.425 495.438 199.707C525.996 178.22 519.044 150.787 535.399 98.1041C549.798 51.7229 582.928 -12.5311 672.009 -82.9397"
          stroke="url(#paint1_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M0.427734 -8.07248C101.437 17.6109 157.926 59.1582 191.073 92.7496C219.311 121.367 224.231 132.476 257.347 155.627C324.429 202.522 432.102 235.497 491.335 196.694C523.333 175.729 520.106 148.815 538.91 96.2613C555.093 51.041 588.943 -12.1031 676.468 -81.8713"
          stroke="url(#paint2_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M0.641113 3.45418C98.7273 24.1168 154.741 62.8934 187.778 94C216.355 120.892 223.832 130.951 258.896 153.676C327.409 198.099 428.262 229.659 487.234 193.679C520.741 173.238 521.119 146.865 542.425 94.4168C560.317 50.3687 594.963 -11.6751 680.918 -80.8078"
          stroke="url(#paint3_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M0.855713 14.9792C96.0289 30.6179 151.622 66.6287 184.484 95.2328C213.45 120.444 223.401 129.441 260.426 151.726C330.251 193.741 424.561 223.917 483.118 190.664C518.201 170.742 522.06 144.928 545.925 92.5724C565.468 49.6996 600.969 -11.2487 685.357 -79.7362"
          stroke="url(#paint4_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M1.06934 26.5043C93.3122 37.1222 148.574 70.3655 181.189 96.4737C210.606 120.022 222.9 127.952 261.967 149.774C332.998 189.455 421.012 218.271 479.01 187.65C515.733 168.26 522.936 143 549.433 90.7279C570.561 49.0289 606.982 -10.8208 689.807 -78.6806"
          stroke="url(#paint5_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M1.28308 38.0293C90.6033 43.6185 145.611 74.0863 177.895 97.7145C207.828 119.623 222.366 126.49 263.506 147.824C335.657 185.239 417.591 212.728 474.903 184.635C513.324 165.801 523.772 141.073 552.941 88.8851C575.595 48.3518 613.002 -10.3928 694.256 -77.6155"
          stroke="url(#paint6_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M1.50439 49.5559C87.902 50.1292 142.748 77.7896 174.608 98.9553C205.135 119.236 221.809 125.059 265.053 145.875C338.244 181.095 414.292 207.286 470.803 181.62C510.983 163.372 524.541 139.142 556.457 87.0406C580.568 47.6667 619.007 -9.96002 698.706 -76.5519"
          stroke="url(#paint7_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M1.71057 61.081C85.1856 56.6335 139.97 81.4578 171.306 100.198C202.516 118.856 221.189 123.673 266.587 143.923C340.76 177.021 411.081 201.946 466.687 178.607C508.687 160.983 525.231 137.195 559.957 85.1962C585.488 46.9673 625.02 -9.53842 703.166 -75.4884"
          stroke="url(#paint8_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M1.92432 72.6012C82.4786 63.133 137.301 85.0732 168.011 101.434C199.979 118.463 220.553 122.302 268.126 141.969C343.22 173.01 407.95 196.698 462.58 175.587C506.439 158.639 525.866 135.225 563.465 83.3469C590.357 46.2455 631.035 -9.11525 707.605 -74.4296"
          stroke="url(#paint9_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M2.13879 84.1326C79.7801 69.6421 134.738 88.6375 164.719 102.679C197.544 118.055 219.895 120.991 269.668 140.022C345.649 169.073 404.893 191.558 458.473 172.579C504.243 156.365 526.439 133.237 566.974 81.5073C595.178 45.5141 637.048 -8.68247 712.056 -73.3581"
          stroke="url(#paint10_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M2.35254 95.6576C77.0616 76.1448 132.272 92.1205 161.425 103.92C195.202 117.593 219.222 119.72 271.207 138.072C348.047 165.194 401.891 186.504 454.366 169.564C502.089 154.158 526.953 131.212 570.483 79.6644C599.959 44.7555 643.061 -8.25609 716.504 -72.2961"
          stroke="url(#paint11_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M2.56628 107.184C74.3527 82.6491 129.89 95.514 158.13 105.163C192.947 117.058 218.517 118.492 272.748 136.122C350.426 161.374 398.928 181.539 450.26 166.549C499.966 152.032 527.412 129.15 573.991 77.82C604.704 43.9731 649.074 -7.82809 720.954 -71.2325"
          stroke="url(#paint12_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M2.78027 118.709C71.646 89.1533 127.58 98.8083 154.836 106.403C190.772 116.418 217.808 117.31 274.284 134.171C352.788 157.607 395.988 176.649 446.149 163.535C497.883 150.001 527.813 127.044 577.495 75.9754C609.421 43.1633 655.087 -7.40502 725.403 -70.1643"
          stroke="url(#paint13_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M2.99365 130.234C68.9367 95.6561 125.316 101.996 151.533 107.644C188.647 115.638 217.083 116.173 275.821 132.221C355.148 153.893 393.059 171.854 442.037 160.52C495.817 148.075 528.167 124.891 580.999 74.1263C614.102 42.3218 661.093 -6.97853 729.845 -69.1102"
          stroke="url(#paint14_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M3.20752 141.761C66.2376 102.16 123.072 105.075 148.246 108.885C186.566 114.685 216.369 115.083 277.368 130.269C357.514 150.231 390.142 167.125 437.937 157.507C493.779 146.266 528.486 122.694 584.515 72.2882C618.782 41.4611 667.114 -6.54577 734.301 -68.0403"
          stroke="url(#paint15_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M3.43091 153.286C63.5287 108.665 121.087 113.823 144.962 110.127C184.011 104.081 215.656 114.038 278.919 128.32C359.893 146.603 387.206 162.467 433.83 154.492C491.741 144.591 528.762 120.431 588.024 70.4438C623.436 40.5716 673.127 -6.11779 738.752 -66.9704"
          stroke="url(#paint16_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M3.63574 164.811C60.8129 115.169 119.057 115.691 141.658 111.368C181.271 103.792 214.924 113.037 280.449 126.37C362.281 143.026 384.236 157.877 429.723 151.477C489.697 143.048 529.005 118.12 591.533 68.5961C628.084 39.6486 679.14 -5.69461 743.201 -65.9164"
          stroke="url(#paint17_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M3.84961 176.338C58.1041 121.672 116.761 117.376 138.364 112.609C178.689 103.713 214.205 112.081 281.99 124.426C364.681 139.479 381.229 153.34 425.615 148.471C487.638 141.667 529.221 115.76 595.041 66.7629C632.723 38.7129 685.155 -5.25855 747.651 -64.8416"
          stroke="url(#paint18_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M4.06311 187.863C55.395 128.176 113.503 116.48 135.071 113.85C177.554 108.681 213.498 111.164 283.531 122.473C367.106 135.963 378.169 148.85 421.509 145.453C485.545 140.429 529.413 113.323 598.553 64.9104C637.366 37.7307 691.171 -4.83545 752.101 -63.7861"
          stroke="url(#paint19_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M4.27686 199.388C52.6861 134.68 110.817 119.192 131.774 115.092C174.862 106.665 212.795 110.289 285.069 120.522C369.547 132.478 375.04 144.409 417.4 142.438C483.406 139.369 529.59 110.851 602.057 63.0707C642.003 36.7326 697.18 -4.40431 756.55 -62.7177"
          stroke="url(#paint20_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
        <path
          d="M4.4906 210.914C49.9791 141.184 107.948 121.906 128.48 116.333C171.924 104.535 212.105 109.452 286.61 118.569C372.017 129.019 371.838 140.003 413.294 139.421C481.206 138.463 529.754 108.312 605.567 61.2247C646.648 35.7122 703.193 -3.9811 761 -61.6574"
          stroke="url(#paint21_linear_535_13041)"
          strokeOpacity="0.1"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_535_13041"
          x1="77.6934"
          y1="-48.6695"
          x2="150.91"
          y2="140.412"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_535_13041"
          x1="78.3996"
          y1="-48.263"
          x2="148.935"
          y2="138.507"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_535_13041"
          x1="79.1075"
          y1="-47.8433"
          x2="147.019"
          y2="136.564"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_535_13041"
          x1="79.814"
          y1="-47.4209"
          x2="145.168"
          y2="134.583"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_535_13041"
          x1="80.5203"
          y1="-46.9841"
          x2="143.38"
          y2="132.574"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_535_13041"
          x1="81.227"
          y1="-46.554"
          x2="141.664"
          y2="130.535"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_535_13041"
          x1="81.9336"
          y1="-46.1076"
          x2="140.012"
          y2="128.479"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_535_13041"
          x1="82.647"
          y1="-45.655"
          x2="138.434"
          y2="126.404"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_535_13041"
          x1="83.3483"
          y1="-45.1945"
          x2="136.91"
          y2="124.317"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_535_13041"
          x1="84.0538"
          y1="-44.7314"
          x2="135.457"
          y2="122.211"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_535_13041"
          x1="84.7613"
          y1="-44.2483"
          x2="134.069"
          y2="120.108"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_535_13041"
          x1="85.4679"
          y1="-43.767"
          x2="132.746"
          y2="117.99"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_535_13041"
          x1="86.1747"
          y1="-43.2771"
          x2="131.485"
          y2="115.867"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_535_13041"
          x1="86.8815"
          y1="-42.7764"
          x2="130.283"
          y2="113.74"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_535_13041"
          x1="87.5871"
          y1="-42.2806"
          x2="129.148"
          y2="111.611"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_535_13041"
          x1="88.2947"
          y1="-41.764"
          x2="128.068"
          y2="109.488"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_535_13041"
          x1="89.01"
          y1="-41.2405"
          x2="127.056"
          y2="107.367"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_535_13041"
          x1="89.7088"
          y1="-39.3636"
          x2="129.864"
          y2="113.501"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_535_13041"
          x1="90.4157"
          y1="-37.0859"
          x2="133.817"
          y2="121.88"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_535_13041"
          x1="91.1223"
          y1="-34.8255"
          x2="137.858"
          y2="130.166"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_535_13041"
          x1="91.8289"
          y1="-32.5538"
          x2="141.973"
          y2="138.372"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_535_13041"
          x1="92.5357"
          y1="-30.289"
          x2="146.166"
          y2="146.49"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <clipPath id="clip0_535_13041">
          <rect width="761" height="223" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
