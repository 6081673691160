import { useCallback, useEffect, useState } from 'react';
import { Button, Card, CheckBox, Header, Modal } from 'components';
import { classes, customToast, truncate } from 'utils';
import moment from 'moment';
import { Views, useStartVotingSessionMutation, useShortListedViewsLazyQuery } from 'gql';
import { useCookies } from 'react-cookie';
import { Setter } from 'types';
import { ModalType } from './SessionPanel';

const ViewCard = ({ view }: { view: Views }) => {
  return (
    <Card className="bg- text-white flex justify-center gap-1 border-0 p-0">
      <div className="text-sm break-all line-clamp-1 max-w-[30vw]">{view.name}</div>
      <div className="flex justify-between gap-48">
        <div className="flex flex-col text-sm">
          <span className="text-gray font-text-bold text-xs">Publisher</span>{' '}
          {truncate(view?.owner?.walletAddress || '', 5)}
        </div>
        <div className="flex flex-col text-sm">
          <span className="text-gray font-text-bold text-xs">Hash Id</span>{' '}
          {truncate(view?.hashId, 5)}
        </div>
      </div>
    </Card>
  );
};

export function CreateSessionModal({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: Setter<ModalType | undefined>;
}) {
  const [{ accessToken }] = useCookies(['accessToken']);

  const [fetchShortlistedViews, { data }] = useShortListedViewsLazyQuery({
    variables: { accessToken },
    fetchPolicy: 'cache-and-network',
  });
  const [startSession, { loading: sessionLoading }] = useStartVotingSessionMutation();

  const [selected, setSelected] = useState<string[]>([]);
  const [sessionData, setSessionData] = useState<{
    name: string;
    endDate?: string;
  }>({ name: '' });

  useEffect(() => {
    setSelected([]);
    setSessionData({ name: '' });
    if (isOpen) fetchShortlistedViews();
  }, [isOpen]);

  const setModalState = useCallback((state: boolean) => {
    if (!state) setIsOpen(undefined);
  }, []);

  const handleCreateSession = useCallback(() => {
    if (accessToken && startSession) {
      startSession({
        variables: {
          data: {
            name: sessionData.name,
            startDate: moment(new Date()).toISOString(),
            endDate: moment(sessionData?.endDate).toISOString(),
            shortlistedViewsHashes: selected,
          },
          accessToken,
        },
        refetchQueries: ['ShortListedViews'],
        onCompleted: () => {
          setModalState(false);
        },
        onError: (err) => {
          customToast(err.message, true);
        },
      });
    }
  }, [selected, startSession, sessionData]);

  const handleSelect = useCallback(
    (view: Views) => {
      if (selected.includes(view?.hashId)) {
        setSelected((prev) => prev.filter((item) => item !== view?.hashId));
      } else {
        setSelected((prev) => [...prev, view?.hashId]);
      }
    },
    [selected]
  );

  const handleShortcut = useCallback(() => {
    if (data?.shortListedViews.length == selected.length) {
      setSelected([]);
    } else {
      setSelected(
        data?.shortListedViews.map((el) => el?.__typename === 'Views' && el.hashId) as string[]
      );
    }
  }, [data, selected]);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setModalState as Setter<boolean>}
      classNames={{ content: 'items-start overflow-hidden', base: 'md:max-w-[unset] max-w-[90vw]' }}
    >
      <Header classNames={{ header: 'text-2xl' }}>Select views for session</Header>
      <div className="text-lg mb-2 mt-1 flex justify-between items-center w-full relative">
        <div className="text-base">
          Views Selected: {selected.length} / {data?.shortListedViews.length}
        </div>
        <Button variant="plain" className="absolute right-0" onClick={handleShortcut}>
          {data?.shortListedViews.length == selected.length ? 'Clear all' : 'Select all'}
        </Button>
      </div>
      <div className="flex flex-col gap-3 max-h-96 overflow-y-auto scrollbar-white p-[15px_15px_15px_0]">
        {data?.shortListedViews.length
          ? data?.shortListedViews.map((view, index) => (
              <button
                key={index}
                onClick={() => handleSelect(view as Views)}
                className={classes(
                  'flex flex-row gap-4 items-center p-[10px] border-[0.5px] border-solid border-steel-gray rounded-[10px] '
                )}
              >
                <CheckBox
                  isChecked={view?.__typename == 'Views' && selected.includes(view.hashId)}
                  className="w-5 h-5"
                />
                <ViewCard view={view as Views} />
              </button>
            ))
          : 'No shortlisted views available'}
      </div>
      <div className="py-4 w-full flex flex-col gap-2">
        <div className="flex justify-center items-center gap-2 mt-1 w-full">
          <label htmlFor="session-name" className="text-sm min-w-[120px]">
            Session Name:
          </label>
          <input
            id="session-name"
            className="w-52 p-2 rounded-md border border-solid border-steel-gray flex-1 h-10"
            name="trip-start"
            autoComplete="off"
            value={sessionData?.name}
            onChange={(e) => {
              let name = e.target.value;
              if (name.length > 0 && name.charAt(0) !== name.charAt(0).toUpperCase()) {
                name = name.charAt(0).toUpperCase() + name.slice(1);
              }
              setSessionData((prev) => ({ ...prev, name: name }));
            }}
          />
        </div>
        <div className="flex justify-center items-center gap-2 mt-1 w-full">
          <label htmlFor="session-date" className="text-sm min-w-[120px]">
            Session End Date:
          </label>
          <input
            id="session-date"
            className="flex-1 h-10"
            type="datetime-local"
            name="trip-start"
            value={sessionData?.endDate || ''}
            min={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            onChange={(e) => {
              if (moment(new Date()).isBefore(moment(e.target.value)))
                setSessionData((prev) => ({ ...prev, endDate: e.target.value }));
            }}
          />
        </div>
      </div>
      <Button
        variant="primary"
        classNames={{ base: 'w-full h-[unset] !py-3', container: '!text-sm' }}
        isDisabled={!selected.length || !sessionData.name?.length || !sessionData.endDate?.length}
        isLoading={sessionLoading}
        onClick={handleCreateSession}
      >
        Create Session
      </Button>
    </Modal>
  );
}
