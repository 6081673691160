import { HTMLAttributes } from 'types';
import { classes } from 'utils';
import { Select, SortGradientIcon } from 'components';
import { Skeleton } from './Skeleton';

interface Props extends HTMLAttributes<HTMLDivElement> {
  pageSize: number;
  setPageSize: (_: number) => void;
  pageSizeOptions?: string[];
}

export function PageSizeControl({ className, pageSize, setPageSize, pageSizeOptions }: Props) {
  return (
    <div
      className={classes(
        'relative whitespace-pre flex items-center gap-[10px] text-gray text-xs',
        className
      )}
    >
      <Skeleton.Loader className="w-[54px]">Showing</Skeleton.Loader>
      <Select
        classNames={{
          container: 'bottom-[40px] top-[unset] right-[unset] w-auto',
          selector: 'min-w-[unset] h-6 md:!px-[7px] !px-[6px] gap-[2px] md:!text-sm !text-xs',
          selectedValue: 'bg-primary-gradient bg-clip-text text-transparent',
          menuLabel: 'text-sm',
          optionsContainer: 'border-none',
        }}
        value={pageSize.toString()}
        onSelect={(val) => setPageSize(+val)}
        options={pageSizeOptions || ['3', '10', '15', '20']}
        hideApplyButton
        rightIcon={<SortGradientIcon className="md:min-h-[17px] max-h-h-[15px] min-w-3" />}
        menuLabel="Rows:"
      />
      rows
    </div>
  );
}
