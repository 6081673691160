import React, { useCallback, useState } from 'react';
import { Modal } from './Modal';
import { Setter } from 'types';
import { Header } from './Header';
import { Button } from './Button';

const TermsAndConditions = ({
  isConfirmed,
  setIsConfirmed,
}: {
  isConfirmed: boolean;
  setIsConfirmed: Setter<boolean>;
}) => {
  const [isScrollAtBottom, setIsScrollAtBottom] = useState(false);

  const handleScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement>) => {
      if (!isScrollAtBottom) {
        const { scrollTop, scrollHeight, clientHeight } = event.target as HTMLDivElement;
        const isAtBottom = Math.floor(scrollHeight - scrollTop) <= clientHeight;
        setIsScrollAtBottom(isAtBottom);
      }
    },
    [isScrollAtBottom]
  );

  return (
    <Modal
      isOpen={!isConfirmed}
      classNames={{ base: 'w-full z-50 bg-black md:p-8 p-6', modalBase: 'mt-0 max-h-full' }}
      isCloseHidden
    >
      <div className="w-full">
        <Header classNames={{ header: 'capitalize' }}>Terms of Service</Header>
        <div
          className="flex flex-col gap-2 text-gray mt-1 overflow-y-scroll scrollbar-white h-[calc(100vh_-_280px)] pr-2"
          onScroll={handleScroll}
        >
          <p>
            Please read these Terms of Service (“Terms”) before accessing or using the Analog One
            platform, participating in the Analog test-net airdrop (“Airdrop”) or receiving Analog
            tokens (“Services”). These Terms set out your legal rights and obligations in relation
            to the Services.
          </p>
          <p>
            These Terms may be revised from time to time by Analog One at its sole discretion
            without notice and by continuing to use the Services after the Terms have been revised,
            you agree to be bound by the revised Terms from the effective date of such revisions.
            The latest version of these terms will be available{' '}
            <a
              href={window.location.origin + '/terms-of-service.pdf'}
              target="_blank"
              rel="noreferrer"
              className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
            >
              here
            </a>
            .
          </p>
          <p>
            Analog One shall not be liable in connection with any claims, losses, damages or other
            liabilities, whether in contract, tort or otherwise, arising out of or in connection
            with the Services. Analog One does not assume any responsibility for your use of the
            Services. It is your responsibility to be aware of and to observe all applicable laws
            and regulations of any relevant jurisdiction. By using the Services, you agree that you
            have read, understood and are bound by these Terms. If you do not wish to agree to these
            Terms, do not use or access the Services.
          </p>
          <p>
            By using the Services, you represent and warrant that you have the legal right and full
            power to accept to be bound by these Terms and you will not use the Services if doing so
            will contravene any laws of any applicable jurisdiction. If you are acting for or on
            behalf of an entity, you represent and warrant that the entity is duly incorporated and
            validly existing under the laws of the jurisdiction of its incorporation, has the legal
            right and full power and authority to agree to be bound by the Terms and has obtained
            all relevant consents and approvals required under the applicable laws and that such
            consents and approvals shall remain valid and in full force and effect for as long these
            Terms continue to be binding upon the entity.
          </p>
          <p>
            [The Services are not available to residents of Democratic People’s Republic of Korea,
            Democratic Republic of the Congo, Iran, Libya, Somalia, South Sudan, Sudan, Yemen].
          </p>
          <p>
            The Airdrop will be conducted during the period specified by Analog One. Analog One will
            notify participants of the specific instructions and allocation of tokens from time to
            time. Participants are not required to make any payment for the receipt of tokens during
            the Airdrop period, however, transaction fees may apply. Participants agree that the
            tokens received via the Airdrop may be subject to various limitations and have no
            monetary value. Analog One makes no representations or warranties that the participation
            in the Airdrop will be continuous, uninterrupted, timely, or free of error, harmful
            components or other malicious, destructive or corrupting code, agent, programme or
            macros and Analog One will not be liable for any losses relating thereto.
          </p>
          <p>
            [The Services are part of Analog One’s test-net and not all features of the Services,
            including of the tokens to be distributed as part of the Airdrop, may be fully enabled.
            You acknowledge that you understand and agree that the risk of errors, failures and
            corruption or loss of data during the test-net is high and that Analog One will not be
            responsible for any costs, expenses or other liabilities that may be incurred by you in
            connection thereto.] We do not guarantee that the Services are free from defects,
            errors, bugs and security vulnerabilities, nor do we guarantee that the Services will be
            available at all times. You agree that your use of the Services is at your own risk and
            we expressly disclaim all warranties and conditions of any kind, whether express,
            implied, statutory or otherwise.{' '}
          </p>
          <p>
            [The Services includes information derived from third party sources and Analog One does
            not endorse, support, represent or guarantee the completeness, truthfulness, accuracy or
            reliability of any information made available on the Services.] Analog One does not
            provide any advice, endorsement, analysis or recommendation, nor does Analog One provide
            any financial, tax, legal advice or consultancy services of any kind via the Services.
          </p>
          <p>
            Analog One’s privacy policy is available at:{' '}
            <a
              href="https://www.analog.one/privacy-policy"
              target="_blank"
              className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
              rel="noreferrer"
            >
              https://www.analog.one/privacy-policy
            </a>
            .
          </p>
          <p>
            You will cease to be bound by the Terms at any time by discontinuing your use of the
            Services. Analog One may terminate your access to the Services at any time for any or no
            reason. Analog One shall not be required to provide any reason for termination.
          </p>
          <p>
            You agree to indemnify and hold harmless Analog One and its affiliates and their
            respective shareholders, members, directors, officers, employees, attorneys, agents
            representatives, suppliers or contractors from and against any potential or actual
            claims, actions, proceedings, investigations, suits, costs, expenses and damages,
            howsoever arising in connection with the Services.
          </p>
          <p>
            These Terms constitute the entire agreement and understanding between you and Analog One
            relating to the Services and supersede any written or oral agreement between you and
            Analog One and its affiliates and representatives relating to the Services. You agree
            and acknowledge that you have not accepted these Terms in reliance upon any
            representation, warranty or undertaking of Analog One which is not set out or referred
            to in these Terms.
          </p>
          <p>
            You may not assign (whether absolutely or by way of security and whether in whole or in
            part), transfer, mortgage, charge or otherwise dispose in any manner whatsoever of the
            benefit of these Terms.
          </p>
          <p>
            These Terms are governed by [Singapore law] and you agree that any dispute arising
            hereunder or in connection with the Services are subject to the [exclusive jurisdiction
            of the courts of Singapore].
          </p>
        </div>
        <Button
          isDisabled={!isScrollAtBottom}
          variant="primary"
          className="w-full mt-6"
          onClick={() => setIsConfirmed(true)}
        >
          Confirm
        </Button>
      </div>
    </Modal>
  );
};

export default TermsAndConditions;
