import { Page } from 'types';

export function pathTo(page: Page): string {
  try {
    switch (page) {
      case 'Home': {
        return '/';
      }

      case 'Game': {
        return '/game';
      }

      case 'Quests': {
        return '/quests';
      }

      case 'Leaderboard': {
        return '/leaderboard';
      }

      case 'ValidatorLeaderboard': {
        return '/validator-leaderboard';
      }

      case 'ClosedSessions': {
        return '/game/closed-sessions';
      }

      case 'Session': {
        return '/game/active-session';
      }

      case 'AllViews': {
        return '/game/active-session/all-views';
      }

      case 'AdminPanel': {
        return '/admin';
      }

      case 'FAQ': {
        return '/faq';
      }

      case 'SignIn': {
        return '?signin';
      }

      case 'SignUp': {
        return '?signup';
      }

      case 'ForgotPassword': {
        return '?forgot-password';
      }

      case 'SwitchWallet': {
        return '?switch-wallet';
      }

      case 'NotFound': {
        return '/404';
      }

      default: {
        throw new Error('Page not found');
      }
    }
  } catch (e) {
    return '#';
  }
}
