import { ActiveSessionCard, NoSession } from 'components';
import { useSessions } from 'context';

const Banner = () => {
  const { activeSession, isActiveSessionLoading } = useSessions();

  return (
    <div className="w-full">
      {activeSession?.id || isActiveSessionLoading ? <ActiveSessionCard /> : <NoSession />}
    </div>
  );
};

export default Banner;
