import { Button, CustomLink, DangerRedIcon, GameLogo, Modal } from 'components';
import { useApi } from 'context';
import { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { pathTo } from 'utils';

export const LoginError = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { trackEvent } = useApi();
  const [isVerificationStarted, setIsVerificationStarted] = useLocalStorage(
    'isVerificationStarted',
    false
  );

  const errorTitle = useCallback(() => {
    if (searchParams.get('account-frozen') !== null) {
      return 'Your account has been frozen';
    } else if (searchParams.get('discord') !== null) {
      return 'Error in discord connection';
    } else if (searchParams.get('twitter') !== null) {
      return 'Error in twitter connection';
    } else if (searchParams.get('signup') !== null) {
      return 'Error while sign up';
    } else if (searchParams.get('signin') !== null) {
      return 'Error while sign in';
    }
    return 'Authentication Error';
  }, [
    searchParams.get('account-frozen'),
    searchParams.get('discord'),
    searchParams.get('twitter'),
    searchParams.get('signup'),
    searchParams.get('signin'),
  ]);

  useEffect(() => {
    if (searchParams.get('account-frozen') !== null) {
      trackEvent('gtm_login', {
        action: 'sign_in_failure',
        error_message: errorTitle(),
      });
    } else if (searchParams.get('discord') !== null || searchParams.get('twitter') !== null) {
      trackEvent('gtm_login', {
        action: 'social_connect_failure',
        error_message: errorTitle(),
      });
    } else if (isVerificationStarted && searchParams.get('signup') !== null) {
      trackEvent('gtm_login', {
        action: 'sign_up_failure',
        error_message: searchParams.get('msg'),
      });
      setIsVerificationStarted(false);
    } else if (searchParams.get('signin') !== null) {
      trackEvent('gtm_login', {
        action: 'sign_in_failure',
        error_message: searchParams.get('msg'),
      });
    } else {
      trackEvent('gtm_login', {
        action: 'unknown_failure',
        error_message: searchParams.get('msg'),
      });
    }
  }, []);

  const ErrorMessage = () => {
    if (searchParams.get('account-frozen') !== null) {
      return (
        <>
          Your account has been frozen due to suspicious activity. To request an investigation or
          file an appeal, please open a ticket on our{' '}
          <CustomLink link="https://discord.com/invite/analog">Discord</CustomLink>.
        </>
      );
    }
    return <>{searchParams.get('msg')}</>;
  };

  return (
    <div className="flex flex-col w-[100vw] h-[100vh] font-['Chakra_Petch']">
      <div className="flex min-h-[72px] justify-center items-center border-b border-solid border-[#202020]">
        <GameLogo className="w-[150px]" />
      </div>
      <div className="h-full flex justify-center items-center">
        <div className="flex xl:max-w-[40vw] md:max-w-[60vw] max-w-[80vw] flex-col md:p-8 p-6 rounded-[10px] border border-solid text-white border-[#fc3b46cc] bg-[#fc3b460f] items-start">
          <div className="flex items-center gap-2">
            <div className="p-2 rounded-full bg-[#ffffff12]">
              <DangerRedIcon className="md:min-h-6 md:min-w-6 md:h-6 md:w-6 min-h-5 min-w-5 h-5 w-5" />
            </div>
            <div className="md:text-xl text-lg">{errorTitle()}</div>
          </div>
          <div className="text-sm text-[#ffffffb3] mt-2">
            <ErrorMessage />
          </div>
          <Button
            variant="primary"
            className="mt-4 w-full md:h-10 h-9"
            onClick={() => navigate(pathTo('Home'))}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  );
};
