import React from 'react';
import { Card } from './Card';
import { AtomIcon } from './icons';
import { classes } from 'utils';
import { ClassNames } from 'types';
import { Skeleton } from './Skeleton';

export function NoSession({
  text,
  classNames,
}: {
  text?: string;
  classNames?: ClassNames<'icon' | 'text'>;
}) {
  return (
    <Card
      className={classes(
        'flex flex-col items-center py-8 px-6 md:gap-3 gap-[15px]',
        classNames?.base
      )}
    >
      <Skeleton.Loader className="h-12 w-12">
        <AtomIcon
          className={classes('p-2 h-[60px] w-[60px] bg-black-100 rounded-full', classNames?.icon)}
        />
      </Skeleton.Loader>
      <div className={classes('md:text-xl text-lg uppercase text-center', classNames?.text)}>
        <Skeleton.Loader className="w-48">
          {text || 'No Active Voting Session Available'}
        </Skeleton.Loader>
      </div>
    </Card>
  );
}
