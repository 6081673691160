import { Star2Icon, StarGradientIcon } from './icons';
import { classes } from 'utils';
import { Maybe, SessionStats } from 'types';
import { Card } from './Card';
import { Skeleton } from './Skeleton';
import { CustomLink } from './CustomLink';
import { useWindowSize } from 'hooks';
import { ClosedSession } from 'context';

export function ClosedSessionCard({
  data,
  stats,
}: {
  data: Maybe<ClosedSession>;
  stats?: SessionStats[];
}) {
  const { isMobile } = useWindowSize();

  return (
    <Card className="overflow-hidden border-[#ffffff1a] border-[1px]">
      <div>
        <div className="h-10 w-full flex justify-center items-center overflow-hidden border-b border-[#ffffff3d] bg-[#ffffff1a]">
          <span className="uppercase text-sm md:text-base font-text-bold text-[#FFFFFF80]">
            <Skeleton.Loader className="w-20 h-6">Closed</Skeleton.Loader>
          </span>
        </div>
        <div className="relative overflow-hidden p-6">
          <div className="flex justify-between items-center gap-4 mb-4">
            <div className="flex items-center md:gap-[14px] gap-[12px] text-sm md:text-xl flex-[3] min-w-[50%]">
              <Skeleton.Loader className="min-h-9 h-9 w-9 min-w-9 lg:min-h-[60px] lg:h-[60px] lg:w-[60px] lg:min-w-[60px]">
                <img
                  src="/SessionIcon.svg"
                  alt="session-icon"
                  className="min-h-12 h-12 w-12 min-w-12 lg:min-h-[60px] lg:h-[60px] lg:w-[60px] lg:min-w-[60px]"
                />
              </Skeleton.Loader>
              <div className="md:text-lg text-sm break-all overflow-hidden whitespace-pre-line font-text-bold line-clamp-1">
                <Skeleton.Loader className="sm:w-20 w-14 h-6">{data?.name}</Skeleton.Loader>
              </div>
            </div>
            {data?.winnerView && (
              <div className="flex flex-row items-center gap-2 flex-2 justify-end">
                <Skeleton.Loader className="w-9 h-9 rounded-full">
                  {isMobile ? (
                    <Star2Icon className="min-h-8 min-w-8" />
                  ) : (
                    <StarGradientIcon className="min-h-8 min-w-8" />
                  )}
                </Skeleton.Loader>
                <div className="flex flex-col">
                  <div className="text-sm">
                    <Skeleton.Loader className="w-14 h-6">Winner</Skeleton.Loader>
                  </div>
                  <CustomLink
                    link={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/view/${
                      data.winnerView.hashId
                    }`}
                    className="line-clamp-1 break-all overflow-hidden whitespace-pre-line w-full text-base"
                  >
                    <Skeleton.Loader className="sm:w-24 w-16 h-6">
                      {data.winnerView.name}
                    </Skeleton.Loader>
                  </CustomLink>
                </div>
              </div>
            )}
          </div>
          <div className="[&>*:nth-child(1)]:[grid-area:top] [&>*:nth-child(2)]:[grid-area:center] [&>*:nth-child(3)]:[grid-area:bottom] lg:[grid-template-areas:'top_center_bottom'] [grid-template-areas:'top_center''bottom_bottom'] border-0 border-t border-solid border-[#282131] lg:grid-cols-[33%_33%_33%]  2xl:[&>*:nth-child(2)]:!justify-center 2xl:[&>*:nth-child(3)]:!justify-start !grid-cols-[repeat(auto-fit,_minmax(200px,_1fr))] flex gap-2 flex-wrap">
            {stats?.map(({ Icon, value, label }, index) => {
              return (
                <div
                  className={classes(
                    'flex flex-1 lg:items-center md:gap-1 px-6 md:py-[20px] border-0 !md:border-l border-solid border-[#282131] p-0 !md:items-start !border-l-0 border-b-0 !gap-2 !pt-3 !pb-0 !flex-row-reverse !items-center !justify-end',
                    index > 0 && 'border-l'
                  )}
                  key={index}
                >
                  <div className="flex items-center gap-2 text-sm md:text-base">
                    {Icon && (
                      <Skeleton.Loader className="w-8 h-6">
                        <Icon className="h-6 w-6 lg:h-auto lg:w-auto" />
                      </Skeleton.Loader>
                    )}{' '}
                    <Skeleton.Loader className="w-10 h-6">{value}</Skeleton.Loader>
                  </div>
                  <div className="text-xs text-gray md:text-sm">
                    <Skeleton.Loader className="w-16 h-5">{label + ':'}</Skeleton.Loader>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </Card>
  );
}
