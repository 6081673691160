import React, { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'context';
import { pathTo } from 'utils';

export function AdminRoute({ children }: { children: ReactElement }) {
  const { isAdmin, isSuccessLogin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSuccessLogin || (isAdmin != null && !isAdmin)) {
      navigate(pathTo('NotFound'));
    }
  }, [isAdmin, isSuccessLogin]);

  return children;
}
