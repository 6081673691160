import { Card, ClockIcon, Skeleton } from 'components';
import { RewardType } from 'hooks/quests';
import { classes } from 'utils';

type QuestType = {
  heading: string;
  headCta?: JSX.Element;
  withoutTopMargin?: boolean;
  comingSoon?: boolean;
  tasks: Array<{
    icon?: any;
    rewardType?: RewardType;
    title: string | JSX.Element;
    description?: string | JSX.Element;
    taskButton?: JSX.Element;
  }>;
};

const ValidatorsQuestCard = ({ quest }: { quest: QuestType }) => {
  return (
    <div className="flex flex-col md:gap-3 gap-2 ">
      <div className="flex flex-row items-center gap-[10px] space-x-0 justify-between">
        {quest.heading && (
          <div className="md:text-xl text-base uppercase">
            <Skeleton.Loader className="h-7 w-[120px]">{quest.heading}</Skeleton.Loader>
          </div>
        )}

        {quest.headCta && (
          <Skeleton.Loader className="h-7 w-[120px]">{quest.headCta}</Skeleton.Loader>
        )}
      </div>
      <div
        className={classes(
          'flex flex-col md:gap-4 gap-3 relative',
          quest.comingSoon && 'overflow-hidden'
        )}
      >
        {quest.tasks.map((task, index) => {
          return (
            <Card
              key={`faq-item-${index}`}
              className="flex md:flex-row flex-col justify-between md:gap-1 gap-[10px] bg-[#030303] shadow-[0px_0px_80px_0px_rgba(255,_255,_255,_0.08)_inset] border-dark-gray md:p-6 p-4"
            >
              <div className="flex gap-[10px] items-start">
                {task.icon && (
                  <Skeleton.Loader className="h-7 w-[30px]">
                    <div className="flex gap-[20px] justify-center items-center bg-[#ffffff1a] md:min-h-[38px] md:min-w-[38px] min-h-[36px] min-w-[36px] rounded-md">
                      {task.icon}
                    </div>
                  </Skeleton.Loader>
                )}

                <div
                  className={classes(
                    'self-center',
                    task.description && !quest.withoutTopMargin && 'mt-[7px]'
                  )}
                >
                  <Skeleton.Loader className="h-7 w-[240px]">
                    <div className="md:text-base text-sm">{task.title}</div>
                  </Skeleton.Loader>

                  {task.description && (
                    <Skeleton.Loader className="h-7 w-[170px]">
                      <div className="text-xs leading-5 text-gray">{task.description}</div>{' '}
                    </Skeleton.Loader>
                  )}
                </div>
              </div>

              {task.taskButton && (
                <Skeleton.Loader className="h-7 w-[290px]">
                  <div className="md:h-10 h-9">{task.taskButton}</div>
                </Skeleton.Loader>
              )}
            </Card>
          );
        })}
        {quest.comingSoon && (
          <div className="absolute top-0 left-0 bg-black bg-opacity-40 backdrop-blur-sm w-full h-full flex items-center justify-center flex-col md:gap-2 sm:gap-1 gap-2">
            <Skeleton.Loader className="w-9 h-9">
              <div className="bg-[#1C1C1C] md:p-2 sm:p-1 p-2 rounded-md">
                <ClockIcon className="h-7 w-7" />
              </div>
            </Skeleton.Loader>
            <Skeleton.Loader className="h-5 w-24">
              <div className="md:text-xl text-lg">Coming Soon</div>
            </Skeleton.Loader>
          </div>
        )}
      </div>
    </div>
  );
};

export default ValidatorsQuestCard;
