import { forwardRef } from 'react';
import { HTMLAttributes } from 'types';
import { classes } from 'utils';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  isSelected?: boolean;
}

export function radioButtonClassName(isSelected: boolean | undefined, className?: string): string {
  return classes(
    'whitespace-nowrap text-sm font-medium h-7',
    isSelected
      ? 'bg-black-100 text-white rounded-md'
      : 'bg-transparent bg-primary-gradient bg-clip-text text-transparent',
    className
  );
}

export const RadioButtons = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  (props, ref) => {
    const { className, children, ...rest } = props;

    return (
      <div
        {...rest}
        className={classes(
          'flex w-min p-1 text-sm border border-solid border-steel-gray rounded-lg !h-9 items-center',
          className
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  }
);

RadioButtons.displayName = 'RadioButtons';

export function RadioButton({ isSelected, children, className }: Props) {
  return <button className={radioButtonClassName(isSelected, className)}>{children}</button>;
}
