import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { client } from 'main';
import { useCookies } from 'react-cookie';
import { Swiper, SwiperSlide, SwiperRef, SwiperClass } from 'swiper/react';
import { EffectCoverflow, Mousewheel, Navigation } from 'swiper/modules';
import {
  ActiveVotingSessionDocument,
  Views,
  useActiveVotingSessionViewsQuery,
  useVoteMutation,
} from 'gql';
import { useApi, useSessions } from 'context';
import { useSessionCountdown, useWindowSize } from 'hooks';
import { Button, Tooltip, NoSession, Skeleton, BreadCrumbs, CustomLink } from 'components';
import { classes, customToast, pathTo, shuffleArray } from 'utils';
import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { SessionViewCard } from './SessionViewCard';

const ProgressLine = () => {
  const { progressPercentage } = useSessionCountdown();
  return (
    <div className="h-1 w-full transition-[0.8s] bg-[#151120]">
      <div
        className="transition-[0.8s] h-full bg-primary-gradient"
        style={{ width: progressPercentage + '%' }}
      ></div>
    </div>
  );
};

const SessionCountdown = () => {
  const { countdown } = useSessionCountdown();
  return (
    <div>
      {countdown} <span className="text-[#FFFFFF99]">Time left</span>
    </div>
  );
};

export function Voting() {
  const { isMobile } = useWindowSize();
  const [{ accessToken }] = useCookies(['accessToken']);

  const { account } = useApi();
  const { activeSession, isActiveSessionLoading, setIsVoteSuccessOpen } = useSessions();
  const [voteMutation, { loading: voteLoading }] = useVoteMutation();
  const {
    data: activeSessionViews,
    loading: isActiveSessionViewsLoading,
    refetch: refetchActiveSessionViews,
  } = useActiveVotingSessionViewsQuery({ variables: { accessToken }, skip: !accessToken });

  const [isActive, setIsActive] = useState(0);
  const swiperRef = useRef<SwiperRef>(null);

  const shuffledViews = useMemo(() => {
    if (activeSessionViews?.activeVotingSessionViews)
      return shuffleArray([...activeSessionViews.activeVotingSessionViews]);
  }, [activeSessionViews]);

  const disableVoting = useMemo(
    () =>
      !account?.id ||
      !!account?.votingSessionParticipated?.find(
        (session) => session.votingSessionId == activeSession?.id
      ),
    [account, activeSession?.id]
  );

  const vote = useCallback(
    (view: Views) => {
      if (activeSession?.id)
        try {
          voteMutation({
            variables: {
              accessToken,
              data: {
                viewHash: view.hashId,
                VotingSessionId: activeSession.id,
              },
            },
            onCompleted(data) {
              if (data.vote.status === 'SUCCESS') {
                client.writeQuery({
                  query: ActiveVotingSessionDocument,
                  data: {
                    activeVotingSession: {
                      ...activeSession,
                      _count: {
                        ...activeSession._count,
                        votes: (activeSession._count?.votes || 0) + 1,
                      },
                    },
                  },
                });
                setIsVoteSuccessOpen(true);
              }
              if (data.vote.error) customToast(data.vote.error, true);
            },
            refetchQueries: ['GetUser'],
          });
        } catch (e) {
          console.log(e);
        }
    },
    [accessToken, activeSession?.id, voteMutation]
  );

  const nextSlideHandler = useCallback(() => {
    if (swiperRef.current !== null && swiperRef.current?.swiper !== null) {
      swiperRef.current?.swiper.slideNext();
    }
  }, [swiperRef]);

  const previousSlideHandler = useCallback(() => {
    if (swiperRef.current !== null && swiperRef.current?.swiper !== null) {
      swiperRef.current?.swiper.slidePrev();
    }
  }, [swiperRef]);

  useEffect(() => {
    if (!activeSessionViews?.activeVotingSessionViews.length) {
      refetchActiveSessionViews();
    }
  }, []);

  return (
    <Skeleton.Provider isLoading={isActiveSessionLoading || isActiveSessionViewsLoading}>
      <div className="md:pt-[55px] pt-[36px] xl:pb-[0] pb-3">
        <div className="flex justify-between">
          <BreadCrumbs
            pages={[{ page: 'Game' }, { page: 'Session', label: activeSession?.name }]}
          />
          <CustomLink useLink link={pathTo('AllViews')} className="uppercase text-sm">
            All Views
          </CustomLink>
        </div>
        <div
          className={classes(
            //Without error banner - md:min-h-[calc(100vh-163px)] h-[calc(100vh-275px)]
            //With error banner - md:min-h-[calc(100vh-204px)] h-[calc(100vh-355px)]
            'md:flex items-start justify-center md:min-h-[calc(100vh-204px)] md:h-auto min-h-[100%] overflow-y-auto overflow-x-hidden md:overflow-hidden h-[calc(100vh-355px)] scrollbar-white',
            !activeSession?.id && ' justify-center items-center flex'
          )}
        >
          {activeSession?.id || isActiveSessionLoading ? (
            <div className="custom-slider w-full md:h-auto !overflow-visible">
              <Swiper
                key={isMobile ? 'mobile' : 'desktop'}
                direction={isMobile ? 'horizontal' : 'vertical'}
                effect={isMobile ? 'coverflow' : ''}
                modules={isMobile ? [EffectCoverflow, Navigation] : [Mousewheel]}
                slidesPerView={1}
                spaceBetween={24}
                // style={{ paddingInline: '12px' }}
                autoHeight={true}
                mousewheel={{
                  forceToAxis: true,
                  sensitivity: 1,
                  releaseOnEdges: true,
                }}
                {...(isMobile && {
                  navigation: {
                    nextEl: '.next-arrow-parent',
                    prevEl: '.prev-arrow-parent',
                    lockClass: 'unlock',
                  },
                })}
                onSlideChange={(slider: SwiperClass) => {
                  setIsActive(slider.realIndex);
                }}
                noSwiping={isMobile ? false : true}
                noSwipingClass={isMobile ? '' : 'swiper-slide'}
                ref={swiperRef}
                updateOnWindowResize={true}
              >
                {(isActiveSessionViewsLoading ? [{} as Views] : shuffledViews)?.map(
                  (view, index) => (
                    <SwiperSlide key={index}>
                      <SessionViewCard
                        view={view as Views}
                        key={index}
                        index={index}
                        length={activeSession?._count?.views}
                        voteLoading={voteLoading}
                        vote={vote}
                        tag={view.tags}
                        disableVoting={disableVoting}
                        isLoggedIn={!!account?.id}
                        nextSlideHandler={nextSlideHandler}
                        previousSlideHandler={previousSlideHandler}
                        viewsCount={isActiveSessionViewsLoading ? 1 : shuffledViews?.length || 0}
                        isLoading={isActiveSessionLoading || isActiveSessionViewsLoading}
                      />
                    </SwiperSlide>
                  )
                )}
                {isMobile && (
                  <>
                    <div className="opacity-[0.7] cursor-pointer next-arrow-parent border border-[#49474A] bg-[#1c1c1c] md:h-[30px] md:w-[30px] h-[25px] w-[25px] rounded-full absolute top-2/4 right-0 z-50  flex items-center justify-center -translate-y-2/4">
                      <ChevronRightIcon className="md:h-[16px] md:w-[16px] h-[12px] w-[12px] text-white" />
                    </div>
                    <div className="opacity-[0.7] cursor-pointer prev-arrow-parent border border-[#49474A] bg-[#1c1c1c] md:h-[30px] md:w-[30px] h-[25px] w-[25px]  rounded-full absolute top-2/4 left-0 z-50 flex items-center justify-center -translate-y-2/4">
                      <ChevronRightIcon className="md:h-[16px] md:w-[16px] h-[12px] w-[12px] rotate-180 text-white" />
                    </div>
                  </>
                )}
              </Swiper>
              {isMobile && (
                <div className="fixed  bg-black bottom-0 left-0 w-full z-50">
                  <Skeleton.Loader className="h-1 w-full">
                    <ProgressLine />
                  </Skeleton.Loader>
                  <div className="p-[20px_16px_16px_16px]">
                    <div className="text-white">
                      <div className="flex items-center justify-between mb-4">
                        <Skeleton.Loader className="h-4 w-28">
                          <SessionCountdown />
                        </Skeleton.Loader>
                        <div className="text-base flex items-center justify-center  gap-[6px]">
                          <Skeleton.Loader className="h-4 w-12">
                            <span>
                              {isActive + 1}/{activeSession?._count?.views}
                            </span>
                          </Skeleton.Loader>
                          <Skeleton.Loader className="h-4 w-24">
                            <span className="text-[#ffffff99]">No. of Views</span>
                          </Skeleton.Loader>
                        </div>
                      </div>
                      <div className="flex items-center gap-2 xl:flex-col flex-row-reverse relative z-40 md:justify-center justify-between">
                        <Skeleton.Loader className="h-9 w-[25vw]">
                          <Button
                            isDisabled={
                              shuffledViews?.length === 1 ||
                              isActive === (shuffledViews?.length || 1) - 1
                            }
                            classNames={{
                              base: 'after:hidden md:w-full w-fit cursor-pointer md:!px-6  md:!py-[7px] !px-[29px] !py-[7px] !h-9 rounded-lg border border-zinc-700 backdrop-blur-[44px] justify-center items-center gap-2.5 inline-flex',
                              container: 'text-white',
                            }}
                            onClick={nextSlideHandler}
                            variant="light"
                          >
                            Next
                          </Button>
                        </Skeleton.Loader>
                        <Skeleton.Loader className="h-9 w-[25vw]">
                          <div>
                            <Button
                              data-tooltip-id={'vote-btn-tooltip'}
                              className="w-full cursor-pointer md:!px-6  md:!py-[7px] min-w-[91px] !px-[5px] !h-9"
                              variant="primary"
                              isLoading={voteLoading}
                              isDisabled={disableVoting}
                              onClick={() => {
                                shuffledViews && vote(shuffledViews[isActive] as Views);
                              }}
                            >
                              Vote
                            </Button>
                            {!account?.id && (
                              <Tooltip
                                id={'vote-btn-tooltip'}
                                content={'Please Sign In for participate'}
                                delay={0}
                              />
                            )}
                          </div>
                        </Skeleton.Loader>
                        <Skeleton.Loader className="h-9 w-[25vw]">
                          <Button
                            isDisabled={shuffledViews?.length === 1 || isActive === 0}
                            classNames={{
                              base: 'after:hidden md:w-full w-fit cursor-pointer md:!px-6  md:!py-[7px] max-w-[106px] w-full !px-[5px] !py-[7px] !h-9 rounded-lg border border-zinc-700 backdrop-blur-[44px] justify-center items-center gap-2.5 inline-flex',
                              container: 'text-white',
                            }}
                            onClick={previousSlideHandler}
                          >
                            Previous
                          </Button>
                        </Skeleton.Loader>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <NoSession />
          )}
        </div>
      </div>
    </Skeleton.Provider>
  );
}
