import { classes } from 'utils';
import { TableType } from 'types';
import { Table } from 'components';

export function Leaderboard({
  columns,
  data,
  classNames,
  showPagination,
  defaultPageSize,
  isLoading,
}: TableType) {
  return (
    <div className={classes('flex flex-col', classNames?.parentContainer)}>
      <Table
        columns={columns}
        data={data}
        withGradient
        showPagination={showPagination}
        defaultPageSize={defaultPageSize || 10}
        pageSizeOptions={['10', '20', '30', '100']}
        classNames={{
          ...classNames,
          base: classes(!showPagination && '!rounded-b-none !border-b-0', classNames?.base),
        }}
        isLoading={isLoading}
      />
    </div>
  );
}
