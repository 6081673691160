import { useEffect, useState } from 'react';
import { Button, ExclamationMark, EyeIcon, SmartContractIcon, Tooltip } from 'components';
import { ConnectedChainType, DeveloperQuestSubType } from 'gql';
import { CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/24/outline';
import { RewardType } from './useDeveloperQuests';
import { useGMPQuestSupport } from './useGmpQuestSupport';
import { useEVM } from './useEVM';
import { useQuestPageData } from 'pages/Quests/useQuestPageData';
import { QuestTaskButton } from './useCommunityQuests';

export function useGMPQuests() {
  const [scAddressEth, setScAddressEth] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });
  const [scAddressAstar, setScAddressAstar] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });

  const [transactionHashEth, setTransactionHashEth] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });
  const [transactionHashAstar, setTransactionHashAstar] = useState({
    value: '',
    isTouched: false,
    isError: false,
  });
  const [error, setError] = useState<{
    deploySmartContractEth: string | null;
    deploySmartContractAstar: string | null;
    sendMessageEth: string | null;
    sendMessageAstar: string | null;
  }>({
    deploySmartContractEth: null,
    deploySmartContractAstar: null,
    sendMessageEth: null,
    sendMessageAstar: null,
  });
  const [success, setSuccess] = useState<{
    deploySmartContractEth: string | null;
    deploySmartContractAstar: string | null;
    sendMessageEth: string | null;
    sendMessageAstar: string | null;
  }>({
    deploySmartContractEth: null,
    deploySmartContractAstar: null,
    sendMessageEth: null,
    sendMessageAstar: null,
  });
  const { completedQuests } = useQuestPageData();
  const {
    handleGmpContractDeploy,
    handleGmpSubmitMessage,
    gmpContractDeployLoadingSepolia,
    gmpContractDeployLoadingShibuya,
    gmpSubmitMessageLoadingSepolia,
    gmpSubmitMessageLoadingShibuya,
  } = useGMPQuestSupport(
    completedQuests.some((point) => point?.type === 'connectEVMWalletOnTestnet')
  );
  const { open, isEvmWalletConnected } = useEVM();

  useEffect(() => {
    setError({ ...error, deploySmartContractAstar: null, deploySmartContractEth: null });
    setSuccess({ ...success, deploySmartContractAstar: null, deploySmartContractEth: null });
  }, [scAddressEth, scAddressAstar]);

  useEffect(() => {
    setError({ ...error, sendMessageAstar: null, sendMessageEth: null });
    setSuccess({ ...success, sendMessageAstar: null, sendMessageEth: null });
  }, [transactionHashEth, transactionHashAstar]);

  const onGmpContractDeploy = (scChain: ConnectedChainType) => {
    const address = scChain === ConnectedChainType.Sepolia ? scAddressEth : scAddressAstar;
    const setScAddress =
      scChain === ConnectedChainType.Sepolia ? setScAddressEth : setScAddressAstar;
    if (!isEvmWalletConnected) {
      open();
    } else if (!/^[a-fA-F0-9]{40}$/.test(address.value.replace('0x', ''))) {
      setScAddress((prev) => ({
        ...prev,
        isError: true,
      }));
    } else {
      handleGmpContractDeploy({
        connectedChain: scChain,
        contractAddress: address.value,
        onError(deployError) {
          setError({
            ...error,
            [scChain === ConnectedChainType.Sepolia
              ? 'deploySmartContractEth'
              : 'deploySmartContractAstar']: deployError,
          });
        },
        onSuccess() {
          setSuccess({
            ...success,
            [scChain === ConnectedChainType.Sepolia
              ? 'deploySmartContractEth'
              : 'deploySmartContractAstar']: '15 points awarded successfully',
          });
        },
      });
    }
  };

  const onGmpSubmitMessage = (transactionChain: ConnectedChainType) => {
    const transactionHash =
      transactionChain === ConnectedChainType.Sepolia ? transactionHashEth : transactionHashAstar;
    const setTransactionHash =
      transactionChain === ConnectedChainType.Sepolia
        ? setTransactionHashEth
        : setTransactionHashAstar;
    if (!isEvmWalletConnected) {
      open();
    } else if (!/^[0-9a-f]{64}$/i.test(transactionHash.value.replace('0x', ''))) {
      setTransactionHash((prev) => ({
        ...prev,
        isError: true,
      }));
    } else {
      handleGmpSubmitMessage({
        connectedChain: transactionChain,
        transactionHash: transactionHash.value,
        onError(sendError) {
          setError({
            ...error,
            [transactionChain === ConnectedChainType.Sepolia
              ? 'sendMessageEth'
              : 'sendMessageAstar']: sendError,
          });
        },
        onSuccess() {
          setSuccess({
            ...success,
            [transactionChain === ConnectedChainType.Sepolia
              ? 'sendMessageEth'
              : 'sendMessageAstar']: '20 points awarded successfully',
          });
        },
      });
    }
  };

  return {
    heading: 'GMP',
    comingSoon:true,
    // headCta: (
    //   <Button
    //     variant="light"
    //     classNames={{
    //       base: 'md:h-[38px] h-[34px] md:!px-4 !px-4',
    //       container: 'md:text-sm text-xs',
    //     }}
    //     onClick={() =>
    //       window.open(
    //         'https://docs.analog.one/documentation/developers/analog-gmp/developers-guide/send-your-first-cross-chain-message#deploy-the-destination-contract',
    //         '_blank'
    //       )
    //     }
    //   >
    //     Get Started
    //   </Button>
    // ),
    blockText:
      'To claim your ATP Points for completing GMP quests, please connect your EVM account that will be used!',
    tasks: [
      {
        icon: SmartContractIcon,
        rewardType: RewardType.FirstTime,
        type: DeveloperQuestSubType.ConnectEvmWalletOnTestnet,
        title: 'Connect your EVM account for GMP Quest',
        description: 'Please connect your EVM wallet used for GMP quests to claim your points.',
        primaryPoints: '1 ATP',
      },
      {
        icon: EyeIcon,
        rewardType: RewardType.OnceDaily,
        type: DeveloperQuestSubType.DeployedSmartContractUsingGmpOnSepolia,
        title: 'Build and deploy a smart contract using Analog GMP interfaces on Sepolia Testnet',
        description:
          'Ensure your smart contract is verified on Blockscout with the deployer address set as the connected EVM account. Please refer to our guides for assistance.',
        primaryPoints: '15 ATP',
        additionalDescription: (
          <>
            <div className="flex gap-4 lg:flex-row flex-col m-4">
              <div className="flex-1  flex flex-col gap-2 ">
                <div>Smart Contract Address</div>
                <div className="p-[10px] border border-solid border-[#393939] w-full justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                  <input
                    value={scAddressEth.value}
                    onChange={(e) =>
                      setScAddressEth({ value: e.target.value, isTouched: true, isError: false })
                    }
                    className="truncate w-full whitespace-pre-line line-clamp-1 break-all"
                  />
                  {((scAddressEth.isError && scAddressEth.isTouched) ||
                    /^.{40}$/.test(scAddressEth.value)) && (
                    <div data-tooltip-id={'sc-address'} className="relative">
                      <ExclamationMark />
                      <Tooltip delay={1000} id="sc-address" content="This address is not valid" />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1   flex flex-col gap-2 ">
                <div>Chain</div>
                <div className="flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px] ps-3">
                  Ethereum - sepolia
                </div>
              </div>
              <Button
                variant="primary"
                className="mt-auto"
                isDisabled={!scAddressEth.value || (scAddressEth.isError && scAddressEth.isTouched)}
                onClick={() => onGmpContractDeploy(ConnectedChainType.Sepolia)}
                isLoading={gmpContractDeployLoadingSepolia}
              >
                Claim
              </Button>
            </div>
            {error.deploySmartContractEth && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
                <InformationCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {error.deploySmartContractEth}
                </p>
              </div>
            )}
            {success.deploySmartContractEth && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
                <CheckCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {success.deploySmartContractEth}
                </p>
              </div>
            )}
          </>
        ),
      },
      {
        icon: SmartContractIcon,
        rewardType: RewardType.OnceDaily,
        type: DeveloperQuestSubType.SentMessageUsingGmpGatewayOnSepolia,
        title: 'Send a message using a GMP gateway contract',
        description:
          'Set the sender address as the connected EVM account. Check our guides for help if needed.',
        primaryPoints: '20 ATP',
        additionalDescription: (
          <>
            <div className="flex gap-4 lg:flex-row flex-col m-4">
              <div className="flex-1  flex flex-col gap-2 ">
                <div>Transaction Hash</div>
                <div className="p-[10px] border border-solid border-[#393939] w-full justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                  <input
                    value={transactionHashEth.value}
                    onChange={(e) =>
                      setTransactionHashEth({
                        value: e.target.value,
                        isTouched: true,
                        isError: false,
                      })
                    }
                    className="truncate w-full whitespace-pre-line line-clamp-1 break-all"
                  />
                  {((transactionHashEth.isError && transactionHashEth.isTouched) ||
                    /^.{40}$/.test(transactionHashEth.value)) && (
                    <div data-tooltip-id={'transaction-hash'} className="relative">
                      <ExclamationMark />
                      <Tooltip
                        delay={1000}
                        id="transaction-hash"
                        content="Not a valid transaction hash"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1   flex flex-col gap-2 ">
                <div>Sender Chain</div>
                <div className="flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px] ps-3">
                  Ethereum - sepolia
                </div>
              </div>
              <Button
                variant="primary"
                className="mt-auto"
                isDisabled={
                  !transactionHashEth.value ||
                  (transactionHashEth.isError && transactionHashEth.isTouched)
                }
                onClick={() => onGmpSubmitMessage(ConnectedChainType.Sepolia)}
                isLoading={gmpSubmitMessageLoadingSepolia}
              >
                Claim
              </Button>
            </div>
            {error.sendMessageEth && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
                <InformationCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {error.sendMessageEth}
                </p>
              </div>
            )}
            {success.sendMessageEth && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
                <CheckCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {success.sendMessageEth}
                </p>
              </div>
            )}
          </>
        ),
      },
      {
        icon: EyeIcon,
        rewardType: RewardType.OnceDaily,
        type: DeveloperQuestSubType.DeployedSmartContractUsingGmpOnShibuya,
        title: 'Build and deploy a smart contract using Analog GMP interfaces on Shibuya Testnet',
        description:
          'Ensure your smart contract is verified on Blockscout with the deployer address set as the connected EVM account. Please refer to our guides for assistance.',
        primaryPoints: '15 ATP',
        additionalDescription: (
          <>
            <div className="flex gap-4 lg:flex-row flex-col m-4">
              <div className="flex-1  flex flex-col gap-2 ">
                <div>Smart Contract Address</div>
                <div className="p-[10px] border border-solid border-[#393939] w-full justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                  <input
                    value={scAddressAstar.value}
                    onChange={(e) =>
                      setScAddressAstar({ value: e.target.value, isTouched: true, isError: false })
                    }
                    className="truncate w-full whitespace-pre-line line-clamp-1 break-all"
                  />
                  {((scAddressAstar.isError && scAddressAstar.isTouched) ||
                    /^.{40}$/.test(scAddressAstar.value)) && (
                    <div data-tooltip-id={'sc-address'} className="relative">
                      <ExclamationMark />
                      <Tooltip delay={1000} id="sc-address" content="This address is not valid" />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1   flex flex-col gap-2 ">
                <div>Chain</div>
                <div className="flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px] ps-3">
                  Astar - shibuya
                </div>
              </div>
              <Button
                variant="primary"
                className="mt-auto"
                isDisabled={
                  !scAddressAstar.value || (scAddressAstar.isError && scAddressAstar.isTouched)
                }
                onClick={() => onGmpContractDeploy(ConnectedChainType.Shibuya)}
                isLoading={gmpContractDeployLoadingShibuya}
              >
                Claim
              </Button>
            </div>
            {error.deploySmartContractAstar && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
                <InformationCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {error.deploySmartContractAstar}
                </p>
              </div>
            )}
            {success.deploySmartContractAstar && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
                <CheckCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {success.deploySmartContractAstar}
                </p>
              </div>
            )}
          </>
        ),
      },
      {
        icon: SmartContractIcon,
        rewardType: RewardType.OnceDaily,
        type: DeveloperQuestSubType.SentMessageUsingGmpGatewayOnShibuya,
        title: 'Send a message using a GMP gateway contract',
        hide:true,
        description:
          'Set the sender address as the connected EVM account. Check our guides for help if needed.',
        primaryPoints: '20 ATP',
        additionalDescription: (
          <>
            <div className="flex gap-4 lg:flex-row flex-col m-4">
              <div className="flex-1  flex flex-col gap-2 ">
                <div>Transaction Hash</div>
                <div className="p-[10px] border border-solid border-[#393939] w-full justify-between flex-row flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px]">
                  <input
                    value={transactionHashAstar.value}
                    onChange={(e) =>
                      setTransactionHashAstar({
                        value: e.target.value,
                        isTouched: true,
                        isError: false,
                      })
                    }
                    className="truncate w-full whitespace-pre-line line-clamp-1 break-all"
                  />
                  {((transactionHashAstar.isError && transactionHashAstar.isTouched) ||
                    /^.{40}$/.test(transactionHashAstar.value)) && (
                    <div data-tooltip-id={'transaction-hash'} className="relative">
                      <ExclamationMark />
                      <Tooltip
                        delay={1000}
                        id="transaction-hash"
                        content="Not a valid transaction hash"
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex-1   flex flex-col gap-2 ">
                <div>Sender Chain</div>
                <div className="flex rounded-lg items-center shadow-[0px_0px_120.6px_0px_#ffffff14_inset] h-[40px] ps-3">
                  Astar - shibuya
                </div>
              </div>
              {/* <Button
                variant="primary"
                className="mt-auto"
                isDisabled={
                  !transactionHashAstar.value ||
                  (transactionHashAstar.isError && transactionHashAstar.isTouched)
                }
                onClick={() => onGmpSubmitMessage(ConnectedChainType.Shibuya)}
                isLoading={gmpSubmitMessageLoadingShibuya}
              >
                Claim
              </Button> */}
            </div>
            {error.sendMessageAstar && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-rose-500 px-3 pb-3">
                <InformationCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {error.sendMessageAstar}
                </p>
              </div>
            )}
            {success.sendMessageAstar && (
              <div className="flex [flex-basis:100%] items-center gap-1 text-green-500 px-3 pb-3">
                <CheckCircleIcon className="w-5 h-5" />
                <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                  {success.sendMessageAstar}
                </p>
              </div>
            )}
          </>
        ),
      },
    ],
  };
}
