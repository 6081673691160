import { useWalletInfo, useWeb3Modal, useWeb3ModalEvents } from '@web3modal/wagmi/react';
import { useAccount, useDisconnect } from 'wagmi';

export function useEVM() {
  const { open } = useWeb3Modal();
  const { walletInfo } = useWalletInfo();
  const { disconnect } = useDisconnect();
  const { address, chain, status } = useAccount();
  const {
    data: { event },
  } = useWeb3ModalEvents();

  return {
    open,
    isEvmWalletConnected: status === 'connected',
    evmWalletAddress: address,
    evmChain: chain,
    evmEvent: event,
    disconnectEvmWallet: disconnect,
    evmWalletInfo: walletInfo,
  };
}
