import { useApi } from 'context';
import {
  DeveloperQuestSubType,
  useCheckGalaxyQuestCompletedQuery,
  useCheckGalaxyXCryfiQuestCompletedQuery,
  useClaimIntractPointMutation,
  useCompletedDevQuestCountLazyQuery,
} from 'gql';
import { useEVM } from 'hooks/quests';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';

export function useQuestPageData() {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { evmWalletAddress } = useEVM();
  const { userPoints, isUserPointsLoading, fetchUserPoints } = useApi();

  const [
    fetchCompletedDevQuestCount,
    {
      data: completedDevQuestsCount,
      loading: completedCountLoading,
      refetch: refetchDevQuestCount,
    },
  ] = useCompletedDevQuestCountLazyQuery({
    variables: { accessToken },
  });

  const [claimIntractPoint] = useClaimIntractPointMutation();

  useEffect(() => {
    if (
      userPoints &&
      !userPoints?.UserPoints?.questPointsSubType?.find(
        (data) => data?.type === DeveloperQuestSubType.IntractXMetaStreet
      )
    ) {
      claimIntractPoint({
        variables: { evmAddress: evmWalletAddress as string, accessToken },
        onCompleted(data) {
          if (data.claimIntractPoint?.awarded) {
            fetchUserPoints();
          }
        },
      });
    }
  }, [accessToken, userPoints, evmWalletAddress, userPoints?.UserPoints?.questPointsSubType]);

  const { data: galaxyQuestCompleted, loading: checkGalaxyQuestLoading } =
    useCheckGalaxyQuestCompletedQuery({
      variables: {
        evmWalletAddress: evmWalletAddress as string,
        accessToken,
      },
      skip: !evmWalletAddress || !accessToken,
    });

  const { data: galaxyXCryfiQuestCompleted, loading: checkGalaxyXCryfiQuestLoading } =
    useCheckGalaxyXCryfiQuestCompletedQuery({
      variables: {
        evmWalletAddress: evmWalletAddress as string,
        accessToken,
      },
      skip: !evmWalletAddress || !accessToken,
    });

  return {
    fetchCompletedDevQuestCount,
    refetchDevQuestCount,
    completedDevQuestsCount,
    userPoints,
    completedQuests: userPoints?.UserPoints?.questPointsSubType || [],
    galaxyQuestCompleted,
    galaxyXCryfiQuestCompleted,
    isUserPointsLoading,
    loadingQuestData: completedCountLoading || isUserPointsLoading || checkGalaxyQuestLoading || checkGalaxyXCryfiQuestLoading,
  };
}
