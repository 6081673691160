import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { classes, pathTo } from 'utils';
import { Button } from './Button';
import {
  EmptyCircleIcon,
  ExclamationMark,
  EyeIcon,
  FilledCircleIcon,
  EyeSlashIcon,
  CheckIcon,
} from './icons';
import OTPInput from 'react-otp-input';
import { Setter } from 'types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

const ForgotPassword = () => {
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [refreshCaptcha, setRefreshCaptcha] = useState<number>(0);

  const [step, setStep] = useState(1);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState({
    value: '',
    isError: false,
    isTouched: false,
  });
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState({
    value: '',
    isError: false,
    isTouched: false,
  });
  const [resendTimer, setResendTimer] = useState(60);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const passwordValidations = useMemo(() => {
    if (password.length) {
      return {
        length: password.length >= 8,
        number: /\d/gm.test(password),
        case: /^(?=.*[a-z])(?=.*[A-Z]).+$/.test(password),
      };
    }
    return { length: false, number: false, case: false };
  }, [password]);

  const displayTime = useMemo(() => {
    if (resendTimer <= 0) {
      return '00:00';
    }
    const minutes = Math.floor(resendTimer / 60);
    const remainingSeconds = resendTimer % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }, [resendTimer]);

  useEffect(() => {
    if (step == 2 && resendTimer !== 0) {
      const interval = setInterval(() => {
        setResendTimer((prevSeconds) => prevSeconds - 1);
      }, 1000);

      // Cleanup function to clear interval
      return () => clearInterval(interval);
    }
  }, [step]);

  useEffect(() => {
    if (resendTimer === 0) {
      clearInterval(resendTimer);
    }
  }, [resendTimer]);

  const toggleState = useCallback((setter: Setter<boolean>) => {
    setter((prev) => !prev);
  }, []);

  const validate = useCallback(() => {
    if (error) {
      setError('');
    }
    if (step === 1) {
      const emailRegex = /[a-z0-9]+@[a-z0-9]+\.[a-z]{2,3}/;
      const isEmailValid = emailRegex.test(email.value);
      setEmail((prev) => {
        return {
          ...prev,
          isTouched: true,
          isError: !isEmailValid,
        };
      });
      return isEmailValid;
    } else if (step === 3) {
      setConfirmPassword((prev) => {
        return { ...prev, isError: password !== confirmPassword.value, isTouched: true };
      });
      return password === confirmPassword.value;
    }
  }, [email, password, step, confirmPassword]);

  const handleSubmit = useCallback(async () => {
    if (step === 1) {
      if (validate()) {
        setLoading(true);
        try {
          const data = await axios.post(
            `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/forgetPassword`,
            {
              email: email.value,
              captchaToken: captchaToken,
            }
          );

          if (data.status === 200) {
            setCaptchaToken(null);
            setError('');
            setStep(2);
          }
        } catch (e) {
          // @ts-expect-error
          setError(e?.response?.data?.message);
        }
        setLoading(false);
      }
    } else if (step === 2) {
      setLoading(true);
      setRefreshCaptcha(Date.now())
      try {
        const data = await axios.post(`${import.meta.env.VITE_BACKEND_ENDPOINT}auth/verifyOTP`, {
          email: email.value,
          OTP: otp,
          captchaToken,
        });

        if (data.status === 200) {
          setCaptchaToken(null);
          setError('');
          setStep(3);
        }
      } catch (e) {
        // @ts-expect-error
        setError(e?.response?.data?.message);
      }
      setLoading(false);
    } else if (step === 3) {
      if (validate()) {
        setLoading(true);
        try {
          const data = await axios.post(
            `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/changePassword`,
            {
              email: email.value,
              newPassword: password,
              OTP: otp,
              captchaToken,
            }
          );

          if (data.status === 200) {
            setCaptchaToken(null);
            setError('');
            setStep(4);
          }
        } catch (e) {
          // @ts-expect-error
          setError(e?.response?.data?.message);
        }
        setLoading(false);
      }
    }
  }, [email, password, confirmPassword, otp, step, captchaToken]);

  switch (step) {
    case 1:
      return (
        <div className="w-full h-fit">
          <div className="sm:text-xl text-lg">Forgot your password</div>
          <div className="text-gray text-sm mt-1 mb-4">
            Enter the email address with your account and we’ll send an email with confirmation to
            reset your password.
          </div>
          <>
            <div className="flex flex-col gap-4">
              <div
                className={classes(
                  'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
                  email.isError && email.isTouched && 'border-rose-500'
                )}
              >
                <input
                  className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                  placeholder="Email"
                  value={email.value}
                  onChange={(e) => {
                    setEmail((prev) => ({
                      ...prev,
                      value: e.target.value.replaceAll(' ', ''),
                      isTouched: false,
                    }));
                  }}
                />

                {email.isError && email.isTouched && (
                  <div className="flex items-center gap-1 text-rose-500">
                    <InformationCircleIcon className="w-5 h-5" />
                    <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                      Invalid email
                    </p>
                  </div>
                )}
              </div>
              {email.value && (
                <ReCAPTCHA
                  theme="dark"
                  sitekey={import.meta.env.VITE_WATCH_GOOGLE_CAPTCHA_SITEKEY}
                  onChange={(val) => setCaptchaToken(val)}
                />
              )}
              <Button
                variant="primary"
                isDisabled={!email.value || !captchaToken}
                isLoading={loading}
                className="uppercase"
                onClick={handleSubmit}
              >
                send code
              </Button>
            </div>

            {error && (
              <div className="mt-[10px] text-[#FF3A5D] text-xs flex flex-row items-center gap-[6px] justify-center">
                <ExclamationMark />
                {error}
              </div>
            )}
          </>
        </div>
      );
    case 2:
      return (
        <div className="w-full h-fit">
          <div className="sm:text-xl text-lg">Please check your email</div>
          <div className="text-gray text-sm mt-1 mb-4">
            We have sent the code to <span className="text-white">{email.value}</span>
          </div>

          <>
            <div className="flex flex-col md:gap-4 gap-[11px]">
              <OTPInput
                containerStyle="justify-center gap-4"
                numInputs={6}
                onChange={setOtp}
                value={otp}
                inputType="tel"
                shouldAutoFocus
                renderInput={(props) => (
                  <input
                    {...props}
                    className="bg-[#000] border border-solid border-[#39393980] rounded-lg py-[10px] text-sm md:!w-[70px] !w-[40px] sm:!h-[unset] !h-[40px] shadow-[0px_0px_120.6px_0px_rgba(255,_255,_255,_0.08)_inset]"
                  />
                )}
              />
              {(otp.length === 6 || !(resendTimer > 0 || loading)) && (
                <ReCAPTCHA
                  key={refreshCaptcha}
                  theme="dark"
                  sitekey={import.meta.env.VITE_WATCH_GOOGLE_CAPTCHA_SITEKEY}
                  onChange={(val) => {
                    setCaptchaToken(val);
                  }}
                />
              )}
              <div className="flex justify-center items-center gap-1 md:text-sm text-xs">
                <Button
                  variant="plain"
                  classNames={{ base: '!h-[unset]', container: 'md:text-sm text-xs' }}
                  isDisabled={resendTimer > 0 || loading}
                  onClick={async () => {
                    setOtp('');
                    setRefreshCaptcha(Date.now())
                    try {
                      const data = await axios.post(
                        `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/forgetPassword`,
                        {
                          email: email.value,
                          captchaToken,
                        },
                        { withCredentials: true }
                      );

                      if (data.status === 200) {
                        setCaptchaToken(null);
                        setResendTimer(60);
                      }
                    } catch (e) {
                      // @ts-expect-error
                      setError(e?.response?.data?.message);
                    }
                  }}
                >
                  Send code again
                </Button>
                {resendTimer > 0 && <div className="flex items-center">{displayTime}</div>}
              </div>
              <Button
                variant="primary"
                isDisabled={otp.length !== 6 || !captchaToken}
                isLoading={loading}
                className="uppercase md:text-sm text-xs"
                onClick={handleSubmit}
              >
                verification
              </Button>
            </div>

            {error && (
              <div className="mt-[10px] text-[#FF3A5D] text-xs flex flex-row items-center gap-[6px] justify-center">
                <ExclamationMark />
                {error}
              </div>
            )}
          </>
        </div>
      );
    case 3:
      return (
        <div className="w-full h-fit">
          <div className="sm:text-xl text-lg">Create New password</div>
          <div className="text-gray text-sm mt-1 mb-4">
            This password should be different from the previous password.
          </div>
          <>
            <div className="flex flex-col gap-4">
              <div
                className={classes(
                  'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow'
                )}
              >
                <input
                  className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                  placeholder="New Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />{' '}
                <button type="button" onClick={() => toggleState(setShowPassword)}>
                  {showPassword ? (
                    <EyeSlashIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" />
                  ) : (
                    <EyeIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" stroke="#919191" />
                  )}
                </button>
              </div>
              <div
                className={classes(
                  'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
                  confirmPassword.isError && confirmPassword.isTouched && 'border-rose-500'
                )}
              >
                <input
                  className="w-full autofill:bg-clip-text autofill:shadow-[inset 0_0_20px_20px_#23232300] autofill:[-webkit-text-fill-color:#fff]"
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={confirmPassword.value}
                  onChange={(e) => {
                    setConfirmPassword((prev) => ({
                      ...prev,
                      value: e.target.value.replaceAll(' ', ''),
                      isTouched: false,
                    }));
                  }}
                />{' '}
                <button type="button" onClick={() => toggleState(setShowConfirmPassword)}>
                  {showConfirmPassword ? (
                    <EyeSlashIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" />
                  ) : (
                    <EyeIcon className="md:h-5 md:w-5 h-[18px] w-[18px]" stroke="#919191" />
                  )}
                </button>
              </div>
              {confirmPassword.isError && confirmPassword.isTouched && (
                <div className="flex items-center gap-1 text-rose-500 -mt-3">
                  <InformationCircleIcon className="w-5 h-5" />
                  <p className="font-['Chakra_Petch'] text-xs font-normal leading-tight whitespace-nowrap">
                    Password doesn’t match
                  </p>
                </div>
              )}
              <div className="flex flex-col gap-2 -mt-2 mb-1 text-gray text-xs">
                <div
                  className={classes(
                    'flex items-center gap-[6px]',
                    passwordValidations.length && 'text-[#23CA3E]'
                  )}
                >
                  {passwordValidations.length ? <FilledCircleIcon /> : <EmptyCircleIcon />}
                  <span>At least 8 characters</span>
                </div>
                <div
                  className={classes(
                    'flex items-center gap-[6px]',
                    passwordValidations.number && 'text-[#23CA3E]'
                  )}
                >
                  {passwordValidations.number ? <FilledCircleIcon /> : <EmptyCircleIcon />}
                  <span>At least 1 number</span>
                </div>
                <div
                  className={classes(
                    'flex items-center gap-[6px]',
                    passwordValidations.case && 'text-[#23CA3E]'
                  )}
                >
                  {passwordValidations.case ? <FilledCircleIcon /> : <EmptyCircleIcon />}
                  <span>Both upper and lower case letters</span>
                </div>
              </div>
              {passwordValidations.case &&
                passwordValidations.number &&
                passwordValidations.length && (
                  <ReCAPTCHA
                    theme="dark"
                    sitekey={import.meta.env.VITE_WATCH_GOOGLE_CAPTCHA_SITEKEY}
                    onChange={(val) => {
                      setCaptchaToken(val);
                    }}
                  />
                )}
              <Button
                variant="primary"
                isDisabled={
                  !(
                    passwordValidations.case &&
                    passwordValidations.number &&
                    passwordValidations.length
                  ) || !captchaToken
                }
                isLoading={loading}
                className="uppercase"
                onClick={handleSubmit}
              >
                Reset password
              </Button>
            </div>

            {error && (
              <div className="mt-[10px] text-[#FF3A5D] text-xs flex flex-row items-center gap-[6px] justify-center">
                <ExclamationMark />
                {error}
              </div>
            )}
          </>
        </div>
      );
    case 4:
      return (
        <div className="w-full h-fit">
          <div className="sm:text-xl text-lg">
            <div className="flex items-center gap-2">
              <CheckIcon className="p-2 h-10 w-10 bg-black-100 rounded-full" /> Password changed
              successfully
            </div>
          </div>
          <div className="text-gray text-sm mt-1">
            Sign in{' '}
            <Link
              to={pathTo('SignIn')}
              className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
            >
              here
            </Link>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

export default ForgotPassword;
