import { Button, CheckIcon, DiscordSocialIcon, GameLogo, XSocialIcon } from 'components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { pathTo } from 'utils';

export const ConnectSuccess = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  return (
    <div className="flex flex-col w-[100vw] h-[100vh] font-['Chakra_Petch']">
      <div className="flex min-h-[72px] justify-center items-center border-b border-solid border-[#202020]">
        <GameLogo className="w-[150px]" />
      </div>
      {searchParams.get('discord') !== null && (
        <div className="h-full flex justify-center items-center">
          <div className="flex flex-col items-center text-center md:max-w-[50vw] max-w-[80vw] md:p-8 p-6 rounded-[10px] border border-solid text-white border-steel-gray bg-[#000]">
            <div className="relative">
              <DiscordSocialIcon className="md:min-h-[100px] md:min-w-[100px] md:h-[100px] md:w-[100] min-h-[60px] min-w-[60px] h-[60px] w-[60px] rounded-full" />

              <div className="rounded-full bg-black-100 absolute bottom-0 left-0 md:p-1 p-[2px] md:translate-x-[200%] translate-x-[195%] translate-y-[10%]">
                <CheckIcon className="md:h-6 md:w-6 h-4 w-4" />
              </div>
            </div>
            <div className="flex items-center gap-2 md:mt-3 mt-2">
              <div className="md:text-xl text-lg">Verification Successful!</div>
            </div>
            <div className="text-sm text-gray md:mt-2 mt-1 md:max-w-[80%] max-w-[95%] text-center">
              Great! Your account is now verified and connected to Discord.
            </div>
            <Button
              variant="primary"
              className="md:mt-4 mt-3 w-full md:h-10 h-9"
              onClick={() => navigate(pathTo('Home'))}
            >
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
