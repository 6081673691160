import { ClassNames, HTMLAttributes } from 'types';
import { classes } from 'utils';

type Variant = 'primary' | 'secondary' | 'plain' | 'light';

export interface Props$Button extends HTMLAttributes<HTMLButtonElement> {
  isDisabled?: boolean;
  isIcon?: boolean;
  isLoading?: boolean;
  variant?: Variant;
  classNames?: ClassNames<'container'>;
}

export function Buttons({ children, className }: HTMLAttributes<HTMLDivElement>) {
  return <div className={classes('flex space-x-2 [&>*]:flex-1', className)}>{children}</div>;
}

export const Button = (props: Props$Button) => {
  const {
    children,
    variant = 'secondary',
    className,
    isDisabled,
    isIcon,
    classNames,
    isLoading,
    ...rest
  } = props;

  return (
    <button
      type="button"
      className={classes(
        'btn relative h-10 max-h-full',
        isIcon && 'icon',
        variant,
        className,
        classNames?.base
      )}
      disabled={isDisabled || isLoading}
      {...rest}
    >
      <div
        className={classes(
          'flex items-center justify-center whitespace-pre text-sm btnText h-full',
          classNames?.container
        )}
      >
        {isLoading && <div className="loading-spinner" />}
        {isLoading ? <div className="opacity-0">{children}</div> : children}
      </div>
    </button>
  );
};

Button.displayName = 'Button';
