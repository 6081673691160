import { AtomIcon } from './icons';
import { classes, pathTo } from 'utils';
import { Link } from 'react-router-dom';
import { Card } from './Card';
import { Button } from './Button';
import { Skeleton } from './Skeleton';
import { useSessions } from 'context';
import { useSessionCountdown } from 'hooks';

const CountdownTimer = () => {
  const { countdown } = useSessionCountdown();

  return <div>{countdown}</div>;
};

export function ActiveSessionCard() {
  const { activeSession, activeStats } = useSessions();

  return (
    <Card className={classes('border-0 overflow-hidden')}>
      <div className="border-gradient relative z-[5] after:rounded-t-2xl overflow-hidden">
        <div className="h-10 w-full flex justify-center items-center overflow-hidden border-b border-[#ffffff3d] bg-primary-gradient-light">
          <span className="uppercase text-sm md:text-base font-text-bold bg-primary-gradient bg-clip-text text-transparent !leading-none mt-[3px] tracking-[1.4px]">
            <Skeleton.Loader className="w-20 h-6">Active Voting Session</Skeleton.Loader>
          </span>
        </div>
        <div className="relative overflow-hidden bg-[url(../components/icons/wave.svg)] bg-cover bg-no-repeat md:bg-center bg-right">
          <div className="flex justify-between items-center gap-4 p-4 md:p-6">
            <div className="flex items-center md:gap-[14px] gap-[12px] text-sm md:text-xl flex-[3]">
              <Skeleton.Loader className="min-h-9 h-9 w-9 min-w-9 lg:min-h-[60px] lg:h-[60px] lg:w-[60px] lg:min-w-[60px]">
                <img
                  src="/SessionIcon.svg"
                  alt="session-icon"
                  className="min-h-12 h-12 w-12 min-w-12 lg:min-h-[60px] lg:h-[60px] lg:w-[60px] lg:min-w-[60px]"
                />
              </Skeleton.Loader>
              <div className="md:text-lg text-sm break-all overflow-hidden whitespace-pre-line font-text-bold line-clamp-1">
                <Skeleton.Loader className="sm:w-20 w-14 h-6">
                  {activeSession?.name}
                </Skeleton.Loader>
              </div>
            </div>
            <div className="flex flex-col items-end text-[#ffffff99] min-w-[130px]">
              <div className="text-white text-2xl lg:text-3xl">
                <Skeleton.Loader className="w-36 h-6">
                  <CountdownTimer />
                </Skeleton.Loader>
              </div>
              <div className="text-neutral-400 text-sm font-normal leading-normal">
                <Skeleton.Loader className="w-20 h-4">Countdown</Skeleton.Loader>
              </div>
            </div>
          </div>
          <div className="[&>*:nth-child(1)]:[grid-area:top] [&>*:nth-child(2)]:[grid-area:center] [&>*:nth-child(3)]:[grid-area:bottom] lg:[grid-template-areas:'top_center_bottom'] [grid-template-areas:'top_center''bottom_bottom'] grid-cols-[1fr_1fr] border-0 border-t border-solid border-[#282131] grid  lg:grid-cols-[33%_33%_33%]  2xl:[&>*:nth-child(2)]:!justify-center 2xl:[&>*:nth-child(3)]:!justify-start">
            {activeStats?.map(({ Icon, value, label }, index) => {
              return (
                <div
                  className={classes(
                    'flex flex-1 flex-col justify-center lg:items-center items-start gap-[1px] md:gap-1 px-6 pt-[14px] pb-[10px] md:py-[20px] border-0 !md:border-l border-b border-solid border-[#282131] ',
                    index > 0 && 'border-l'
                  )}
                  key={index}
                >
                  <div className="flex items-center gap-2 text-sm text-[20px] md:text-2xl">
                    {Icon && (
                      <Skeleton.Loader className="w-8 h-6">
                        <Icon className="h-6 w-6 lg:h-auto lg:w-auto" />
                      </Skeleton.Loader>
                    )}{' '}
                    <Skeleton.Loader className="w-10 h-6">{value}</Skeleton.Loader>
                  </div>
                  <div className="text-xs text-gray md:text-sm">
                    <Skeleton.Loader className="w-16 h-5">{label}</Skeleton.Loader>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Skeleton.Loader className="h-10 w-full">
        <Link to={pathTo('Session')} className="z-10 relative">
          <Button
            variant="primary"
            className="h-[unset] !py-3 w-full !rounded-t-none font-text-bold"
          >
            <AtomIcon /> Start Voting
          </Button>
        </Link>
      </Skeleton.Loader>
    </Card>
  );
}
