import { CheckIcon, CrossIcon, DefaultIcon, ExclamationMark } from 'components';
import moment from 'moment';
import toast, { ToastOptions } from 'react-hot-toast';
import { twMerge } from 'tailwind-merge';
import { UseCases } from './constants';
import { ReactNode } from 'react';
import { web3Enable, web3FromAddress } from '@polkadot/extension-dapp';

export { default as pluralize } from 'pluralize';

export function classes(...classLists: (string | null | undefined | false)[]) {
  return twMerge(...classLists.map((classList) => (!classList ? null : classList)));
}

export function formatToTwoDigits(num: number) {
  return num > 9 ? num.toString() : '0' + num.toString();
}

export function truncate(value: string | undefined, sideLength = 6): string {
  return value
    ? value.length > sideLength * 2
      ? `${value.substring(0, sideLength)}...${value.substring(value.length - sideLength)}`
      : value
    : '';
}

export function countLines(value: string): number {
  try {
    return value.split('\n').length;
  } catch (e) {
    return 0;
  }
}

export function timeAgo(d?: Date | string | null) {
  return moment(d).fromNow(true);
}

export function customToast(
  message: string | ReactNode,
  isError = false,
  icon?: ReactNode,
  options?: ToastOptions
) {
  const handleCloseToast = () => {
    toast.dismiss(toastId);
  };

  const toastId = toast(
    <div className="flex justify-between items-center gap-2 w-full min-[425px]:w-unset">
      <div className="flex items-center gap-2">
        <div className="min-h-8 min-w-8 flex items-center justify-center bg-[#ffffff1a] rounded-full">
          {icon ? icon : isError ? <ExclamationMark /> : <CheckIcon />}
        </div>
        <div className={classes('text-sm break-words', isError && 'text-red-600')}>{message}</div>
      </div>
      <button onClick={handleCloseToast} className="h-6 w-6 ">
        <CrossIcon stroke="#fff" />
      </button>
    </div>,
    {
      className:
        'w-full !max-w-[unset] min-[425px]:w-[unset] min-[425px]:!max-w-[350px] !relative toast-wrap !pr-[16px] !pl-[20px] !py-[10px] !bg-[#060606] !rounded-lg !border !border-steel-gray !text-white after:top-0 after:left-0 after:w-[6px] after:h-full after:bg-primary-gradient after:absolute overflow-hidden !shadow-[0px_0px_120.6px_0px_rgba(255,_255,_255,_0.08)_inset,_0px_0px_10.2px_0px_rgba(170,_125,_248,_0.25)]',
      ...options,
    }
  );

  return toastId;
}

export function scrollToSection(
  sectionRef: React.RefObject<HTMLElement>,
  options?: ScrollIntoViewOptions
) {
  sectionRef.current?.scrollIntoView({ behavior: 'smooth', ...options });
}

export function getUsecasesIcon(name: string, iconClass: string) {
  const usecase = UseCases.find((item) => item.name === name);
  return usecase ? <usecase.icon className={iconClass} /> : <DefaultIcon className={iconClass} />;
}

export function isChainTag(chain: string) {
  return ['astar', 'ethereum'].includes(chain?.toLowerCase());
}

export async function getSubstrateWalletType(address: string) {
  await web3Enable('analog-watch');
  const { name } = await web3FromAddress(address);
  return name;
}

export const desktopWallet = [
  {
    name: 'Talisman',
    value: 'talisman',
    img: '/wallets/talisman.svg',
  },
  {
    name: 'SubWallet',
    value: 'subwallet-js',
    img: '/wallets/subwallet.svg',
  },
  {
    name: 'Polkadot',
    value: 'polkadot-js',
    img: '/wallets/polkadot.js.svg',
  },
  {
    name: 'Enkrypt',
    value: 'enkrypt',
    img: '/wallets/enkrypt.svg',
  },
  {
    name: 'Fearless',
    value: 'fearless-wallet',
    img: '/wallets/fearless.svg',
  },
];

export const generateCountdown = (time: number) => {
  const padZero = (num: number) => (num < 10 ? `0${num}` : num);
  const duration = moment.duration(time);
  const days = padZero(duration.days());
  const hours = padZero(duration.hours());
  const minutes = padZero(duration.minutes());
  const seconds = padZero(duration.seconds());
  return `${days}:${hours}:${minutes}:${seconds}`;
};

export const shuffleArray = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const isProduction = window.location.host === 'testnet.analog.one';
