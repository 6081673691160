import React from 'react';
import { HTMLAttributes } from 'types';
import { classes } from 'utils';

interface Props extends HTMLAttributes<unknown> {
  as?: React.ElementType;
}

export function Card({ as: Component = 'div', children, className, ...props }: Props) {
  return (
    <Component
      {...props}
      className={classes(
        'border-[0.5px] border-solid border-steel-gray rounded-[10px] bg-light-Black text-white shadow-md flex-[2] flex-col',
        className
      )}
    >
      {children}
    </Component>
  );
}
