import { ReactNode } from 'react';
import { classes } from 'utils';
import { Skeleton } from './Skeleton';
import { ClassNames } from 'types';

export const Overview = ({
  sections = [],
  classname,
  refreshButton,
  classNames,
}: {
  sections: { icon: ReactNode; data: string | number; dataUnit?: string; label: string }[];
  classname?: string;
  refreshButton?: JSX.Element;
  classNames?: ClassNames<'sectionsContainer'>;
}) => {
  return (
    <div
      className={classes(
        "bg-[url(/overviewBg.svg)] bg-cover md:bg-center bg-no-repeat flex flex-col relative p-[20px_24px_0_24px] border-gradient rounded-[10px] after:rounded-[10px] after:from-[#5b346b] after:to-[#6951a3] font-['Chakra Petch']",
        classname,
        classNames?.base
      )}
    >
      <div className="flex items-center justify-between text-white text-lg md:text-xl uppercase !leading-[36px] w-full relative after:absolute after:opacity-[0.4] after:bottom-0 after:left-0 after:content-[''] after:bg-[linear-gradient(140deg,#D274F7_16.37%,#9A74F7_50.02%)] after:w-full after:h-[1px] pb-[10px] md:pb-4">
        <Skeleton.Loader className="w-[150px] h-9">
          <div>Overview</div>
        </Skeleton.Loader>
        {refreshButton}
      </div>
      <div
        className={classes(
          'flex items-center m-[10px_0_12px] md:m-[18px_0]',
          classNames?.sectionsContainer
        )}
      >
        {sections.map((section, index) => {
          return (
            <div
              key={index}
              className={classes(
                'flex-1 md:before:hidden',
                sections.length > 2 &&
                  'relative before:content-[""] before:w-full before:opacity-[0.4] before:h-[1px] before:bg-[linear-gradient(140deg,#D274F7_16.37%,#9A74F7_50.02%)] before:absolute before:-bottom-3 before:left-0 last-of-type:before:hidden md:mb-0 mb-3',
                index === 0 ? 'pr-[23px] ' : 'pl-[23px] ',
                index === 1 &&
                  'relative last-of-type(2):after:content-[""] after:w-[1px] after:opacity-[0.4] after:h-full after:bg-[linear-gradient(140deg,#D274F7_16.37%,#9A74F7_50.02%)] after:absolute after:-bottom-0 after:left-0 last-of-type(2):before:hidden md:last-of-type(2):before:content-[""] md:before:w-[1px] md:before:opacity-[0.4] md:before:h-full md:before:bg-[linear-gradient(140deg,#D274F7_16.37%,#9A74F7_50.02%)] md:before:absolute md:before:-bottom-0 md:before:right-0',
                index === 2 &&
                  'md:relative md:after:content-[""] md:after:w-[1px] md:after:opacity-[0.4] md:after:h-full md:after:bg-[linear-gradient(140deg,#D274F7_16.37%,#9A74F7_50.02%)] md:after:absolute md:after:-bottom-0 md:after:left-0'
              )}
            >
              <div className="flex items-start gap-2">
                <div className="min-w-[24px] w-[24px] h-[24px] !rounded-md pt-1">
                  <Skeleton.Loader className="min-w-[24px] w-[24px] h-[24px] !rounded-md">
                    {section?.icon}
                  </Skeleton.Loader>
                </div>
                <div className="flex flex-col">
                  <div className="text-white text-xl md:text-2xl font-medium leading-[28px] md:leading-[32px]">
                    <Skeleton.Loader className="max-w-[100px] min-w-[50px]">
                      {section?.data}
                      {section?.dataUnit && <span className="pl-2">{section?.dataUnit}</span>}
                    </Skeleton.Loader>
                  </div>
                  <div className="text-neutral-400 max-[400px]:whitespace-nowrap max-[400px]:text-[10px] text-sm font-normal leading-tight mt-1">
                    <Skeleton.Loader className="max-w-[150px]">{section?.label}</Skeleton.Loader>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
