/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useEffect, useLayoutEffect, useState } from 'react';
import { Popover } from '@headlessui/react';
import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { CopyButton } from './CopyButton';
import { useApi } from 'context';
import {
  ExclamationMark,
  GradientDotsIcon,
  GradientSwitchArrowsIcon,
  LogOutIcon,
  UserProfileIcon,
} from './icons';
import { Link, useSearchParams } from 'react-router-dom';
import { desktopWallet, getSubstrateWalletType, pathTo, truncate } from 'utils';
import { useEVM } from 'hooks/quests';
import { VoidFn } from 'types';
import { Tooltip } from './Tooltip';
import { Button } from './Button';

enum OptionType {
  SwitchSubstrate = 'switchSubstrate',
  SwitchEVM = 'switchEVM',
  SignOut = 'signOut',
}

export const AccountMenu = ({
  isOpen,
  handleLogOut,
  close,
}: {
  isOpen: boolean;
  handleLogOut: () => void;
  close?: VoidFn;
}) => {
  const { account, trackEvent } = useApi();
  const { evmWalletAddress, isEvmWalletConnected, open, evmWalletInfo } = useEVM();
  const [_, setSearchParams] = useSearchParams();

  const [show, setShow] = useState<OptionType | undefined>();
  const [substrateWalletIcon, setSubstrateWalletIcon] = useState<string | undefined>();

  useEffect(() => {
    setShow(undefined);
  }, [isOpen]);

  useLayoutEffect(() => {
    (async () => {
      if (account?.walletAddress) {
        const walletType = await getSubstrateWalletType(account?.walletAddress);
        setSubstrateWalletIcon(desktopWallet.find((wallet) => wallet.value === walletType)?.img);
      }
    })();
  }, [account?.walletAddress]);

  if (!account) {
    return null;
  }

  return (
    <Popover.Panel className="md:absolute relative md:top-[64px] md:right-[35px] z-10 md:w-[320px] w-full bg-black py-4 shadow-lg  border border-[#202020] rounded-lg">
      <>
        <div className="h-4 w-4 bg-black border border-[#2A2B3A] rounded  border-b-0 border-l-0 absolute right-6 -rotate-45 top-[-8px]  flex"></div>
        <div className="flex flex-col">
          <div className="px-4 flex items-center gap-2 mb-[18px]">
            <UserProfileIcon className=" h-8 w-8" />
            <div className="flex justify-between items-center flex-1 relative">
              <div className="flex flex-col gap-1 text-white">
                <h4 className="text-sm font-text-bold leading-[14px] break-all line-clamp-1">
                  {account.name}
                </h4>
                <div className="text-gray text-sm leading-[18px] break-all line-clamp-1">
                  {account.email}
                </div>
              </div>
              <div
                className="text-white cursor-pointer"
                onClick={() => {
                  if (show === OptionType.SignOut) setShow(undefined);
                  else setShow(OptionType.SignOut);
                }}
              >
                <GradientDotsIcon className="h-5 w-5" color="#51526C" />
              </div>
              {show === OptionType.SignOut && (
                <button
                  className="flex gap-2 absolute bottom-[-45px] items-center -right-[3px] border bg-[#010101] z-10 border-[#141414] px-3 py-2 rounded-lg text-[#FF7070] text-xs cursor-pointer"
                  onClick={() => {
                    handleLogOut();
                    close && close();
                  }}
                >
                  <div className="h-3 w-3 bg-black border border-[#141414] rounded  border-b-0 border-l-0 absolute right-[5px] -rotate-45 top-[-5px]  flex"></div>
                  <LogOutIcon />
                  <span>Sign out</span>
                </button>
              )}
            </div>
          </div>
          <div className="h-[1px] w-full mb-[14px] bg-[#393939]" />
          <div className="px-4">
            <div className="text-white text-sm mb-[10px]">Your Wallets</div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-1">
                <div className="text-[10px] text-gray uppercase">Substrate Wallet Address</div>
                {!account.walletAddress ? (
                  <div className="flex gap-1 text-gray text-sm">
                    <div className="flex-1 px-[5px] py-[6px] flex flex-row bg-[#000] shadow-primary-shadow items-center border border-solid border-steel-gray rounded-lg">
                      <div className="flex justify-center items-center md:p-1 p-[10px] rounded-md bg-[#ffffff12] md:max-h-8 max-h-8 md:max-w-12 max-w-10 mr-2">
                        <ExclamationCircleIcon stroke="#FC3B46" className="h-5 w-5" />
                      </div>
                      <div className="text-white">No Wallets Connected</div>
                      <Button
                        variant="light"
                        onClick={() => {
                          setSearchParams({ 'connect-wallet': 'true' });
                        }}
                        classNames={{ base: 'ms-auto h-[30px] w-20', container: 'text-xs' }}
                      >
                        Connect
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="text-sm bg-[#000] shadow-primary-shadow px-2 py-2 border border-solid h-10 border-[#393939] rounded-lg">
                    <div className="text-white text-sm flex items-center justify-between gap-2 relative">
                      <div className="flex gap-2 items-center">
                        {substrateWalletIcon ? (
                          <img
                            alt="substrate-wallet-icon"
                            src={substrateWalletIcon}
                            className="h-5 w-5 rounded"
                          />
                        ) : (
                          <div className="relative" data-tooltip-id="fetch-wallet-icon-failed">
                            <ExclamationMark />
                            <Tooltip
                              id="fetch-wallet-icon-failed"
                              content="Unable to fetch your wallet icon"
                              classNames="max-w-[80vw] break-words"
                            />
                          </div>
                        )}
                        <div>{truncate(account.walletAddress, 10)}</div>
                      </div>
                      <CopyButton
                        id={'substrate-address-copy'}
                        value={account.walletAddress}
                        gradientIcon
                        className="ml-1"
                      />
                      <div
                        className="text-white cursor-pointer"
                        onClick={() => {
                          if (show === OptionType.SwitchSubstrate) setShow(undefined);
                          else setShow(OptionType.SwitchSubstrate);
                        }}
                      >
                        <GradientDotsIcon className="h-5 w-5" color="#51526C" />
                      </div>
                      {show === OptionType.SwitchSubstrate && (
                        <Link
                          to={pathTo('SwitchWallet')}
                          onClick={() => {
                            trackEvent('gtm_wallet_change', {
                              action: 'switch_wallet_start',
                            });
                            close && close();
                          }}
                          className="flex gap-1 absolute bottom-[-50px] items-center border border-zinc-700 z-[100] px-3 py-2 right-[-3px] rounded-lg text-xs cursor-pointer bg-[#030303]"
                        >
                          <div className="h-3 w-3 bg-black border border-[#141414] rounded  border-b-0 border-l-0 absolute right-[5px] -rotate-45 top-[-5px]  flex"></div>
                          <GradientSwitchArrowsIcon className="h-5 w-5" />
                          <span className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer">
                            Switch wallet
                          </span>
                        </Link>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex flex-col gap-1">
                <div className="text-[10px] text-gray uppercase">EVM Wallet Address</div>
                {!isEvmWalletConnected || !evmWalletAddress ? (
                  <div className="flex gap-1 text-gray text-sm">
                    <div className="flex-1 px-[5px] py-[6px] flex flex-row bg-[#000] shadow-primary-shadow items-center border border-solid border-steel-gray rounded-lg">
                      <div className="flex justify-center items-center md:p-1 p-[10px] rounded-md bg-[#ffffff12] md:max-h-8 max-h-8 md:max-w-12 max-w-10 mr-2">
                        <ExclamationCircleIcon stroke="#FC3B46" className="h-5 w-5" />
                      </div>
                      <div className="text-white">No Wallets Connected</div>
                      <Button
                        variant="light"
                        onClick={() => {
                          open && open({ view: 'Connect' });
                        }}
                        classNames={{ base: 'ms-auto h-[30px] w-20', container: 'text-xs' }}
                      >
                        Connect
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="text-sm shadow-primary-shadow px-2 py-2 border border-solid h-10 border-[#393939] rounded-lg bg-[#030303] z-50">
                    <div className="text-white text-sm flex items-center justify-between gap-2 relative">
                      <div className="flex gap-2 items-center">
                        {evmWalletInfo?.icon && (
                          <img
                            alt="evm-wallet-icon"
                            src={evmWalletInfo.icon}
                            className="h-5 w-5 rounded"
                          />
                        )}
                        <div>{truncate(evmWalletAddress, 11)}</div>
                      </div>
                      <CopyButton
                        id={'ethereum-address-copy'}
                        value={evmWalletAddress}
                        gradientIcon
                        className="ml-1"
                      />
                      <div
                        className="text-white cursor-pointer"
                        onClick={() => {
                          if (show === OptionType.SwitchEVM) setShow(undefined);
                          else setShow(OptionType.SwitchEVM);
                        }}
                      >
                        <GradientDotsIcon className="h-5 w-5" color="#51526C" />
                      </div>
                      {show === OptionType.SwitchEVM && (
                        <div className="flex flex-col absolute bottom-[-50px] right-[-3px] border border-zinc-700 rounded-lg bg-[#030303]">
                          <div className="h-3 w-3 bg-black border border-[#141414] rounded  border-b-0 border-l-0 absolute right-[5px] -rotate-45 top-[-5px]  flex"></div>
                          <div
                            className="flex gap-1 items-center z-10 px-3 py-2 rounded-lg text-xs cursor-pointer"
                            onClick={() => {
                              open && open({ view: 'Connect' });
                              close && close();
                            }}
                          >
                            <GradientSwitchArrowsIcon className="h-5 w-5" />
                            <span className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer">
                              Switch wallet
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Popover.Panel>
  );
};
