import {
  Button,
  Card,
  Header,
  NoSession,
  Skeleton,
  ActiveSessionCard,
  Star2Icon,
  StarGradientIcon,
  CustomLink,
} from 'components';
import { useWindowSize } from 'hooks';
import { classes, pathTo, truncate } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
import { ClosedSession, useSessions } from 'context';
import { SessionStats } from 'types';

const ClosedSessionCard = ({
  closedStats,
  closedSession,
  isSessionActive,
}: {
  closedStats: Array<SessionStats> | undefined;
  closedSession: ClosedSession | null;
  isSessionActive: boolean;
}) => {
  const { isMobile } = useWindowSize();

  return (
    <Card
      className={classes(
        'h-[327px] md:h-[95%] md:max-w-[30%] md:opacity-50',
        !isSessionActive && 'md:!max-w-[unset] w-full'
      )}
    >
      <div className="h-10 w-full flex justify-center items-center bg-[#ffffff1a] text-mild-gray text-sm md:text-base overflow-hidden">
        <Skeleton.Loader className="w-20 h-6">Closed</Skeleton.Loader>
      </div>
      <div className="pt-[15px] pl-[25px] pr-6 pb-6">
        <div className="flex items-center gap-[14px] text-sm md:text-xl">
          <Skeleton.Loader className="min-h-12 h-12 w-12 min-w-12 lg:min-h-[60px] lg:h-[60px] lg:w-[60px] lg:min-w-[60px]">
            <img
              src="/SessionIcon.svg"
              alt="session-icon"
              className="min-h-[40px] min-w-[40px] md:h-[34px] md:w-[34px]"
            />
          </Skeleton.Loader>
          <div className="line-clamp-1 break-all">
            <Skeleton.Loader className="w-20 h-6">{closedSession?.name}</Skeleton.Loader>
          </div>
        </div>
        <div className="py-3 my-3 border-0 border-t border-b border-solid border-[#202020]">
          {closedStats?.map(({ label, value }, index) => {
            return (
              <div className="flex gap-2 text-gray text-base md:text-sm" key={index}>
                <Skeleton.Loader className="h-5 w-20">{label}</Skeleton.Loader>:
                <div className="text-white text-base font-text-bold">
                  <Skeleton.Loader className="h-5 w-7">{value}</Skeleton.Loader>
                </div>
              </div>
            );
          })}
        </div>
        <div className="flex gap-2 items-center break-all">
          <Skeleton.Loader className="w-9 h-9">
            {isMobile ? (
              <Star2Icon className="min-h-8 min-w-8" />
            ) : (
              <StarGradientIcon className="min-h-8 min-w-8" />
            )}
          </Skeleton.Loader>
          <div className="flex flex-col gap-[2px] text-sm">
            <span className="text-mild-gray text-xs">
              <Skeleton.Loader className="h-3 w-10">Winner</Skeleton.Loader>
            </span>
            <CustomLink
              link={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/view/${
                closedSession?.winnerView?.hashId
              }`}
              className="line-clamp-1 break-all overflow-hidden whitespace-pre-line w-full text-sm"
            >
              <Skeleton.Loader className="h-4 w-24">
                {truncate(closedSession?.winnerView?.name || '')}
              </Skeleton.Loader>
            </CustomLink>
          </div>
        </div>
      </div>
    </Card>
  );
};

const Sessions = () => {
  const { activeSession, closedStats, closedSession } = useSessions();

  const { isMobile } = useWindowSize();

  return (
    <div className="flex flex-col gap-4 md:gap-6">
      <div className="flex justify-between items-center">
        <Header classNames={{ header: '!text-xl md:!text-2xl' }}>Sessions</Header>
        <Skeleton.Loader className="h-6 w-[140px]">
          <Link to={pathTo('ClosedSessions')}>
            <Button
              classNames={{ base: 'uppercase', container: '!text-xs md:!text-sm' }}
              variant="plain"
            >
              VIEW CLOSED SESSIONS
            </Button>
          </Link>
        </Skeleton.Loader>
      </div>
      {!closedSession?.length && !activeSession?.id ? (
        <NoSession />
      ) : isMobile ? (
        <div className="flex items-center">
          {activeSession?.id ? (
            <Swiper direction="horizontal" slidesPerView={1}>
              <SwiperSlide>{activeSession?.id && <ActiveSessionCard />}</SwiperSlide>
              <SwiperSlide>
                {closedSession?.length && (
                  <ClosedSessionCard
                    closedStats={closedStats && closedStats[0]}
                    closedSession={closedSession[0]}
                    isSessionActive={!!activeSession?.id}
                  />
                )}
              </SwiperSlide>
            </Swiper>
          ) : (
            closedSession?.length && (
              <ClosedSessionCard
                closedStats={closedStats && closedStats[0]}
                closedSession={closedSession[0]}
                isSessionActive={!!activeSession?.id}
              />
            )
          )}
        </div>
      ) : (
        <div className="flex items-center">
          {activeSession?.id && <ActiveSessionCard />}
          {closedSession?.length && (
            <ClosedSessionCard
              closedStats={closedStats && closedStats[0]}
              closedSession={closedSession[0]}
              isSessionActive={!!activeSession?.id}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Sessions;
