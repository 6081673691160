import { Button, CustomCalendarIcon, LinkIcon, Skeleton, Tooltip } from 'components';
import { Identicon } from 'components/Identicon';
import { useSessionCountdown, useWindowSize } from 'hooks';
import { truncate } from 'lodash';
import { Link } from 'react-router-dom';
import { classes, isChainTag, timeAgo } from 'utils';
import { ViewDetails } from './ViewDetails';
import { Views } from 'gql';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { VoidFn } from 'types';

const CircularProgressBar = () => {
  const { countdown, progressPercentage } = useSessionCountdown();

  return (
    <CircularProgressbarWithChildren
      className="lg:w-[195px] lg:h-[198px] w-[165px] h-[168px] mx-2"
      strokeWidth={5}
      value={progressPercentage}
      counterClockwise
    >
      <div className="text-[24px] flex items-center flex-col [rotate:180deg] lg:tracking-[3px] [transform:rotateY(180deg)]">
        {countdown}
        <div className="tracking-normal text-steel-gray text-base">Time Left</div>
      </div>
    </CircularProgressbarWithChildren>
  );
};

export function SessionViewCard({
  view,
  index,
  length,
  voteLoading,
  vote,
  tag,
  disableVoting,
  isLoggedIn,
  nextSlideHandler,
  previousSlideHandler,
  viewsCount,
  isLoading,
}: {
  view: Views;
  index: number;
  length?: number;
  voteLoading?: boolean;
  vote: (view: Views) => void;
  tag: { name?: string; slug?: string }[];
  disableVoting?: boolean;
  isLoggedIn?: boolean;
  nextSlideHandler?: VoidFn;
  previousSlideHandler?: VoidFn;
  viewsCount: number;
  isLoading: boolean;
}) {
  const { isMobile, windowSize } = useWindowSize();

  return (
    <div
      className={classes(
        'md:p-6 p-3 flex flex-col rounded-[10px] border-steel-gray border-solid border text-white bg-black',
        windowSize.width < 1280 && 'swiper-no-mousewheel'
      )}
    >
      <div className="flex xl:flex-row flex-col gap-8 mb-1">
        <div className="flex flex-col flex-1 md:w-full xl:w-[calc(100%-325px)] justify-between">
          <div className="flex gap-4  items-center">
            {!isMobile && (
              <Skeleton.Loader className="md:min-h-[128px] md:min-w-[128px] md:max-w-[128px] min-h-[40px] min-w-[40px]">
                <div className="border border-solid border-steel-gray rounded-[10px]  w-full md:min-h-[128px] md:min-w-[128px] md:max-w-[128px] min-h-[40px] min-w-[40px] max-w-[40px] flex items-center justify-center">
                  <img src="/SessionIcon.svg" alt="session-icon" />
                </div>
              </Skeleton.Loader>
            )}
            <div className="flex-1 flex flex-col">
              <div className="text-base uppercase leading-8 flex items-center justify-between gap-3">
                <div className="flex items-center justify-center gap-2">
                  {isMobile && (
                    <Skeleton.Loader className="min-h-[40px] min-w-[40px]">
                      <div className="border border-solid border-[#49474A] p-[7px] rounded-lg">
                        <img src="/SessionIcon.svg" alt="session-icon" className="h-5 w-5" />
                      </div>
                    </Skeleton.Loader>
                  )}
                  <div className="md:text-2xl text-xl [-webkit-line-clamp:1] [-webkit-box-orient:vertical] [display:-webkit-box] break-all overflow-hidden whitespace-pre-line normal-case">
                    <Skeleton.Loader className="h-8 w-28">{view.name}</Skeleton.Loader>
                  </div>
                </div>
                <Skeleton.Loader className="h-6 w-6">
                  <Link
                    to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/view/${view.hashId}`}
                    target="_blank"
                    className="cursor-pointer"
                  >
                    <LinkIcon />
                  </Link>
                </Skeleton.Loader>
              </div>
              <div className=" mt-2 mb-[18px] text-neutral-400 text-sm font-normal break-all font-['Chakra Petch'] leading-tight line-clamp-2  break-word">
                <Skeleton.Loader className="h-4 w-40">{view.description || ''}</Skeleton.Loader>
              </div>
              <div className="flex flex-row text-sm gap-2 h-[30px] flex-wrap scrollbar-white  w-full overflow-auto">
                {(isLoading ? new Array(2).fill('') : tag)?.map(({ name }, index) => {
                  if (!name && !isLoading) return;
                  return (
                    <Skeleton.Loader className="h-6 w-24" key={index}>
                      <div
                        className={classes(
                          'bg-[#ffffff1a] p-[4px] pr-2 rounded-[30px] text-sm h-fit whitespace-pre break-all gap-1 flex items-center justify-center',
                          !isChainTag(name) && 'px-4'
                        )}
                        key={index}
                      >
                        {isChainTag(name) && (
                          <div className="w-[18px] h-[18px] bg-neutral-700 rounded-full flex items-center justify-center">
                            <img
                              height="12px"
                              width="auto"
                              className="h-[12px] w-auto"
                              src={`/chain/${name?.toLowerCase()}.svg`}
                              alt={name}
                            />
                          </div>
                        )}
                        {name}
                      </div>
                    </Skeleton.Loader>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center md:border-b md:border-solid border-[#202020]">
            <div className="flex pr-4 my-4 border-r border-solid border-[#202020] gap-[10px] flex-1 break-words items-center">
              <Skeleton.Loader className="md:min-w-12 md:h-12 min-w-[24px] h-[24px]">
                <div className="md:min-w-12 md:h-12 min-w-[38px] h-[38px] flex items-center justify-center rounded-full bg-black-100">
                  <Identicon value={view?.owner?.walletAddress || ''} size={24} />
                </div>
              </Skeleton.Loader>
              <div className="flex  justify-center flex-col gap-[2px]">
                <Skeleton.Loader className="md:h-4 md:w-28 h-3 w-14">
                  <div className="text-gray md:text-xs text-[10px]">Publisher</div>
                </Skeleton.Loader>
                <div className="break-all md:text-base text-xs line-clamp-1">
                  <Skeleton.Loader className="md:h-5 md:w-36 h-4 w-16">
                    <Link
                      to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/publisher/${
                        view?.owner?.walletAddress
                      }`}
                      target="_blank"
                      className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
                    >
                      {isMobile && view?.owner?.walletAddress
                        ? truncate(view?.owner?.walletAddress)
                        : view?.owner?.walletAddress}
                    </Link>
                  </Skeleton.Loader>
                </div>
              </div>
            </div>
            <div className="flex pl-4 my-4 border-solid border-[#202020]  gap-[10px] flex-1 break-words  items-center">
              <Skeleton.Loader className="md:min-w-12 md:h-12 min-w-[24px] h-[24px]">
                <div className="md:min-w-12 md:h-12 min-w-[38px] h-[38px] flex items-center justify-center rounded-full bg-black-100  ">
                  <CustomCalendarIcon />
                </div>
              </Skeleton.Loader>
              <div className="flex  justify-center flex-col gap-[2px]">
                <Skeleton.Loader className="md:h-4 md:w-28 h-3 w-14">
                  <div className="text-gray md:text-xs text-[10px]">Date Created</div>
                </Skeleton.Loader>
                <Skeleton.Loader className="md:h-5 md:w-36 h-4 w-16">
                  <div className="break-all md:text-base text-xs line-clamp-1">
                    {timeAgo(view?.created_at)} ago
                  </div>
                </Skeleton.Loader>
              </div>
            </div>
          </div>
          <ViewDetails view={view as Views} isLoading={isLoading} />
        </div>

        {!isMobile && (
          <div className="md:relative md:bottom-[unset] md:left-[unset] fixed bottom-0 left-0 w-full max-w-[unset] xl:max-w-[243px]">
            <div className="border-gradient after:rounded-[10px] p-[56px_24px_14px_23px] flex flex-col flex-1 h-full">
              <div className="flex items-center justify-center [rotate:180deg] [transform:rotateY(180deg)]">
                <Skeleton.Loader className="lg:w-[195px] lg:h-[198px] w-[ 165px] h-[168px] mx-2">
                  <CircularProgressBar />
                </Skeleton.Loader>
              </div>
              <Skeleton.Loader
                className="h-6 w-28 xl:my-6 my-4"
                containerClassName="flex justify-center"
              >
                <div className="xl:text-lg text-base flex items-end flex-1 justify-center xl:my-[14px] my-[14px] gap-[6px]">
                  <span>
                    {index + 1}/{length}
                  </span>
                  <span className="text-[#ffffff99]">No. of Views</span>
                </div>
              </Skeleton.Loader>
              <div className="flex items-center justify-center xl:justify-end xl:gap-2 gap-2 xl:flex-col flex-row-reverse relative z-40 xl:flex-1 ">
                <Skeleton.Loader className="h-10 w-56">
                  <Button
                    data-tooltip-id={'vote-btn-tooltip'}
                    className="w-full cursor-pointer xl:order-1 order-2"
                    variant="primary"
                    isDisabled={disableVoting}
                    isLoading={voteLoading}
                    onClick={() => vote(view)}
                  >
                    Vote
                  </Button>
                </Skeleton.Loader>
                {!isLoggedIn && (
                  <Tooltip
                    id={'vote-btn-tooltip'}
                    content={'Please Sign In for participate'}
                    delay={0}
                  />
                )}
                <Skeleton.Loader className="h-10 w-56">
                  <Button
                    isDisabled={viewsCount === 1 || index === viewsCount - 1}
                    classNames={{
                      base: 'w-full cursor-pointer xl:order-2 order-1',
                    }}
                    onClick={nextSlideHandler}
                  >
                    Next
                  </Button>
                </Skeleton.Loader>
                <Skeleton.Loader className="h-10 w-56">
                  <Button
                    isDisabled={viewsCount === 1 || index === 0}
                    classNames={{
                      base: 'after:hidden xl:order-3 order-3 w-full h-9 px-3 py-[7px] rounded-lg border border-zinc-700 backdrop-blur-[44px] justify-center items-center gap-2.5 inline-flex',
                      container: 'text-white',
                    }}
                    onClick={previousSlideHandler}
                  >
                    Previous
                  </Button>
                </Skeleton.Loader>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
