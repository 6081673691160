import { Header } from 'components';
import Accordian from './Accordian';
import { FAQ_DATA, classes } from 'utils';
import { useApi } from 'context';

export function FAQPage() {
  const { trackEvent } = useApi();
  return (
    <div className="lg:max-w-[66%] md:pt-[60px] md:pb-[40px] py-[36px] mx-auto">
      <div className="flex flex-col gap-4 md:gap-8">
        <Header classNames={{ header: 'text-2xl normal-case' }}>FAQs</Header>
      </div>
      <div className="mt-3">
        {FAQ_DATA.map(({ faq, section }, index) => (
          <div key={index} className={classes('mb-4', index !== 0 && 'mt-10')}>
            <div className={'text-white md:text-xl text-lg mb-3'}>{section}</div>
            <Accordian items={faq || []} onTitleClick={(title) => trackEvent('faq_clicks', {questions: title})} />
          </div>
        ))}
      </div>
    </div>
  );
}
