import React, { useEffect, useRef, useState } from 'react';
import { ChevronGradient, CheckBox, Button, BottomModal } from 'components';
import { classes } from 'utils';
import { ClassNames } from 'types';
import { useOutsideClick, useWindowSize } from 'hooks';

interface DropdownProps {
  options: string[];
  onSelect: (selectedOption: string) => void;
  value?: string;
  classNames?: ClassNames<
    'container' | 'selector' | 'optionsContainer' | 'selectedValue' | 'menuLabel'
  >;
  title?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  menuLabel?: string;
  hideApplyButton?: boolean;
}

export const Select: React.FC<DropdownProps> = ({
  options,
  onSelect,
  value,
  classNames,
  title,
  leftIcon,
  rightIcon,
  menuLabel,
  hideApplyButton,
}) => {
  const impactRef: any = useRef();
  const [selectedOption, setSelectedOption] = useState<string>();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useOutsideClick(impactRef, () => setIsOpen(false));

  useEffect(() => {
    setSelectedOption(value);
  }, [value]);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
  };

  const toggleDropdown = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  const { windowSize } = useWindowSize();

  return (
    <div
      ref={impactRef}
      className={classes('inline-block text-left md:relative', classNames?.base)}
    >
      <div>
        <button
          type="button"
          onClick={() => (windowSize.width >= 768 ? toggleDropdown() : setIsModalOpen(true))}
          className={classes(
            'inline-flex justify-between items-center text-sm min-w-[104px] text-white rounded-lg h-[36px] pl-3 pr-[10px] py-[7px] gap-[6px] border border-solid border-dark-gray focus:border-light-purple hover:border-light-purple w-full',
            classNames?.selector
          )}
        >
          <div className="flex flex-row gap-1 items-center">
            {leftIcon}
            {value ? (
              <>
                {title && `${title} :`}{' '}
                <div className={classes('capitalize', classNames?.selectedValue)}>{value}</div>
              </>
            ) : (
              `${title}`
            )}
          </div>
          {rightIcon || (
            <ChevronGradient
              className={classes('h-4 w-4 transition-transform', isOpen && 'rotate-180')}
            />
          )}
        </button>
      </div>

      {isOpen && (
        <div
          className={classes(
            'absolute right-0 top-10 z-50 mt-2 rounded-xl bg-[#060606] border border-solid border-[#ffffff1a] w-[245px] ',
            classNames?.container
          )}
        >
          <div className="flex flex-col">
            <>
              {menuLabel && (
                <div
                  className={classes(
                    'text-[#FFFFFF80] text-[10px] pt-3 px-4 pb-[6px]',
                    classNames?.menuLabel
                  )}
                >
                  {menuLabel}
                </div>
              )}
            </>
            <div
              className={classes(
                'gap-2 flex flex-col pb-4 border-0 border-b border-solid border-[#ffffff1a]',
                classNames?.optionsContainer
              )}
            >
              {options.map((option, index) => (
                <button
                  key={option}
                  onClick={() => {
                    if (hideApplyButton) {
                      onSelect(option || '');
                      closeDropdown();
                    } else {
                      handleSelect(option);
                    }
                  }}
                  className={classes('flex flex-row gap-2 items-center px-4 ')}
                >
                  <CheckBox isChecked={selectedOption === option} className="w-5 h-5" />
                  <div
                    className={classes(
                      'flex items-center gap-[7px] justify-between capitalize text-[#919191]',
                      selectedOption === option && 'text-white'
                    )}
                  >
                    {option}
                  </div>
                </button>
              ))}
            </div>
            {!hideApplyButton && (
              <div className="p-4">
                <Button
                  variant="primary"
                  className="!w-full"
                  onClick={() => {
                    onSelect(selectedOption || '');
                    closeDropdown();
                  }}
                >
                  Apply
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      <BottomModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} title={menuLabel}>
        <div className="w-full">
          <div className={classes('gap-2 flex flex-col pb-4', classNames?.optionsContainer)}>
            {options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleSelect(option)}
                className={classes('flex flex-row gap-2 items-center')}
              >
                <CheckBox isChecked={selectedOption === option} className="w-5 h-5" />
                <div
                  className={classes(
                    'flex items-center gap-[7px] justify-between capitalize text-gray'
                  )}
                >
                  {option}
                </div>
              </button>
            ))}
          </div>
          <div className="flex flex-row gap-4">
            <Button variant="secondary" className="!w-full" onClick={() => setIsModalOpen(false)}>
              Cancel
            </Button>
            <Button
              variant="primary"
              className="!w-full"
              onClick={() => {
                onSelect(selectedOption || '');
                setIsModalOpen(false);
              }}
            >
              Apply
            </Button>
          </div>
        </div>
      </BottomModal>
    </div>
  );
};
