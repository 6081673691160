import { Modal } from './Modal';
import { Setter } from 'types';
import { Header } from './Header';
import { Button } from './Button';
import { useCookies } from 'react-cookie';
import { useEarlyBonusPointsQuery } from 'gql';

const EarlyBonus = ({
  isConfirmed,
  setIsConfirmed,
}: {
  isConfirmed: boolean;
  setIsConfirmed: Setter<boolean>;
}) => {

  const [{ accessToken }] = useCookies(['accessToken']);
  const { data, loading } = useEarlyBonusPointsQuery({
    variables: { accessToken },
    onCompleted(data) {
      if (data.earlyBonusPoints === 0) {
        setIsConfirmed(true);
      }
    },
    skip:isConfirmed
  });

  if (loading || !data?.earlyBonusPoints || data.earlyBonusPoints <= 0) {
    return null;
  }


  return (
    <Modal
      isOpen={!isConfirmed}
      classNames={{ base: 'w-full z-50 bg-black md:p-8 p-6', modalBase: 'mt-0 max-h-full' }}
      isCloseHidden
    >
      <div className="w-full">
        <Header classNames={{ header: 'capitalize' }}>Thank You for Your Participation!</Header>
        <div className="flex flex-col gap-2 text-gray mt-3 overflow-y-scroll scrollbar-white pr-2">
          <p>
            We sincerely appreciate your early participation in our incentivized testnet program.
            Your patience has been invaluable. To show our gratitude, we’re excited to reward you
            with an extra <b>{data.earlyBonusPoints} ATP</b>. Thank you for being a crucial part of our journey!
          </p>
        </div>
        <Button variant="primary" className="w-full mt-6" onClick={() => setIsConfirmed(true)}>
          Continue Exploring
        </Button>
      </div>
    </Modal>
  );
};

export default EarlyBonus;
