/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import {
  AtomAlertIcon,
  Button,
  ClosedIcon,
  CupGroupIcon,
  CustomCalendarIcon,
  DiamondIcon,
  EyeIcon,
  GraphIcon,
  Header,
  LinkIcon,
  Modal,
  ReferralLink,
  StarGroupIcon,
  StarIcon,
} from 'components';
import {
  ActiveVotingSessionDocument,
  VotingSession,
  WinnerViewType,
  _Count,
  useActiveVotingSessionLazyQuery,
  useSessionWinnerViewLazyQuery,
  useVotingSessionEndedSubscription,
  useVotingSessionListLazyQuery,
  useVotingSessionPointsDistributedSubscription,
  useVotingSessionPointsLazyQuery,
  useVotingSessionStartedSubscription,
  useVotingSessionUpdatedSubscription,
} from 'gql';
import { ElementType, createContext, useContext, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Maybe, SessionStats, Setter } from 'types';
import { classes, customToast, isChainTag, pathTo, timeAgo, truncate } from 'utils';
import { useCookies } from 'react-cookie';
import { Identicon } from 'components/Identicon';
import { useWindowSize } from 'hooks';
import { useAuth } from './AuthContext';
import { useApi } from './api';

export type ClosedSession = {
  _count?: Maybe<_Count>;
  created_at: string;
  id: number;
  name?: string | null;
  winnerView?: Maybe<WinnerViewType>;
};

interface Value {
  activeSession: Maybe<VotingSession | undefined>;
  closedSession: Maybe<(ClosedSession | null)[] | undefined>;
  activeStats: SessionStats[];
  closedStats: Array<Array<SessionStats>> | undefined;
  isActiveSessionLoading: boolean;
  isClosedSessionsLoading: boolean;
  setIsVoteSuccessOpen: Setter<boolean>;
}

export const SessionContext = createContext<Value>({
  walletAccount: null,
  isConnecting: false,
} as unknown as Value);

export function SessionProvider({ children }: React.PropsWithChildren) {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { isSuccessLogin } = useAuth();
  const { account, fetchUserPoints, userPoints } = useApi();
  const { pathname } = useLocation();
  const { isMobile } = useWindowSize();
  const navigate = useNavigate();

  const [fetchActiveSession, { data: activeSessionData, loading: isActiveSessionLoading }] =
    useActiveVotingSessionLazyQuery({ fetchPolicy: 'cache-and-network' });

  const [fetchClosedSessionList, { data: closedSessionsData, loading: isClosedSessionsLoading }] =
    useVotingSessionListLazyQuery({
      variables: { accessToken },
      fetchPolicy: 'cache-and-network',
    });

  const [fetchWinnerView, { data: winnerView }] = useSessionWinnerViewLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const [fetchSessionPoints, { data: userSessionPoints }] = useVotingSessionPointsLazyQuery({
    variables: { accessToken },
    fetchPolicy: 'cache-and-network',
  });

  const [showSessionResults, setShowSessionResult] = useState(false);
  const [isVoteSuccessOpen, setIsVoteSuccessOpen] = useState(false);
  const [activeSession, activeStats] = useMemo(() => {
    const activeStats = [
      {
        Icon: GraphIcon as unknown as ElementType,
        value: activeSessionData?.activeVotingSession?._count?.votes,
        label: 'No. of Votes',
      },
      {
        Icon: EyeIcon as unknown as ElementType,
        value: activeSessionData?.activeVotingSession?._count?.views,
        label: 'Analog Views',
      },
      { Icon: StarIcon as unknown as ElementType, value: '10-140 ATP', label: 'Expected Reward' },
    ];

    return [activeSessionData?.activeVotingSession, activeStats];
  }, [activeSessionData?.activeVotingSession]);

  const [closedSession, closedStats] = useMemo(() => {
    const closed = [...(closedSessionsData?.votingSessionList || [])].sort(
      (a, b) => +new Date(b?.created_at) - +new Date(a?.created_at)
    );

    const closedStats = closed.map((session) => [
      {
        value: new Number(session?._count?.votes).toLocaleString(),
        label: 'No. of Votes',
      },
      { value: session?._count?.views, label: 'Views' },
      {
        value: `${
          userSessionPoints?.votingSessionPoints?.sessionPoints?.find(
            (el) => el?.sessionId === session?.id
          )?.points || 0
        } ATP`,
        label: 'Earnings',
      },
    ]);

    return [closed, closedStats];
  }, [
    closedSessionsData?.votingSessionList,
    userSessionPoints?.votingSessionPoints?.sessionPoints,
  ]);

  const value = useMemo(
    () => ({
      activeSession,
      closedSession,
      isActiveSessionLoading,
      isClosedSessionsLoading,
      activeStats,
      closedStats,
      setIsVoteSuccessOpen,
    }),
    [
      activeSession,
      closedSession,
      activeStats,
      closedStats,
      isActiveSessionLoading,
      isClosedSessionsLoading,
      setIsVoteSuccessOpen,
    ]
  );

  useEffect(() => {
    if (
      !activeSessionData?.activeVotingSession &&
      [
        pathTo('AdminPanel'),
        pathTo('Game'),
        pathTo('Home'),
        pathTo('ClosedSessions'),
        pathTo('Session'),
        pathTo('AllViews'),
      ].includes(pathname)
    ) {
      fetchActiveSession({ fetchPolicy: 'cache-first' });
    }

    if (
      !closedSessionsData?.votingSessionList &&
      [pathTo('Game'), pathTo('ClosedSessions')].includes(pathname)
    ) {
      fetchClosedSessionList({ fetchPolicy: 'cache-first' });
    }

    if (
      !userSessionPoints?.votingSessionPoints &&
      accessToken &&
      [pathTo('ClosedSessions'), pathTo('Game')].includes(pathname)
    ) {
      fetchSessionPoints({ fetchPolicy: 'cache-first' });
    }
  }, [pathname, accessToken]);

  useEffect(() => {
    if (isVoteSuccessOpen) fetchUserPoints();
  }, [isVoteSuccessOpen]);

  useVotingSessionStartedSubscription({
    onData() {
      fetchActiveSession();
      customToast(
        <div>
          New Voting Session{' '}
          <Link
            to={pathTo('Session')}
            className="cursor-pointer bg-primary-gradient bg-clip-text text-transparent"
            onClick={() => toast.dismiss()}
          >
            Started
          </Link>
        </div>,
        false,
        <AtomAlertIcon />,
        {
          duration: 4000,
        }
      );
    },
  });

  useVotingSessionUpdatedSubscription({
    onData(options) {
      options.client.cache.evict({ fieldName: 'activeVotingSession', broadcast: true });
    },
  });

  useVotingSessionEndedSubscription({
    onData(options) {
      options.client.cache.evict({ fieldName: 'activeVotingSession' });
      options.client.cache.evict({ fieldName: 'activeVotingSessionViews' });
      isSuccessLogin && fetchClosedSessionList();
      customToast(
        'The last voting session has ended. Rewards will be distributed soon.',
        false,
        <ClosedIcon />,
        { duration: 6000 }
      );
    },
  });

  useVotingSessionPointsDistributedSubscription({
    onData(options) {
      if (isSuccessLogin) {
        fetchUserPoints();
        fetchSessionPoints();
      }
      customToast(
        <div>
          The active voting session has ended.{' '}
          <span
            className="cursor-pointer bg-primary-gradient bg-clip-text text-transparent"
            onClick={() => {
              if (!isSuccessLogin) {
                toast.dismiss();
                return navigate(pathTo('SignIn'));
              }

              options.data.data?.votingSessionPointsDistributed?.votingSession?.id &&
                fetchWinnerView({
                  variables: {
                    accessToken,
                    sessionId: options.data.data?.votingSessionPointsDistributed?.votingSession?.id,
                  },
                });
              setShowSessionResult(true);
              toast.dismiss();
            }}
          >
            Check
          </span>{' '}
          to see your rewards!
        </div>,
        false,
        <ClosedIcon />,
        { duration: Infinity }
      );
    },
  });

  return (
    <SessionContext.Provider value={value}>
      {children}
      <Modal
        isOpen={showSessionResults}
        isCloseInside
        setIsOpen={setShowSessionResult}
        classNames={{
          content: '!items-start font-["Chakra_Petch"]',
          modalBase: 'mt-0 max-h-full',
          base: "p-8 md:pt-[26px] pt-[18px] md:pb-[29px] pb-6 md:px-[32px] px-6 w-full font-['Chakra_Petch'] border-[0.5px] border-[#49474A] max-h-[calc(100vh-60px)] overflow-auto scrollbar-white max-w-2xl",
          closeIcon: ' md:right-[25px] right-[10px] top-[16px]',
        }}
      >
        <Header
          classNames={{
            header:
              'flex flex-col gap-1 text-[24px] text-center text-white text-xs font-medium  uppercase leading-[20px] flex-1 px-[17px]',
            base: 'w-full',
          }}
        >
          The Voting Session has officially ended
        </Header>
        <div className="my-4 flex items-center justify-center w-full">
          <CupGroupIcon />
        </div>
        <p className=" text-center text-white md:text-2xl text-lg font-normal  uppercase md:leading-10 w-full">
          Reward Summary
        </p>
        <div className="w-full md:px-6 px-4 md:py-3 py-4 items-center flex h-auto justify-center rounded-lg bg-[#0B0A0C]  mt-[25px] relative bg-[url('/background.svg')] bg-[5%_25%] shadow-primary-shadow">
          <div className="flex-1 border-r border-[#ffffff26] py-3 md:pr-0 pr-4">
            <div className=" text-white md:text-[32px] text-2xl font-normal leading-8 mb-[6px]md:max-w-full max-w-[280px] md:mb-[6px]">
              +
              {(closedSession?.length &&
                userSessionPoints?.votingSessionPoints?.sessionPoints?.find(
                  (el) => el?.sessionId === closedSession[0]?.id
                )?.points) ||
                '0'}
            </div>
            <div className=" text-neutral-400 md:text-sm text-[12px] font-normal leading-5 gap-[10px] flex items-center justify-between md:pr-6 whitespace-pre">
              Earnings
              <StarIcon className="h-5 w-5" />
            </div>
          </div>
          <div className="flex-1 md:pl-6 pl-4 py-3">
            <div className=" text-white md:text-[32px] text-2xl font-normal leading-8 md:mb-[6px]">
              {userPoints?.UserPoints?.total || '0'}
            </div>
            <div className=" text-neutral-400 md:text-sm text-[12px] font-normal leading-5 gap-[10px] flex items-center justify-between ">
              Total ATP Points
              <DiamondIcon />
            </div>
          </div>
        </div>
        {winnerView?.SessionWinnerView && (
          <div className="bg-primary-gradient-light p-4 mt-5 rounded-[10px] flex flex-col gap-2 w-full">
            <h4 className="text-white font-text-bold uppercase text-sm">Winner</h4>
            <div className="bg-[#06030D] p-4 rounded-[10px]  border-dark-gray border">
              <div className="flex gap-3  items-center relative">
                {!isMobile && (
                  <div className="border border-steel-gray rounded-md w-24 h-24 min-w-24 md:flex items-center justify-center hidden">
                    <img src="/SessionIcon.svg" alt="session-icon" />
                  </div>
                )}
                <div className="flex flex-col w-full">
                  <div className="flex justify-between items-center">
                    <div className="flex gap-3 items-center ">
                      <div className="border border-solid border-[#49474A] p-[7px] rounded-lg flex items-center justify-center md:hidden">
                        <img src="/SessionIcon.svg" alt="session-icon" className="w-5 h-5" />
                      </div>
                      <h4 className="text-base leading-8 flex items-center justify-between gap-3">
                        {winnerView?.SessionWinnerView?.name}
                      </h4>
                    </div>
                    <Link
                      to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/view/${
                        winnerView?.SessionWinnerView?.hashId
                      }`}
                      target="_blank"
                      className="cursor-pointer"
                    >
                      <LinkIcon className="md:absolute top-0 right-0" />
                    </Link>
                  </div>
                  <p className=" text-neutral-400 text-sm line-clamp-2 break-word mb-2 md:mt-0 mt-2">
                    {winnerView?.SessionWinnerView?.description || 'No description available'}
                  </p>
                  <ul className="m-0 p-0 flex gap-2">
                    {winnerView?.SessionWinnerView?.tags && (
                      <div className="flex flex-wrap flex-1 gap-2">
                        {winnerView.SessionWinnerView?.tags.map(
                          (tag: Maybe<{ name: string; slug: string }>, index: number) => {
                            if (!tag?.name) return;
                            return (
                              <div
                                className={classes(
                                  'bg-[#ffffff1a] py-[2px] pl-1 pr-2 rounded-[30px] text-xs h-fit whitespace-pre break-all gap-1 flex items-center justify-center',
                                  !isChainTag(tag.name) && 'px-4'
                                )}
                                key={index}
                              >
                                {isChainTag(tag.name) && (
                                  <div className="w-[16px] h-[16px] bg-neutral-700 rounded-full flex items-center justify-center">
                                    <img
                                      height="12px"
                                      width="auto"
                                      className="h-[12px] w-auto"
                                      src={`/chain/${tag.name.toLowerCase()}.svg`}
                                      alt={tag.name}
                                    />
                                  </div>
                                )}
                                {tag.name}
                              </div>
                            );
                          }
                        )}
                      </div>
                    )}
                  </ul>
                </div>
              </div>
              <div className="border-t border-dark-gray py-3 grid grid-cols-2 mt-4 md:gap-5">
                <div className="flex gap-2 border-r border-dark-gray flex-1 md:pr-0 pr-4  items-center">
                  <div className="md:min-w-12 md:h-12 min-w-[28px] h-[28px] bg-black-100 rounded-full flex justify-center items-center">
                    <Identicon
                      value={winnerView?.SessionWinnerView?.owner.walletAddress || ''}
                      size={24}
                      className="md:h-6 md:w-6 h-4 w-4"
                    />
                  </div>
                  <div className="flex flex-col justify-center break-all h-fit">
                    <h6 className="text-gray md:text-xs text-[10px] line-clamp-1">Publisher</h6>
                    <Link
                      to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/publisher/${
                        winnerView?.SessionWinnerView?.owner.walletAddress
                      }`}
                      target="_blank"
                      className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer md:text-sm leading-none text-xs line-clamp-2"
                    >
                      {truncate(winnerView?.SessionWinnerView?.owner.walletAddress || '')}
                    </Link>
                  </div>
                </div>
                <div className="flex gap-2  flex-1 md:pl-0 pl-4 items-center">
                  <div className="md:min-w-12 md:h-12 min-w-[28px] h-[28px] bg-black-100 rounded-full flex justify-center items-center">
                    <CustomCalendarIcon className="md:h-6 md:w-6 h-4 w-4" />
                  </div>
                  <div className="flex flex-col justify-center break-all h-fit">
                    <h6 className="text-gray md:text-xs text-[10px] line-clamp-1">Date created</h6>
                    <p className="md:text-sm  leading-none text-xs text-white line-clamp-2">
                      {timeAgo(winnerView?.SessionWinnerView?.created_at)} ago
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <Link
          to={pathTo('ClosedSessions')}
          className="w-full"
          onClick={() => {
            setShowSessionResult(false);
          }}
        >
          <Button variant="primary" className="w-full mt-5">
            View closed sessions
          </Button>
        </Link>
      </Modal>
      <Modal
        isOpen={isVoteSuccessOpen}
        isCloseInside
        setIsOpen={setIsVoteSuccessOpen}
        className="p-8 md:pt-[26px] pt-[18px] md:pb-[29px] pb-6 md:px-[32px] px-6 w-full font-['Chakra_Petch'] border-[0.5px] border-[#49474A]"
        classNames={{ content: '!items-start' }}
      >
        <Header
          classNames={{
            header:
              'flex flex-col gap-1 text-[24px] text-center text-white text-xs font-medium  uppercase leading-[20px] flex-1 px-[17px]',
            base: 'w-full',
          }}
        >
          Congrats! Your vote has been counted! 🎉{' '}
        </Header>
        <div className="my-4 flex items-center justify-center w-full">
          <StarGroupIcon />
        </div>
        <p className=" text-center text-white md:text-[32px] text-lg  font-normal  uppercase md:leading-10 w-full">
          Boom!
        </p>
        <div className=" text-center text-neutral-400 text-sm font-normal  leading-tight w-full md:mt-2">
          {activeSession?._count?.votes || '0'} Total Votes
        </div>
        <div className="w-full md:px-6 px-4 md:py-3 py-4 items-center flex h-auto justify-center rounded-lg bg-[#0B0A0C]  mt-[25px] relative bg-[url('/background.svg')] bg-[5%_25%] shadow-primary-shadow">
          <div className="flex-1 border-r border-[#ffffff26] py-3 md:pr-0 pr-4">
            <div className=" text-white md:text-[32px] text-2xl font-normal leading-8 mb-[6px]md:max-w-full max-w-[280px] md:mb-[6px]">
              {userPoints?.UserPoints?.total || '0'}
            </div>
            <div className=" text-neutral-400 md:text-sm text-[12px] font-normal leading-5 gap-[10px] flex items-center justify-between md:pr-6 whitespace-pre">
              Total ATP Points
              <DiamondIcon />
            </div>
          </div>
          <div className="flex-1 md:pl-6 pl-4 py-3">
            <div className=" text-white md:text-[32px] text-2xl font-normal leading-8 md:mb-[6px]">
              +10
            </div>
            <div className=" text-neutral-400 md:text-sm text-[12px] font-normal leading-5 gap-[10px] flex items-center justify-between ">
              Earnings
              <StarIcon className="h-5 w-5" />
            </div>
          </div>
        </div>
        <div className="text-neutral-400 text-[10px] font-normal uppercase leading-4 mt-6 mb-2">
          Share your referral link
        </div>
        <ReferralLink
          classNames={{
            base: 'w-full !m-0',
          }}
          referralCode={account?.referralCode || ''}
        />
        <Link to={pathTo('Quests')} className="w-full">
          <Button variant="primary" className="w-full mt-5">
            Find out how to make more points!
          </Button>
        </Link>
      </Modal>
    </SessionContext.Provider>
  );
}

export const useSessions = () => useContext(SessionContext);
