import { Setter } from 'types';
import { Modal, InformationIcon, Card, Button, ReferralLink, Tooltip, Skeleton } from 'components';
import { useState } from 'react';
import { useApi } from 'context';
import { Maybe } from 'gql';

interface ReferralModalProps {
  isOpen: boolean;
  setIsOpen: Setter<boolean>;
  referralCode: Maybe<string>;
}

const ReferralCard = ({
  setIsOpen,
  referralCode,
  referralCount,
  expectedReferralPoints,
}: {
  setIsOpen: Setter<boolean>;
  referralCode: Maybe<string> | undefined;
  referralCount: Maybe<number> | undefined;
  expectedReferralPoints: Maybe<number> | undefined;
}) => {
  return (
    <Card className="flex-none">
      <div className="uppercase text-xs h-[32px] w-full flex justify-center items-center bg-primary-gradient-light md:h-[30px] overflow-hidden font-text-bold">
        <Skeleton.Loader className="h-5 w-20">Referral Link</Skeleton.Loader>
      </div>
      <div className="flex justify-between p-7 flex-col md:flex-row gap-4 md:gap-0">
        <div className="flex text-3xl pr-5 max-w-none md:max-w-[150px] lg:max-w-none lg:p-0">
          <div className="font-text-bold">
            <Skeleton.Loader className="w-10">{referralCount || '0'}</Skeleton.Loader>
          </div>
          <div className="flex flex-col ml-3 md:ml-4">
            <div className="text-sm md:text-base uppercase font-text-bold">
              <Skeleton.Loader className="w-20">Your Referrals</Skeleton.Loader>
            </div>
            <div className="text-gray text-sm">
              <Skeleton.Loader className="w-40">Refer friends and get rewarded!</Skeleton.Loader>
            </div>
          </div>
        </div>
        <div className="flex relative before:bg-dark-gray before:w-full before:absolute before:h-[1px] pt-4 before:top-0 md:pt-0 md:before:h-auto md:before:static md:before:w-[1px] md:before:mr-6 lg:-ml-[25px] md:-ml-[20px]">
          <div className="flex flex-col justify-around lg:justify-between">
            <div className="text-sm text-gray">
              <Skeleton.Loader className="w-40 h-4">Accumulated Referral Points</Skeleton.Loader>
            </div>
            <div className="flex items-center gap-1 text-gray text-xl text-white">
              <Skeleton.Loader className="w-5 !rounded-lg">
                {expectedReferralPoints || '0'}
              </Skeleton.Loader>
              <div data-tooltip-id={'referral-btn-tooltip'} className="relative">
                <Skeleton.Loader className="w-7">
                  <InformationIcon />
                  <Tooltip
                    id={'referral-btn-tooltip'}
                    content={'Track your accumulated referral points here.'}
                    delay={0}
                    classNames="max-w-[90vw]"
                  />
                </Skeleton.Loader>
              </div>
            </div>
          </div>
        </div>
        <Skeleton.Loader className="w-44 h-10">
          <Button
            className="!px-2 lg:!px-4 btn-light-bg"
            isDisabled={!referralCode}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            Create Referral Link
          </Button>
        </Skeleton.Loader>
      </div>
    </Card>
  );
};

const ReferralModal = ({ isOpen, setIsOpen, referralCode }: ReferralModalProps) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isCloseInside
      classNames={{
        base: 'md:p-[24px_32px_32px_32px] p-[17px_24px_24px]',
        content: 'items-start',
      }}
    >
      <div className="text-xl">Create Referral Link</div>
      <p className="mt-1 text-sm text-gray">
        Generate your unique referral link to boost your Analog Watch Points (ATP) earnings when you
        invite your friends to join!
      </p>
      <ReferralLink referralCode={referralCode as string} />
      <div className="flex w-full md:gap-6 gap-4 md:mt-6 mt-5">
        <Button className="w-full" onClick={closeModal}>
          Cancel
        </Button>
        <Button variant="primary" className="w-full" onClick={closeModal}>
          Done
        </Button>
      </div>
    </Modal>
  );
};

export function Referral() {
  const { account, userPoints } = useApi();
  const [isReferralOpen, setIsReferralOpen] = useState(false);

  return (
    <>
      <ReferralCard
        setIsOpen={setIsReferralOpen}
        referralCode={account?.referralCode}
        referralCount={account?._count?.referredToUsers}
        expectedReferralPoints={userPoints?.UserPoints?.expectedReferral}
      />
      {account?.referralCode && (
        <ReferralModal
          isOpen={isReferralOpen}
          setIsOpen={setIsReferralOpen}
          referralCode={account.referralCode}
        />
      )}
    </>
  );
}
