import { Link, LinkProps } from 'react-router-dom';
import { Fragment, ReactNode } from 'react';
import { ClassNames, HTMLAttributes, Page } from 'types';
import { classes, pathTo } from 'utils';

type BreadCrumb = {
  page: Page;
  label?: ReactNode;
  props?: LinkProps;
};

interface Props extends HTMLAttributes<HTMLDivElement> {
  classNames?: ClassNames<'icon'>;
  pages?: BreadCrumb[];
}

const LABELS: Partial<Record<Page, ReactNode>> = {
  Game: 'Watch Game',
  Quests: 'Quests',
  Session: 'Active Session',
  AllViews: 'All Views',
  Home: 'Home',
  Leaderboard: 'Leaderboard',
  ClosedSessions: 'Closed Sessions',
};

export function BreadCrumbs({ className, classNames, pages }: Props) {
  return (
    <div
      className={classes(
        'md:text-sm text-xs flex flex-wrap items-center space-x-2 w-fit pl-0 select-none mb-4',
        classNames?.base,
        className
      )}
    >
      {pages?.map(({ page, label }, index) => (
        <Fragment key={index}>
          {index < pages.length - 1 ? (
            <Link
              className="hyperlink bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
              to={pathTo(page)}
              state={{ bcIndex: index }}
            >
              {label || LABELS[page]}
            </Link>
          ) : (
            <span className="text-sm text-gray">{label || LABELS[page]}</span>
          )}
          {index < pages.length - 1 && (
            <span className="bg-primary-gradient bg-clip-text text-transparent">/</span>
          )}
        </Fragment>
      ))}
    </div>
  );
}
