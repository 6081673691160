import { ClosedSessionCard, Header, NoSession, Skeleton } from 'components';
import { useSessions } from 'context';

export function ClosedSessions() {
  const { closedSession, closedStats, isClosedSessionsLoading } = useSessions();

  return (
    <Skeleton.Provider isLoading={isClosedSessionsLoading}>
      <div className="md:mt-[60px] mt-[36px] mb-4">
        <Header
          classNames={{
            base: 'flex justify-between md:flex-row flex-col gap-4 md:gap-0 mb-6',
            header: 'text-2xl',
          }}
        >
          Closed Sessions
          <div className="text-base text-gray normal-case md:mt-2 mt-1">
            Key metrics and analytics across different voting sessions.
          </div>
        </Header>
        <div className="h-full w-full">
          {closedSession?.length ? (
            <div className="grid lg:grid-cols-[50%_50%] gap-6">
              {closedSession?.map((el, index) => (
                <ClosedSessionCard
                  data={el}
                  stats={closedStats && closedStats[index]}
                  key={index}
                />
              ))}
            </div>
          ) : (
            <NoSession text="No Closed Voting Sessions Available" />
          )}
        </div>
      </div>
    </Skeleton.Provider>
  );
}
