import { Button, Card, LinkIcon, Skeleton } from 'components';
import { useApi, useSessions } from 'context';
import { ActiveVotingSessionDocument, Views, useVoteMutation } from 'gql';
import { useWindowSize } from 'hooks';
import { client } from 'main';
import { useCallback, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { customToast, isChainTag, timeAgo, truncate } from 'utils';

const ViewCard = ({ view }: { view: any }) => {
  const [{ accessToken }] = useCookies(['accessToken']);
  const { account } = useApi();
  const { isMobile } = useWindowSize();
  const { activeSession, setIsVoteSuccessOpen } = useSessions();
  const [voteMutation, { loading: voteLoading }] = useVoteMutation();

  const disableVoting = useMemo(
    () =>
      !account?.id ||
      !!account?.votingSessionParticipated?.find(
        (session) => session.votingSessionId == activeSession?.id
      ),
    [account, activeSession?.id]
  );

  const vote = useCallback(
    (view: Views) => {
      if (activeSession?.id)
        try {
          voteMutation({
            variables: {
              accessToken,
              data: {
                viewHash: view.hashId,
                VotingSessionId: activeSession.id,
              },
            },
            onCompleted(data) {
              if (data.vote.status === 'SUCCESS') {
                client.writeQuery({
                  query: ActiveVotingSessionDocument,
                  data: {
                    activeVotingSession: {
                      ...activeSession,
                      _count: {
                        ...activeSession._count,
                        votes: (activeSession._count?.votes || 0) + 1,
                      },
                    },
                  },
                });
                setIsVoteSuccessOpen(true);
              }
              if (data.vote.error) customToast(data.vote.error, true);
            },
            refetchQueries: ['GetUser'],
          });
        } catch (e) {
          console.log(e);
        }
    },
    [accessToken, activeSession?.id, voteMutation]
  );

  return (
    <Card className="flex w-full md:p-6 p-4 rounded-lg bg-light-Black">
      <div className="flex md:flex-row flex-col items-center md:gap-6 gap-4 relative">
        <Skeleton.Loader
          className="md:min-h-[109px] md:min-w-[109px] md:max-w-[109px] md:max-h-[109px] min-h-[98px] min-w-full leading-[unset]"
          containerClassName="md:w-[unset] w-full"
        >
          <div className="flex justify-center items-center border border-solid border-steel-gray rounded-lg overflow-hidden relative md:min-w-[111px] md:min-h-[111px] h-[100px] md:w-fit w-full bg-[url('/background.svg')] bg-contain">
            <div className="shadow-primary-shadow rounded-lg opacity-50 absolute h-full w-full" />
            <div className="md:p-[22px]">
              <img
                src="/SessionIcon.svg"
                alt="session-icon"
                className="md:min-w-[68px] md:min-h-[68px] max-w-[54px] max-h-[54px]"
              />
            </div>
          </div>
        </Skeleton.Loader>

        <div className="flex md:flex-row flex-col md:gap-3 gap-4 justify-between w-full">
          <div className="flex flex-col">
            <div className="flex flex-row w-full">
              <Skeleton.Loader className="h-5 w-28">
                <div className="text-lg whitespace-pre-line break-word line-clamp-2">
                  {view.name}
                </div>
              </Skeleton.Loader>
              {isMobile && (
                <Skeleton.Loader className="h-5 w-5 flex" containerClassName="ml-auto">
                  <Link
                    to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/view/${view.hashId}`}
                    target="_blank"
                    className="cursor-pointer flex ml-auto"
                  >
                    <LinkIcon />
                  </Link>
                </Skeleton.Loader>
              )}
            </div>
            <Skeleton.Loader className="h-5 w-[100%]">
              <div className="md:text-sm text-xs text-gray break-all whitespace-pre-line break-word line-clamp-1">
                {view.description || 'No description available'}
              </div>
            </Skeleton.Loader>
            {view.tags && (
              <div className="flex flex-wrap flex-1 gap-2 md:mt-2 mt-3">
                {view.tags.map((tag: { name?: string; slug?: string }, index: number) => (
                  <Skeleton.Loader className="h-[19px] w-28">
                    <span
                      className="text-white md:text-sm text-xs py-1 px-2 rounded-full break-all flex bg-[#ffffff1a] gap-[3px] "
                      key={index}
                    >
                      {tag?.name && isChainTag(tag.name) && (
                        <div className="w-[18px] h-[18px] bg-neutral-700 rounded-full flex items-center justify-center">
                          <img
                            height="12px"
                            width="auto"
                            className="h-[12px] w-auto"
                            src={`/chain/${tag.name?.toLowerCase()}.svg`}
                            alt={tag.name}
                          />
                        </div>
                      )}
                      {tag?.name || tag?.slug}
                    </span>
                  </Skeleton.Loader>
                ))}
              </div>
            )}
            <div className="flex md:flex-row flex-col md:items-center lg:gap-4 md:gap-3 gap-2 text-[#919191] text-xs md:mt-[11px] mt-[15px]">
              <Skeleton.Loader className="h-[19px] w-28 leading-[unset]">
                <div className="flex flex-row gap-1 items-center">
                  <div>Publisher:</div>
                  <Link
                    to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/publisher/${
                      view?.owner?.walletAddress
                    }`}
                    target="_blank"
                    className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
                  >
                    {truncate(view?.owner?.walletAddress)}
                  </Link>
                </div>
              </Skeleton.Loader>

              <Skeleton.Loader className="h-[19px] w-28 leading-[unset]">
                <div className="flex flex-1 gap-1">
                  <div>Data: </div>
                  <div className="text-white">{`Created ${timeAgo(view.created_at)} ago`}</div>
                </div>
              </Skeleton.Loader>
            </div>
          </div>
          <div className="flex flex-col justify-between items-end">
            {!isMobile && (
              <Skeleton.Loader className="h-5 w-5">
                <Link
                  to={`${import.meta.env.VITE_WATCH_FRONTEND_URL}/view/${view.hashId}`}
                  target="_blank"
                  className="cursor-pointer"
                >
                  <LinkIcon />
                </Link>
              </Skeleton.Loader>
            )}
            <Skeleton.Loader
              className="h-9 md:w-16 w-full"
              containerClassName="md:w-[unset] w-full"
            >
              <Button
                className="self-end h-[36px] md:max-w-[61px] w-full"
                variant="primary"
                isDisabled={disableVoting}
                isLoading={voteLoading}
                onClick={() => vote(view)}
              >
                vote
              </Button>
            </Skeleton.Loader>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default ViewCard;
