import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useApi, useAuth } from 'context';
import { pathTo } from 'utils';

export function ProtectedRoute({ children }: { children: ReactElement }) {
  const { isSuccessLogin } = useAuth();
  const { account } = useApi();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isSuccessLogin && !account?.id) {
      navigate(pathTo('Home') + pathTo('SignIn'));
    }
  }, [account, isSuccessLogin]);

  return !isSuccessLogin && !account?.id ? null : children;
}
