import { Header } from './Header';
import { classes, pluralize } from 'utils';
import { HTMLAttributes } from 'types';
import { Card } from './Card';
import { CopyButton } from './CopyButton';

export function Definition({
  children,
  lineCount,
  copyString,
  className,
  copyKey,
}: HTMLAttributes<HTMLDivElement> & { lineCount: number; copyString: string; copyKey: string }) {
  return (
    <Card className={classes('px-2 py-2 mt-4', className)}>
      <div className="w-full ">
        <Header
          accessory={<CopyButton className="h-4 w-4" id={copyKey} value={copyString} />}
          tag="div"
          classNames={{
            base: 'flex justify-between pb-[5px] items-center',
            header: 'text-sm',
            accessory: 'w-auto mb-0',
          }}
        >
          <div>
            {lineCount} {pluralize('line', lineCount)}
          </div>
        </Header>
        <div className="relative">{children}</div>
      </div>
    </Card>
  );
}
