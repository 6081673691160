import { Dispatch, ReactNode, useEffect, useMemo, useState } from 'react';
import { classes } from 'utils';
import { ClassNames, Maybe } from 'types';
import { PageControl, PageSizeControl } from 'components';

export function usePageFilter<T>(
  data: Maybe<T[]>,
  defaultPageSize = 3,
  pageSizeOptions?: string[],
  classNames?: ClassNames<'sizeControl' | 'btnContainer'>
): {
  controller: ReactNode;
  currentPage: T[];
  pageIndex: number;
  pageSize: number;
  setPageIndex: Dispatch<number>;
  setMaxPageIndex: Dispatch<number>;
} {
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [maxPageIndex, setMaxPageIndex] = useState(0);

  useEffect(() => {
    if (data?.length) {
      setMaxPageIndex(Math.ceil(data?.length / pageSize));
    }
  }, [data?.length, pageSize]);

  const currentPage = useMemo(() => {
    if (data) {
      const tempArray = [...data];
      return tempArray.slice(pageIndex * pageSize, (1 + pageIndex) * pageSize);
    }
    return [];
  }, [data, pageIndex, pageSize]);

  useEffect(() => {
    if (maxPageIndex && pageIndex > maxPageIndex - 1) {
      setPageIndex(maxPageIndex - 1);
    }
  }, [maxPageIndex]);

  return {
    controller: (
      <div
        className={classes(
          'flex justify-between items-center mt-4 h-auto sm:h-10 text-sm md:gap-0 gap-3',
          classNames?.base
        )}
      >
        <PageSizeControl
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageSizeOptions={pageSizeOptions}
          className={classes('flex items-center justify-center gap-[6px]', classNames?.sizeControl)}
        />
        <PageControl
          isPreviousAble={pageIndex > 0}
          onPrevious={() => setPageIndex((prev) => Math.max(0, prev - 1))}
          isNextAble={pageIndex < maxPageIndex}
          onNext={() => setPageIndex((prev) => Math.min(maxPageIndex, prev + 1))}
          pageIndex={pageIndex}
          setPageIndex={setPageIndex}
          pageCount={maxPageIndex}
          classNames={{
            result: classes('block'),
            base: classes('flex sm:space-x-4'),
            btnContainer: classNames?.btnContainer,
          }}
        />
      </div>
    ),
    currentPage,
    pageIndex,
    pageSize,
    setPageIndex,
    setMaxPageIndex,
  };
}
