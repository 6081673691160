import { HTMLAttributes } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { classes } from 'utils';

interface Tooltip extends HTMLAttributes<HTMLDivElement> {
  id: string;
  content?: string;
  isClickable?: boolean;
  classNames?: string;
  classNameArrow?: string;
  afterHide?: () => void;
  delay?: number;
  rest?: any;
}

export function Tooltip({
  id,
  delay,
  content = '',
  isClickable,
  classNames,
  classNameArrow,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  afterHide = () => {},
  ...rest
}: Tooltip) {
  return (
    <ReactTooltip
      id={id}
      opacity={1}
      content={content}
      className={classes(
        '!bg-light-Black !text-white !text-sm !rounded-lg border-[0.5px] border-solid border-steel-gray z-10',
        classNames
      )}
      classNameArrow={classes('border-b border-r border-solid border-steel-gray', classNameArrow)}
      afterHide={afterHide}
      delayHide={delay}
      clickable={isClickable}
      {...rest}
    />
  );
}
