import { useVerifyCredentialQuery } from 'gql';
import { createContext, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useLocalStorage } from 'usehooks-ts';

type AuthContext = {
  isSuccessLogin: boolean;
  isAdmin: boolean | null;
};

const AuthContext = createContext<AuthContext>({ isSuccessLogin: false, isAdmin: false });

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isSuccessLogin, setIsSuccessLogin] = useLocalStorage('isLoginSuccess', false);
  const [referralCode] = useLocalStorage('referralCode', '');
  const [landingSource] = useLocalStorage('landingSource', '');
  const [Dmailusercode] = useLocalStorage('Dmailusercode', '');
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [{ accessToken }] = useCookies(['accessToken']);

  useVerifyCredentialQuery({
    variables: {
      accessToken,
      referredCode: referralCode,
      referrerSite: landingSource,
      Dmailusercode: Dmailusercode,
    },
    onCompleted(data) {
      setIsAdmin(data.verifyCredential.user.isAdmin);
      setIsSuccessLogin(true);
      if (data.verifyCredential.referred) localStorage.removeItem('referralCode');

      if (data.verifyCredential.referrerSite) localStorage.removeItem('landingSource');
      if (data.verifyCredential.Dmailusercode) localStorage.removeItem('Dmailusercode');
    },
    onError() {
      setIsSuccessLogin(false);
      setIsAdmin(false);
    },
    skip: !isSuccessLogin && !accessToken,
    fetchPolicy: 'no-cache',
  });

  return (
    <AuthContext.Provider value={{ isSuccessLogin, isAdmin }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
