import React from 'react';
import { Modal } from './Modal';
import { Setter } from 'types';
import { Header } from './Header';
import { Button } from './Button';

const TermsOfService = ({
  isConfirmed,
  setIsConfirmed,
}: {
  isConfirmed: boolean;
  setIsConfirmed: Setter<boolean>;
}) => {
  return (
    <Modal
      isOpen={!isConfirmed}
      classNames={{ base: 'w-full z-50 bg-black md:p-8 p-6', modalBase: 'mt-0 max-h-full' }}
      isCloseHidden
    >
      <div className="w-full">
        <Header classNames={{ header: 'capitalize' }}>Terms of Service</Header>
        <div className="flex flex-col gap-2 text-gray mt-1 overflow-y-scroll scrollbar-white pr-2">
          <p>
            We’ve made significant updates to our{' '}
            <a
              href={window.location.origin + '/terms-of-service.pdf'}
              target="_blank"
              rel="noreferrer"
              className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
            >
              Terms of Service
            </a>{' '}
            that you must agree to abide by when using the Analog Incentivized Testnet. These
            updated Terms are crucial as they support our evolving and new features and provide you
            with essential information about participating in the Analog Incentivized Testnet.
          </p>
          <p>
            The Terms apply to your access to and use of the platform, websites, technologies,
            features, and other products and services (collectively called the “Services”) provided
            or made available by Analog Time Corporation Limited, a Seychelles International
            Business Corporation.
          </p>
          <p>
            We encourage you to thoroughly review the updated Terms in their entirety, and feel free
            to{' '}
            <a
              href={window.location.origin + '/terms-of-service.pdf'}
              target="_blank"
              rel="noreferrer"
              className="bg-primary-gradient bg-clip-text text-transparent cursor-pointer"
            >
              download hard copies
            </a>{' '}
            for your reference.
          </p>
        </div>
        <Button variant="primary" className="w-full mt-6" onClick={() => setIsConfirmed(true)}>
          I Accept
        </Button>
      </div>
    </Modal>
  );
};

export default TermsOfService;
