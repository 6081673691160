import { classes } from 'utils';
import { TableBodyType, TableHeaderType, TableType } from 'types';
import { Button, SortIcon } from 'components';
import { usePageFilter } from 'hooks';
import { Skeleton } from './Skeleton';

const TableHeader = ({
  columns,
  classNames,
  withGradient,
  showMobileCards,
  rowStyle,
}: TableHeaderType) => {
  return (
    <>
      <div
        className={classes(
          'text-mild-gray',
          showMobileCards ? 'hidden md:block pl-6 pt-4 pb-[10px]' : 'block',
          classNames?.headingBase
        )}
      >
        <div className={classNames?.row} style={rowStyle}>
          {columns?.map(
            ({ title, sortable = false }: { title: any; sortable?: boolean }, index: number) => {
              return (
                <div
                  key={index}
                  className={classes(
                    'text-[10px] text-ellipsis overflow-hidden whitespace-nowrap',
                    !showMobileCards && 'border-0 border-solid border-b border-steel-gray',
                    classNames?.headingContainer
                  )}
                >
                  <div className={classes('flex items-center', classNames?.columns)}>
                    <span
                      className={classes(
                        'uppercase',
                        !showMobileCards && 'leading-[25px] text-neutral-400 text-xs'
                      )}
                    >
                      <Skeleton.Loader className="w-[50px] h-[14px]"> {title}</Skeleton.Loader>
                    </span>
                    {sortable && (
                      <Button variant="plain" isIcon className="!h-[unset]">
                        <SortIcon />
                      </Button>
                    )}
                  </div>
                </div>
              );
            }
          )}
        </div>
      </div>
    </>
  );
};

const TableBody = ({
  classNames,
  columns,
  data,
  withGradient,
  controller,
  isLoading,
  showMobileCards,
  rowStyle,
}: TableBodyType) => {
  return (
    <div className={classNames?.base}>
      <div className={classNames?.rowWrapper}>
        {/* {data?.length ? ( */}
        {(isLoading ? new Array(10).fill(null) : data)?.map((row: any, idx: number) => {
          return (
            <div
              className={classes(
                'py-[9px] pl-6 ',
                withGradient &&
                  idx == 0 &&
                  'border-0 border-solid border-t border-[#D274F7] bg-[linear-gradient(140deg,_var(--tw-gradient-stops))] from-[#d274f71a_16.37%] to-[#9a74f71a_50.02%]',
                withGradient &&
                  idx == 1 &&
                  'border-0 border-solid border-t border-[#D274F780] bg-[linear-gradient(140deg,_var(--tw-gradient-stops))] from-[#d274f712_16.37%] to-[#9a74f712_50.02%]',
                withGradient &&
                  idx == 2 &&
                  'border-0 border-solid border-t border-[#D274F749] bg-[linear-gradient(140deg,_var(--tw-gradient-stops))] from-[#d274f70D_16.37%] to-[#9a74f70D_50.02%]',
                (!withGradient || idx > 2) && 'border-0 border-solid border-t border-steel-gray',
                classNames?.row
              )}
              style={rowStyle}
              key={idx}
            >
              {columns.map((col: any, index: number) => (
                <div
                  className={classes(
                    'flex flex-col gap-[5px] overflow-hidden justify-center',
                    !showMobileCards && 'border-0 border-solid border-b border-steel-gray',
                    classNames?.colWrapper,
                    classNames?.contentContainer
                  )}
                  key={index}
                >
                  <div
                    className={classes('w-[75px] h-5', showMobileCards ? 'md:hidden' : 'hidden')}
                  >
                    <span
                      className={classes(
                        'leading-5 capitalize text-offWhite text-xs',
                        showMobileCards ? 'md:hidden' : 'hidden'
                      )}
                    >
                      {col.title}
                    </span>
                  </div>
                  {col.render({ ...row, colIndex: index, columnKey: col.key, rowIndex: idx })}
                </div>
              ))}
            </div>
          );
        })}
        {/* ) : */}

        {data && !data.length && (
          <div className="flex justify-center text-base border-0 border-solid border-t border-steel-gray py-2">
            {!isLoading && 'No Data Found'}
          </div>
        )}

        {controller && (
          <div
            className={classes(
              'px-6 py-1 border-0 border-solid border-t border-steel-gray',
              !showMobileCards && 'border-t-0'
            )}
          >
            {controller}
          </div>
        )}
      </div>
    </div>
  );
};

export const Table = ({
  classNames,
  columns,
  data,
  withGradient,
  showPagination,
  defaultPageSize,
  pageSizeOptions,
  showMobileCards = true,
  isLoading,
  rowStyle,
}: TableType) => {
  const { controller, currentPage } = usePageFilter(data, defaultPageSize, pageSizeOptions, {
    base: 'mt-0',
  });

  return (
    <>
      <div
        className={classes(
          'bg-light-Black text-white shadow align-middle inline-block overflow-x-auto min-w-full sdfsdfsd',
          showMobileCards
            ? ' w-fit border-solid !border-[0.5px] border-steel-gray rounded-[10px]'
            : 'w-full overflow-auto overflow-x-auto min-w-full',
          classNames?.base
        )}
      >
        <TableHeader
          columns={columns}
          classNames={classNames}
          withGradient={withGradient}
          showMobileCards={showMobileCards}
          rowStyle={rowStyle}
        />

        <TableBody
          withGradient={withGradient}
          classNames={{
            row: classes(classNames?.row, classNames?.bodyRow),
            rowWrapper: classNames?.bodyWrapper,
            base: classNames?.bodyBase,
            colWrapper: classNames?.colWrapper,
            contentContainer: classNames?.contentContainer,
          }}
          columns={columns}
          controller={showPagination && controller}
          data={showPagination ? currentPage : data}
          isLoading={isLoading}
          showMobileCards={showMobileCards}
          rowStyle={rowStyle}
        />
      </div>
    </>
  );
};
