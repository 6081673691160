import React, { Fragment } from 'react';
import { pathTo } from 'utils';
import { Link } from 'react-router-dom';
import { RightArrowIcon } from 'components';

export function NotFound() {
  return (
    <Fragment>
      <div className="z-10 flex flex-col gap-8 sm:gap-7 h-[75vh] justify-center items-center">
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-center text-[#ffffff4d] text-[120px] sm:text-[220px] font-medium leading-[normal]">
            404
          </h1>
          <div>
            <h2 className="text-white text-[32px] sm:text-5xl">Page not Found</h2>
          </div>
          <div className="mt-2">
            <span className="text-[#A6A3AC] text-sm sm:text-base">Oops! Page not found </span>
          </div>
          <Link to={pathTo('Home')} className="cursor-pointer">
            <div className="mt-6 flex items-center gap-1">
              <span className="text-white text-base"> Go to Home Page </span>
              <RightArrowIcon />
            </div>
          </Link>
        </div>
      </div>
    </Fragment>
  );
}
