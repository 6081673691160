import { HTMLAttributes } from 'types';

export function GradientPlusIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6.5 12H18.5"
        stroke="url(#paint0_linear_3267_116587)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 18V6"
        stroke="url(#paint1_linear_3267_116587)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3267_116587"
          x1="7.8966"
          y1="12.1151"
          x2="7.94605"
          y2="12.8199"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3267_116587"
          x1="12.6164"
          y1="7.381"
          x2="13.4494"
          y2="7.46344"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function GradientMinusIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 12H18"
        stroke="url(#paint0_linear_3267_116693)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3267_116693"
          x1="7.3966"
          y1="12.1151"
          x2="7.44605"
          y2="12.8199"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function TrophyIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.61255 12.375V13.95"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.86304 16.5H13.363V15.75C13.363 14.925 12.688 14.25 11.863 14.25H7.36304C6.53804 14.25 5.86304 14.925 5.86304 15.75V16.5Z"
        stroke="white"
        strokeWidth="0.8"
        strokeMiterlimit="10"
      />
      <path
        d="M5.11255 16.5H14.1126"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.88274 11.3025C5.30774 10.395 4.25024 8.7 4.25024 6.75V4.5C4.25024 2.8425 5.59274 1.5 7.25024 1.5H11.7502C13.4077 1.5 14.7502 2.8425 14.7502 4.5V6.75C14.7502 9.615 12.4627 11.94 9.61274 12C9.57524 12 9.50024 12 9.50024 12"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6018 8.73672C4.0393 8.55672 3.5443 8.22672 3.1543 7.83672C2.4793 7.08672 2.0293 6.18672 2.0293 5.13672C2.0293 4.08672 2.8543 3.26172 3.9043 3.26172H4.3918C4.2418 3.60672 4.1668 3.98922 4.1668 4.38672V6.63672C4.1668 7.38672 4.3243 8.09172 4.6018 8.73672Z"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3972 8.73672C14.9597 8.55672 15.4547 8.22672 15.8447 7.83672C16.5197 7.08672 16.9697 6.18672 16.9697 5.13672C16.9697 4.08672 16.1447 3.26172 15.0947 3.26172H14.6072C14.7572 3.60672 14.8322 3.98922 14.8322 4.38672V6.63672C14.8322 7.38672 14.6747 8.09172 14.3972 8.73672Z"
        stroke="white"
        strokeWidth="0.8"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
