import { useEffect, useState } from 'react';
import { useSessions } from 'context';
import moment from 'moment';
import { generateCountdown } from 'utils';

export function useSessionCountdown() {
  const { activeSession } = useSessions();
  const [countdown, setCountdown] = useState('');
  const [progressPercentage, setProgressPercentage] = useState(0);

  useEffect(() => {
    const start = moment(activeSession?.created_at);
    const end = moment(activeSession?.endDate);
    const countDownFunction = () => {
      const now = moment();
      const diffTime = end.diff(now);
      setCountdown(diffTime >= 0 ? generateCountdown(diffTime) : '00:00:00:00');
      const totalDiff = end.diff(start);
      setProgressPercentage(Math.floor(((diffTime >= 0 ? diffTime : -1) / totalDiff) * 100));
    };

    countDownFunction();
    const interval = setInterval(countDownFunction, 1000);
    return () => clearInterval(interval);
  }, [activeSession]);

  return { countdown, progressPercentage };
}
