import { createWeb3Modal } from '@web3modal/wagmi/react';
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config';
import { WagmiProvider } from 'wagmi';
import { useEffect, useState } from 'react';
import { mainnet } from 'wagmi/chains';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// 0. Setup queryClient
const queryClient = new QueryClient();

const chains = [mainnet];

const projectId = '6b865223017b2f5478147d7a59fa4138';

// 2. Create wagmiConfig
const metadata = {
  name: 'Incentivized Testnet',
  description: 'Incentivized Testnet',
  url: document.location.href,
  icons: ['/icons/apple-touch-icon.png'],
};
const featuredWallets = [
  '971e689d0a5be527bac79629b4ee9b925e82208e5168b733496a09c0faed0709',
  'c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96',
];

const wagmiConfig = defaultWagmiConfig({ chains, projectId, metadata });

createWeb3Modal({
  wagmiConfig,
  projectId,
  defaultChain: mainnet,
  featuredWalletIds: featuredWallets,
});

export function WalletProvider({ children }: { children: React.ReactNode }) {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);

  return (
    <>
      {ready ? (
        <WagmiProvider config={wagmiConfig}>
          <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
        </WagmiProvider>
      ) : null}
    </>
  );
}
