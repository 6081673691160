import { Fragment, useEffect, useState } from 'react';
import { classes } from 'utils';
import { Outlet, useLocation } from 'react-router-dom';
import { SideBar } from './SideBar';
import { TopBar } from './TopBar';
import { SessionProvider, useApi } from 'context';
import { Toaster } from 'react-hot-toast';
import TermsAndConditions from './TermsAndConditions';
import { useLocalStorage } from 'usehooks-ts';
import TermsOfService from './TermOfService';
import EarlyBonus from './EarlyBonus';

export const Layout = () => {
  const [termsAndConditions, setTermsAndConditions] = useLocalStorage('termsAndConditions', false);
  const [isToSAgreed, setIsToSAgreed] = useLocalStorage('IncentivizedTermsOfServiceAgreed', false);
  const [isEarlyBonusRead, setEarlyBonusRead] = useLocalStorage('isEarlyBonusRead', false);
  const { trackEvent, account } = useApi();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isNotReady, setIsNotReady] = useState(true);
  const path = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    trackEvent &&
      trackEvent('gtm_page_view', {
        page: path.pathname,
        location: window.location.href,
      });
  }, [path]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsNotReady(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <SessionProvider>
      <div className="flex flex-row font-['Chakra_Petch']">
        <SideBar
          setIsOpen={setIsMenuOpen}
          classNames={{
            base: classes(isMenuOpen && 'absolute w-full bg-black z-[80] flex flex-col'),
            content: classes(isMenuOpen && 'w-full'),
          }}
        />
        <main className={classes('relative flex max-w-full flex-1 flex-col w-[calc(100%-225px)]')}>
          <TopBar isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
          <Fragment>
            <div className="w-full md:h-[calc(100vh_-_72px)] h-[calc(100vh_-_124px)] overflow-auto scrollbar-white">
              {/* <AnnouncementBanner /> */}
              <div className="lg:px-[60px] w-full px-4">
                <Outlet />
                <TermsAndConditions
                  isConfirmed={termsAndConditions || isNotReady}
                  setIsConfirmed={setTermsAndConditions}
                />
                <TermsOfService
                  isConfirmed={!account?.id || isToSAgreed || isNotReady}
                  setIsConfirmed={setIsToSAgreed}
                />
                <EarlyBonus
                  isConfirmed={!account?.id || isEarlyBonusRead || isNotReady}
                  setIsConfirmed={setEarlyBonusRead}
                />
              </div>
            </div>
          </Fragment>
        </main>
      </div>
      <Toaster
        position="top-right"
        toastOptions={{ duration: 2000 }}
        containerClassName="font-['Chakra_Petch']"
      />
    </SessionProvider>
  );
};

export default Layout;
