import {
  Header,
  InformationIcon,
  Leaderboard,
  Skeleton,
  Tooltip,
  ValidatorActiveIcon,
  ValidatorAddressIcon,
  ValidatorInActiveIcon,
} from 'components';
import { useMemo } from 'react';
import { usePageFilter, useWindowSize } from 'hooks';
import { ValidatorLeaderboardCard } from './ValidatorsLeaderboardCard';
import moment from 'moment';
import { useApi } from 'context';
import { classes, truncate } from 'utils';
import { encodeAddress } from '@polkadot/util-crypto';
import { SocialConnectBanner } from 'components/SocialConnectBanner';
import { useValidatorLeaderBoardQuery } from 'gql';

const transformToNumber = (td: string) => Number(td).toFixed(2);

export function ValidatorLeaderboard() {
  const { isMobile } = useWindowSize();
  const { account } = useApi();
  const { data, loading } = useValidatorLeaderBoardQuery();

  const encodedAddress = useMemo(() => {
    if (account?.walletAddress) return encodeAddress(account.walletAddress, 12850);
  }, [account?.walletAddress]);

  const { controller, currentPage } = usePageFilter(
    data?.validatorLeaderBoard,
    10,
    ['10', '20', '30', '100'],
    {
      base: 'mt-0',
    }
  );

  const userRank = useMemo(() => {
    return data?.validatorLeaderBoard?.find((el) => el?.address === encodedAddress);
  }, [data, encodedAddress]);

  const [isUserInCurrentPage, dataWithUserRank] = useMemo(() => {
    if (!currentPage.length) {
      return [false, []];
    }
    const userPos = [...currentPage].find((row) => row?.rank == userRank?.rank);
    if (userPos) {
      return [true, [...currentPage]];
    } else {
      return [false, userRank ? [...currentPage, userRank] : currentPage];
    }
  }, [currentPage, userRank]);

  return (
    <Skeleton.Provider isLoading={loading}>
      <div className="flex flex-col md:py-[60px] py-9">
        <SocialConnectBanner className="md:mb-[40px] mb-[32px]" />
        <div className="flex lg:flex-row md:flex-row flex-col justify-between lg:mb-7 mb-6 gap-3">
          <div className="flex flex-col">
            <Header>Validator Leaderboard</Header>
            <div className="text-gray text-sm leading-[normal] md:mt-2 mt-1">
              <Skeleton.Loader className="w-[447px] h-5">
                See the all-time Leaderboard of all the validators, ranked according to uptime
                performance.
              </Skeleton.Loader>
            </div>
          </div>
        </div>
        {isMobile ? (
          <ValidatorLeaderboardCard
            data={dataWithUserRank || []}
            isLoading={loading}
            userEncodedAddress={encodedAddress}
          />
        ) : (
          <Leaderboard
            columns={[
              {
                title: 'Rank',
                key: 'rank',
                render(data) {
                  return (
                    <div
                      className={classes(
                        'flex gap-2 items-center',
                        data.address && data.address === encodedAddress && 'activeRowChild'
                      )}
                    >
                      <Skeleton.Loader className="w-[30px] h-5">
                        <div className="text-sm pr-4">{data[data.columnKey]}</div>
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'Name',
                key: 'name',
                render(data) {
                  return (
                    <div className="flex gap-2 items-center">
                      <Skeleton.Loader className="w-[90px] h-5">
                        <div className="text-sm truncate pr-4 ">
                          {data[data.columnKey] || 'Analog User'}
                        </div>
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'ADDRESS',
                key: 'address',
                render(data) {
                  return (
                    <div className="flex gap-2 items-center">
                      <Skeleton.Loader className="w-[120px] h-5">
                        <div className="bg-[#ffffff1a] min-w-7 h-7 w-7 rounded-full flex justify-center items-center">
                          <ValidatorAddressIcon className="h-4 w-4" />
                        </div>
                        <div className="text-sm truncate pr-4 ">
                          {truncate(data[data.columnKey])}
                        </div>
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'DISCOVERED',
                key: 'discovered',
                render(data) {
                  return (
                    <div className="text-sm">
                      <Skeleton.Loader className="w-[70px] h-5">
                        {moment(Number(data[data.columnKey])).fromNow()}
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'STATUS',
                key: 'status',
                render(data) {
                  return (
                    <div className="flex items-center gap-1">
                      <Skeleton.Loader className="w-[60px] h-5">
                        {data[data.columnKey] === 'Active' ? (
                          <ValidatorActiveIcon />
                        ) : (
                          <ValidatorInActiveIcon />
                        )}
                        <div className="text-sm">{data[data.columnKey]}</div>
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'VALID',
                key: 'valid',
                render(data) {
                  return (
                    <div
                      className={classes(
                        'flex items-center gap-1',
                        !data[data.columnKey] ? 'text-red-500' : ''
                      )}
                    >
                      <Skeleton.Loader className="w-[60px] h-5">
                        {
                          <div
                            {...(!data[data.columnKey] && {
                              'data-tooltip-id': `invalid-tooltip-${data.rowIndex}`,
                            })}
                            className="relative flex items-center gap-1 text-sm cursor-default"
                          >
                            <Skeleton.Loader className="w-7">
                              {!data[data.columnKey] && <InformationIcon stroke="#ef4444" />}
                              {data[data.columnKey] ? 'Valid' : 'Invalid'}
                              <Tooltip
                                id={`invalid-tooltip-${data.rowIndex}`}
                                content={data.inValidReason}
                                delay={0}
                                classNames="max-w-[90vw] break-words !bg-[#232224] !border-none"
                                classNameArrow="!border-none"
                                rest={{ arrowColor: '#232224' }}
                              />
                            </Skeleton.Loader>
                          </div>
                        }
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'FAULTS',
                key: 'faults',
                render(data) {
                  return (
                    <div
                      className={classes(
                        'text-sm',
                        Number(data[data.columnKey]) > 0 ? 'text-red-600' : ''
                      )}
                    >
                      <Skeleton.Loader className="w-[40px] h-5">
                        {data[data.columnKey]}
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'INCLUSION',
                key: 'inclusion',
                render(data) {
                  return (
                    <div className="text-sm">
                      <Skeleton.Loader className="w-[60px] h-5">
                        {transformToNumber(data[data.columnKey])}
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'SCORE',
                key: 'score',
                render(data) {
                  return (
                    <div className="text-sm">
                      <Skeleton.Loader className="w-[70px] h-5">
                        {transformToNumber(data[data.columnKey])}
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'COMMISSION',
                key: 'commission',
                render(data) {
                  return (
                    <div className="text-sm">
                      <Skeleton.Loader className="w-[50px] h-5">
                        {transformToNumber(data[data.columnKey])} %
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                title: 'REWARDS',
                key: 'rewards',
                render(data) {
                  return (
                    <div className="text-sm">
                      <Skeleton.Loader className="w-[100px] h-5">
                        {transformToNumber(data[data.columnKey])} TANLOG
                      </Skeleton.Loader>
                    </div>
                  );
                },
              },
              {
                key: 'showYou',
                render(data) {
                  return data.address === encodedAddress ? (
                    <Skeleton.Loader className="w-[50px] h-5">
                      <div className="flex justify-center items-center text-xs h-6 w-12 rounded-[28px] bg-[linear-gradient(178deg,_var(--tw-gradient-stops))] from-[#d274f780_16.37%] to-[#9a74f780_50.02%]">
                        YOU
                      </div>
                    </Skeleton.Loader>
                  ) : null;
                },
              },
            ]}
            data={dataWithUserRank || []}
            classNames={{
              parentContainer:
                'overflow-auto w-full scrollbar-white border-solid !border-[0.5px] border-steel-gray rounded-t-[10px]',
              row: 'grid grid-cols-[minmax(50px,5%)_minmax(120px,15%)_minmax(150px,18%)_minmax(100px,9%)_minmax(80px,7%)_minmax(100px,8%)_minmax(70px,5%)_minmax(80px,6%)_minmax(100px,6%)_minmax(80px,7%)_minmax(120px,8%)_minmax(50px,5%)] activeParent',
              base: classes(
                'overflow-visible border-none',
                !dataWithUserRank?.length && '!border-b !rounded-b-[10px]'
              ),
              colWrapper: 'overflow-visible',
              headingBase: 'pt-3 pb-[10px]',
            }}
            isLoading={loading}
          />
        )}
        <div
          className={classes(
            isMobile
              ? 'mt-2'
              : 'px-6 py-1 border border-solid border-t-0  border-steel-gray rounded-b-[10px]'
          )}
        >
          {controller}
        </div>
      </div>
    </Skeleton.Provider>
  );
}
