import { ReactNode } from 'react';
import { Skeleton } from 'components';
import { classes } from 'utils';

export const HomeOverview = ({
  sections = [],
  earlyBonus,
  classname,
}: {
  sections: { icon: ReactNode; data: string | number; label: string }[];
  earlyBonus?: number | null;
  classname?: string;
}) => {
  return (
    <div>
      <div className="text-white text-xl font-normal font-['Chakra Petch'] uppercase leading-[32px] mb-4">
        <Skeleton.Loader className="w-[150px] h-10">Overview</Skeleton.Loader>
      </div>
      <div
        className={classes(
          ' flex flex-col relative  rounded-[10px] bg-black border-[0.5px] border-zinc-700 overflow-hidden',
          classname
        )}
      >
        <div className="flex flex-col lg:px-[24px] md:p-0 p-[10px_16px] relative [background:linear-gradient(101deg,_#ffffff1c_0%,_#ffffff03_33.71%),_#030303]">
          <div className="w-full h-full bg-[url(../components/icons/OverviewBg.svg)] opacity-[0.08] bg-cover bg-no-repeat absolute top-0 left-0 "></div>
          <div className={classes('flex-1 lg:border-r-0 border-b border-steel-gray')}>
            <div
              className={classes('flex items-start justify-between gap-2 lg:p-[20px_0px] p-[10px]')}
            >
              <div className="flex flex-col lg:gap-1 gap-0 text-white">
                <div className={classes('flex gap-2 h-8 items-center ')}>
                  <Skeleton.Loader className="w-[25px] h-[25px] !rounded-md">
                    {sections[0]?.icon}
                  </Skeleton.Loader>
                  <div className="flex items-end lg:gap-2 gap-[6px] bg-[linear-gradient(140deg,_#D274F7_16.37%,_#9A74F7_50.02%)] text-transparent [-webkit-background-clip: text] [-webkit-text-fill-color: transparent] bg-clip-text text-lg">
                    <div
                      className={classes(
                        'lg:text-[32px] text-2xl lg:leading-[27px] leading-none font-text-bold'
                      )}
                    >
                      <Skeleton.Loader className="max-w-[100px] min-w-[50px]">
                        {sections[0]?.data}
                      </Skeleton.Loader>
                    </div>
                    <span
                      className={classes(
                        "lg:text-lg text-base lg:leading-none leading-none lg:font-['Chakra_Petch'] font-text-bold"
                      )}
                    >
                      <Skeleton.Loader className="w-[20px] h-[20px] !rounded-md">
                        ATP
                      </Skeleton.Loader>
                    </span>
                  </div>
                </div>
                <div className={classes('md:ml-[32px] text-gray lg:text-sm text-xs')}>
                  <Skeleton.Loader className="max-w-[150px]">{sections[0]?.label}</Skeleton.Loader>
                </div>
              </div>
              {earlyBonus && earlyBonus > 0 && (
                <div className="flex items-center justify-center text-center bg-opacity-10 rounded-[46px] text-green-500 bg-green-500 border-green-500  backdrop-blur-[14px] text-sm font-medium font-['Chakra Petch'] px-3 py-2.5">
                  + {earlyBonus} ATP EARLY TESTER BONUS!
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row ">
            {sections.map((section, index) => {
              if (index === 0) return;

              return (
                index !== 0 && (
                  <div
                    key={`home-overview-${index}`}
                    className={classes(
                      'flex-1',
                      index < sections.length - 1 &&
                        'lg:border-r md:border-r lg:border-b-0  border-b border-steel-gray lg:px-0 px-3'
                    )}
                  >
                    <div
                      className={classes(
                        'flex items-start gap-2 lg:p-[20px_24px] p-[10px_10px]',
                        index === 1 && 'lg:pl-0',
                        index < sections.length - 1 && 'p-[10px_0_10px_0]'
                      )}
                    >
                      <div className="flex flex-col lg:gap-1 gap-0 text-white">
                        <div className="flex gap-2 h-8 items-center">
                          <Skeleton.Loader className="w-[25px] h-[25px] !rounded-md">
                            {section?.icon}
                          </Skeleton.Loader>
                          <div className="flex items-end lg:gap-2 gap-[6px]">
                            <div className="lg:text-2xl text-xl lg:leading-[22px] leading-none font-text-bold">
                              <Skeleton.Loader className="max-w-[100px] min-w-[50px]">
                                {section?.data}
                              </Skeleton.Loader>
                            </div>
                            <span className="text-base lg:leading-none leading-none lg:font-['Chakra_Petch'] font-text-bold">
                              <Skeleton.Loader className="w-[20px] h-[20px] !rounded-md">
                                ATP
                              </Skeleton.Loader>
                            </span>
                          </div>
                        </div>
                        <div className="md:ml-[32px] text-gray lg:text-sm text-xs">
                          <Skeleton.Loader className="max-w-[150px]">
                            {section?.label}
                          </Skeleton.Loader>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
