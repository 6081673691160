import { BreadCrumbs, Header, MagnifyGlassIcon, Skeleton } from 'components';
import { useSessions } from 'context';
import { useActiveVotingSessionViewsQuery } from 'gql';
import { useCookies } from 'react-cookie';
import ViewCard from './ViewCard';
import { useEffect, useMemo, useState } from 'react';

export function AllViews() {
  const { activeSession } = useSessions();
  const [{ accessToken }] = useCookies(['accessToken']);

  const {
    data: activeSessionViews,
    loading: isActiveSessionViewsLoading,
    refetch: refetchActiveSessionViews,
  } = useActiveVotingSessionViewsQuery({ variables: { accessToken }, skip: !accessToken });

  const [search, setSearch] = useState('');
  const viewsData = useMemo(() => {
    const trimSearch = search.trim();
    if (!trimSearch.length) return activeSessionViews?.activeVotingSessionViews;
    return activeSessionViews?.activeVotingSessionViews.filter((el) => {
      if (
        el.name.toLowerCase().includes(trimSearch.toLowerCase()) ||
        el.hashId.includes(trimSearch) ||
        el.owner.walletAddress?.includes(trimSearch)
      ) {
        return true;
      }
      return false;
    });
  }, [search, activeSessionViews]);

  useEffect(() => {
    if (!activeSessionViews?.activeVotingSessionViews.length) {
      refetchActiveSessionViews();
    }
  }, []);

  return (
    <Skeleton.Provider isLoading={isActiveSessionViewsLoading}>
      <div className="md:pt-[55px] pt-[36px] xl:pb-[0] pb-3">
        <div className="flex justify-between">
          <BreadCrumbs
            pages={[
              { page: 'Game' },
              { page: 'Session', label: activeSession?.name },
              { page: 'AllViews' },
            ]}
          />
        </div>
        <div className="flex flex-col gap-6">
          <div className="flex md:flex-row flex-col justify-between md:items-center md:gap-0 gap-4">
            <Header classNames={{ header: 'md:text-2xl text-xl' }}>Views</Header>
            <Skeleton.Loader className="h-[42px] w-72">
              <div className="flex md:gap-6 gap-4">
                <div className="bg-[#000] border border-solid border-[#393939] flex p-[10px] gap-[6px] md:max-w-[300px] md:w-[35vw] w-full shadow-primary-shadow rounded-lg">
                  <MagnifyGlassIcon />
                  <input
                    className="text-white text-sm w-full placeholder-gray"
                    placeholder="Search..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              </div>
            </Skeleton.Loader>
          </div>
          {(isActiveSessionViewsLoading ? new Array(5).fill({}) : viewsData)?.map((view, index) => (
            <ViewCard key={index} view={view} />
          ))}
        </div>
      </div>
    </Skeleton.Provider>
  );
}
