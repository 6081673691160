import { Link } from 'react-router-dom';
import { HTMLAttributes } from 'types';
import { classes } from 'utils';

interface Props extends HTMLAttributes<HTMLAnchorElement> {
  link: string;
  useLink?: boolean;
}

export function CustomLink({ link, useLink, children, ...props }: Props) {
  return useLink ? (
    <Link
      to={link}
      rel="noreferrer"
      {...props}
      className={classes(
        'bg-primary-gradient bg-clip-text text-transparent cursor-pointer',
        props.className
      )}
    >
      {children}
    </Link>
  ) : (
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      {...props}
      className={classes(
        'bg-primary-gradient bg-clip-text text-transparent cursor-pointer',
        props.className
      )}
    >
      {children}
    </a>
  );
}
