import { useCallback, useState } from 'react';
import { classes } from 'utils';
import { GradientMinusIcon, GradientPlusIcon } from '../../components/icons';
import { Card } from '../../components/Card';
import { HTMLAttributes } from 'types';

const Accordian = ({
  items,
  onTitleClick
}: {
  items: Array<{
    title: string;
    description: string | JSX.Element;
    icon: (props: HTMLAttributes<HTMLOrSVGElement>) => JSX.Element;
  }>;
  onTitleClick: (title:string) => void
}) => {
  const [isExpandedIndex, setIsExpandedIndex] = useState<number | null>(null);

  const toggleAccordian = useCallback((index: number) => {
    setIsExpandedIndex((prev) => {
      if (prev !== null && prev === index) {
        return null;
      } else return index;
    });
  }, []);

  return (
    <div className="flex flex-col gap-3">
      {items?.map(({ title, description, icon: Icon }, index: number) => {
        return (
          <Card
            key={`faq-item-${index}`}
            className="bg-[#030303] shadow-[0px_0px_80px_0px_rgba(255,_255,_255,_0.08)_inset] border-dark-gray"
          >
            <div key={index}>
              <button
                type="button"
                className="flex items-center justify-between w-full p-4"
                onClick={() => {
                  toggleAccordian(index);
                   onTitleClick && onTitleClick(title);
                  }
                }
              >
                <div className={classes('flex items-center gap-3')}>
                  <div className="flex items-center justify-center h-9 min-w-9 bg-[#ffffff0d] rounded-md mb-auto">
                    <Icon className="w-[18px] h-[18px]" />
                  </div>
                  <div className="flex flex-col transition ease-in-out delay-150">
                    <span
                      className={classes(
                        'text-white text-base break-words min-h-9 flex items-center'
                      )}
                    >
                      {title}
                    </span>
                    <div
                      className={classes(
                        'grid text-sm text-white overflow-hidden transition-all ease-linear h-0',
                        isExpandedIndex === index
                          ? 'grid-rows-[1fr] opacity-100 h-full break-words'
                          : 'grid-rows-[0fr] opacity-0'
                      )}
                    >
                      <div className="overflow-hidden md:mt-0 mt-1">
                        <div className="text-sm text-gray whitespace-pre-line">{description}</div>
                      </div>
                    </div>
                  </div>
                </div>
                {isExpandedIndex === index ? (
                  <GradientMinusIcon
                    className={classes(
                      'min-w-6 min-h-6 transform origin-center transition-all duration-200 ease-out mb-auto'
                    )}
                  />
                ) : (
                  <GradientPlusIcon
                    className={classes(
                      'min-w-6 min-h-6 transform origin-center transition-all duration-200 ease-out '
                    )}
                  />
                )}
              </button>
            </div>
          </Card>
        );
      })}
    </div>
  );
};

export default Accordian;
