import React, { useCallback, useEffect, useMemo } from 'react';
import { Tab } from '@headlessui/react';
import { Header, Table, Definition, Sql, Skeleton } from 'components';
import { usePageFilter, useTabs } from 'hooks';
import { format } from 'sql-formatter';
import { countLines, getUsecasesIcon } from 'utils';
import { Views, useViewDataLazyQuery, useViewSqlLazyQuery } from 'gql';
import { Grid, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ChevronRightIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { useCookies } from 'react-cookie';
import 'swiper/css/grid';

export function ViewDetails({ view, isLoading }: { view: Views; isLoading: boolean }) {
  const [{ accessToken }] = useCookies(['accessToken']);
  const [fetchViewData, { data: viewSampleData, loading: sampleDataLoading }] =
    useViewDataLazyQuery({
      variables: { accessToken, hashId: view.hashId },
    });
  const [fetchViewSql, { data: viewSql, loading: viewSqlLoading }] = useViewSqlLazyQuery({
    variables: { accessToken, hashId: view.hashId },
  });

  const sql = useMemo(() => format(viewSql?.viewSql || ''), [viewSql?.viewSql]);

  const viewColumns = useMemo(() => {
    return viewSampleData?.viewData?.header?.map((colName: string) => {
      return {
        title: colName,
        key: colName,
        render(data: any) {
          return (
            <div className="text-white text-xs font-normal leading-[35px] line-clamp-1 break-all">
              {data[data.colIndex]}
            </div>
          );
        },
      };
    });
  }, [viewSampleData?.viewData]);

  const { controller, currentPage } = usePageFilter(
    viewSampleData?.viewData?.data,
    6,
    ['6', '10'],
    {
      base: 'px-4 border-t border-solid border-steel-gray mt-3',
      sizeControl: 'text-xs text-neutral-400',
      btnContainer: 'text-neutral-400 text-xs',
    }
  );

  const getTabHeader = useCallback((tab: number) => {
    switch (tab) {
      case 0:
        return 'Use Cases';
      case 1:
        return 'Sample Data ';
      case 2:
        return 'Definition';
    }
  }, []);

  const { tabPanels, tabSelector, selectedTab, setSelectedTab } = useTabs(
    [
      {
        child:
          view.useCases?.length || isLoading ? (
            <div className=" mt-4 md:h-[227px]">
              <div className="block  gap-4 inner-slider-wrap">
                <div className=" gap-4 relative">
                  <Swiper
                    navigation={{
                      nextEl: '.next-arrow',
                      prevEl: '.prev-arrow',
                      lockClass: 'unlock',
                    }}
                    spaceBetween={10}
                    slidesPerView={2}
                    grid={{
                      rows: 2,
                      fill: 'row',
                    }}
                    modules={[Grid, Navigation]}
                    breakpoints={{
                      991: { slidesPerView: 3, grid: { rows: 3, fill: 'row' } },
                    }}
                  >
                    {(isLoading ? new Array(9).fill('') : view.useCases).map(
                      (name: string, index: number) => {
                        if (!name.trim().length && !isLoading) {
                          return;
                        }
                        return (
                          <SwiperSlide
                            className=" bg-black p-3 rounded-lg border !h-[69px] border-zinc-700 items-center justify-start flex-row gap-3 shadow-[0px_0px_120.6px_0px_rgba(255,_255,_255,_0.08)_inset] bg-[url('/background.svg')] bg-[5%_25%] !flex"
                            key={index}
                          >
                            <Skeleton.Loader className="md:h-[38px] md:w-[42px] h-[42px] w-[42px]">
                              <div className="p-[8px] w-[42px] h-[42px] bg-[#1C1C1C] rounded-md">
                                {getUsecasesIcon(name, 'md:h-[26px] md:w-[26px] w-4 h-4')}
                              </div>
                            </Skeleton.Loader>
                            <Skeleton.Loader className="h-4 w-20">
                              <div className="text-white md:text-xs text-ellipsis text-xs font-normal w-full leading-snug text-left md:line-clamp-2 line-clamp-2 max-w-full ">
                                {name}
                              </div>
                            </Skeleton.Loader>
                          </SwiperSlide>
                        );
                      }
                    )}
                    <div className="cursor-pointer next-arrow border border-[#49474A] bg-[#1c1c1c] md:h-[30px] md:w-[30px] h-[25px] w-[25px] rounded-full absolute top-2/4 right-0 z-50  flex items-center justify-center -translate-y-2/4">
                      <ChevronRightIcon className="md:h-[16px] md:w-[16px] h-[13px] w-[13px]" />
                    </div>
                    <div className="cursor-pointer prev-arrow border border-[#49474A] bg-[#1c1c1c] md:h-[30px] md:w-[30px] h-[25px] w-[25px]  rounded-full absolute top-2/4 left-0 z-50 flex items-center justify-center -translate-y-2/4">
                      <ChevronRightIcon className="md:h-[16px] md:w-[16px] h-[13px] w-[13px] rotate-180" />
                    </div>
                  </Swiper>
                </div>
              </div>
            </div>
          ) : (
            <Skeleton.Loader className="md:min-h-[227px] min-h-[145px] mt-4">
              <div className="flex justify-center items-center md:h-[227px] mt-4 gap-1">
                <ExclamationCircleIcon className="h-6 w-6" />
                <span className="text-base">Data not available</span>
              </div>
            </Skeleton.Loader>
          ),
        label: 'Use Cases',
        value: 'useCases',
      },
      {
        child: currentPage.length ? (
          <Skeleton.Loader className="md:min-h-[227px] min-h-[145px] mt-4">
            <div className="md:h-[227px] mt-4 swiper-no-mousewheel">
              <div className="border-solid !border-[0.5px] border-steel-gray rounded-[10px]">
                <Table
                  columns={viewColumns}
                  data={currentPage}
                  showMobileCards={false}
                  classNames={{
                    base: 'mt-0 mx-4 mt-[7px] rounded-[10px_10px_0_0] scrollbar-white min-w-[calc(100%-32px)] w-[calc(100%-32px)] md:h-[166px] lg:h-[166px] h-[130px]',
                    row: `grid border-0 p-0 `,
                    bodyWrapper: 'tableDataWrap',
                    headingContainer: 'pt-1 pb-2 !border-b-small !border-[#202020] border-solid ',
                    contentContainer: '!border-[#202020]',
                  }}
                  rowStyle={{
                    gridTemplateColumns: `${viewColumns
                      ?.map(() =>
                        viewColumns.length > 3 ? 'minmax(150px,25%)' : 'minmax(150px,33.33%)'
                      )
                      .join(' ')}`,
                  }}
                />
                {controller}
              </div>
            </div>
          </Skeleton.Loader>
        ) : (
          <Skeleton.Loader className="md:min-h-[227px] min-h-[145px] mt-4">
            <div className="flex justify-center items-center md:h-[227px] mt-4 gap-1">
              <ExclamationCircleIcon className="h-6 w-6" />
              <span className="text-base">Data not available</span>
            </div>
          </Skeleton.Loader>
        ),
        label: 'Sample Data ',
        value: 'sampleData',
      },
      {
        child: sql ? (
          <Skeleton.Loader className="md:min-h-[227px] min-h-[145px] mt-4">
            <div className="md:h-[227px] mt-4 swiper-no-mousewheel">
              <Definition
                lineCount={countLines(sql)}
                copyString={sql}
                className="mt-0"
                copyKey={`definition-${view.hashId}`}
              >
                <Sql
                  className="bg-black pt-0 md:max-h-[180px] lg:max-h-[184px] max-h-[140px] overflow-y-scroll scrollbar-white"
                  options={{ readOnly: true }}
                  value={sql}
                />
              </Definition>
            </div>
          </Skeleton.Loader>
        ) : (
          <Skeleton.Loader className="md:min-h-[227px] min-h-[145px] mt-4">
            <div className="flex justify-center items-center md:h-[227px] mt-4 gap-1">
              <ExclamationCircleIcon className="h-6 w-6" />
              <span className="text-base">Data not available</span>
            </div>
          </Skeleton.Loader>
        ),
        label: 'Definition',
        value: 'definition',
      },
    ],
    0,
    {
      classNames: {
        base: 'lg:max-w-80 max-w-full w-full [&>*:nth-child(1)]:sm:px-2 [&>*:nth-child(1)]:px-1 [&>*:nth-child(2)]:sm:px-2 [&>*:nth-child(2)]:px-1 [&>*:nth-child(3)]:sm:px-2 [&>*:nth-child(3)]:px-1',
      },
    }
  );

  useEffect(() => {
    if (selectedTab === 1) {
      fetchViewData();
    } else if (selectedTab === 2) {
      fetchViewSql();
    }
  }, [selectedTab]);

  return (
    <div className="flex flex-col mt-3 overflow-hidden">
      <Tab.Group selectedIndex={selectedTab} onChange={setSelectedTab}>
        <Header
          accessory={tabSelector}
          classNames={{
            header: 'whitespace-pre',
            base: 'flex md:flex-row flex-col-reverse md:items-center items-start justify-between mb-0 gap-[20px]',
            accessory: 'm-0 lg:!w-auto !w-full',
          }}
        >
          {getTabHeader(selectedTab)}
        </Header>
        <Skeleton.Provider isLoading={sampleDataLoading || viewSqlLoading || isLoading}>
          {tabPanels}
        </Skeleton.Provider>
      </Tab.Group>
    </div>
  );
}
