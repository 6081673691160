import { Button, ExclamationMark, Skeleton } from 'components';
import { useApi } from 'context';
import { useMemo } from 'react';
import { classes } from 'utils';

const SignInWithDiscord = () => {
  window.location.href = `${import.meta.env.VITE_BACKEND_ENDPOINT}auth/verifyDiscordGuild`;
};

export const SocialConnectBanner = ({ className }: { className?: string }) => {
  const { account } = useApi();
  const showBanner = useMemo(() => {
    if (account && !account.connectedDiscordServer) {
      return true;
    }
    return false;
  }, [account]);

  if (!showBanner) {
    return null;
  }

  return (
    <>
      <div
        className={classes(
          'flex md:flex-row flex-col md:justify-between md:items-center md:gap-4 gap-[14px] w-full py-5 px-6 rounded-lg border border-solid border-[#fc3b46cc] bg-[linear-gradient(0deg,_var(--tw-gradient-stops))] from-[#fc3b460f_0%] to-[#fc3b460f_100%] backdrop-blur-sm',
          className
        )}
      >
        <div className="flex gap-4">
          <div className="flex justify-center items-center md:p-3 p-[10px] rounded-md bg-[#ffffff12] md:max-h-12 max-h-10 md:max-w-12 max-w-10">
            <Skeleton.Loader className="h-6 w-6">
              <ExclamationMark stroke="#FC3B46" className="h-6 w-6" />
            </Skeleton.Loader>
          </div>
          <div className="flex flex-col md:justify-between md:gap-0 gap-1">
            <Skeleton.Loader className="h-4 md:w-[25vw] w-[150px]">
              <div className="md:text-lg text-[16px] text-white">
                Please connect your Discord to verify your account.
              </div>
            </Skeleton.Loader>
            <Skeleton.Loader className="h-4 md:w-[30vw] sm:w-[200px] w-[150px]">
              <div className="md:text-base text-sm leading-5 text-gray">
                To complete this process, pass the server captcha to get the Timekeeper role.
                Unverified accounts will be ineligible for rewards and may be disabled. To restore
                your account, you must complete this step.
              </div>
            </Skeleton.Loader>
          </div>
        </div>
        <Skeleton.Loader className="h-10 md:[70px] md:w-[100px] w-full">
          <Button
            variant="light"
            classNames={{
              base: 'md:h-10 h-9 w-[94px] md:w-fit w-full',
              container: 'text-xs md:text-sm',
            }}
            onClick={SignInWithDiscord}
          >
            Connect
          </Button>
        </Skeleton.Loader>
      </div>
    </>
  );
};
