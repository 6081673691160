import { classes } from 'utils';
import { ClassNames } from 'types';
import { Button } from 'components';

type Props = {
  classNames?: ClassNames<'page' | 'btn' | 'btnActive' | 'result' | 'btnContainer'>;
  isPreviousAble?: boolean;
  isNextAble?: boolean;
  onPrevious: () => void;
  onNext: () => void;
  pageCount: number;
  pageIndex: number;
  setPageIndex: React.Dispatch<number>;
};

export function PageControl({ onPrevious, onNext, pageCount, pageIndex, classNames }: Props) {
  return (
    <div
      className={classes(
        'flex items-baseline sm:space-x-4  whitespace-pre text-[#B2B3B8] flex-wrap sm:gap-0 gap-2',
        classNames?.base
      )}
    >
      <div className="flex md:gap-2 gap-[6px] items-center ">
        {onPrevious && (
          <Button
            variant="plain"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={pageIndex > 0 ? onPrevious : () => {}}
            isDisabled={pageIndex <= 0}
            classNames={{
              container: classes(
                'bg-primary-gradient bg-clip-text !text-transparent text-xs',
                pageIndex <= 0 && 'bg-[unset] !text-gray',
                classNames?.btnContainer
              ),
            }}
          >
            Previous
          </Button>
        )}

        {pageIndex > 0 && (
          <Button
            variant="plain"
            classNames={{
              base: 'md:h-6 h-5 md:w-6 w-5 w-unset p-[2px]',
              container: 'md:text-sm text-xs',
            }}
            onClick={onPrevious}
          >
            {pageIndex}
          </Button>
        )}

        <div className="md:h-6 h-5 md:w-6 w-5 rounded-lg flex items-center justify-center bg-[#ffffff1a] md:text-sm text-xs">
          {pageIndex + 1}
        </div>

        {pageIndex + 1 < pageCount && (
          <Button
            variant="plain"
            classNames={{
              base: 'md:h-6 h-5 md:w-6 w-5 w-unset p-[2px]',
              container: 'md:text-sm text-xs',
            }}
            onClick={onNext}
          >
            {pageIndex + 2}
          </Button>
        )}

        {onNext && (
          <Button
            variant="plain"
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onClick={pageIndex + 1 < pageCount ? onNext : () => {}}
            isDisabled={pageIndex + 1 == pageCount || pageCount === 0}
            classNames={{
              container: classes(
                'bg-primary-gradient bg-clip-text !text-transparent text-xs',
                (pageIndex + 1 == pageCount || pageCount === 0) && 'bg-[unset] !text-gray',
                classNames?.btnContainer
              ),
            }}
          >
            Next
          </Button>
        )}
      </div>
    </div>
  );
}
