import React, { ReactNode } from 'react';
import { Modal } from './Modal';
import { ClassNames, HTMLAttributes, Setter } from 'types';
import { classes } from 'utils';
import { CrossIcon } from 'components';

interface Props extends HTMLAttributes<HTMLDivElement> {
  isOpen: boolean;
  setIsOpen?: Setter<boolean>;
  title?: string;
  headerLeftText?: string | ReactNode;
  classNames?: ClassNames<'childrenContainer'>;
}

export function BottomModal({
  isOpen,
  setIsOpen,
  children,
  title,
  headerLeftText,
  classNames,
}: Props) {
  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      classNames={{
        modalBase: 'items-end pb-0 px-0',
        base: classes(
          'w-full max-w-full rounded-[24px_24px_0_0] border-b-[0] p-0',
          classNames?.base
        ),
      }}
      isCloseHidden
    >
      <div
        className={classes(
          'flex items-center justify-between w-full gap-[6px] border-0 border-b border-solid border-[#141414] p-4',
          !title && 'justify-end'
        )}
      >
        {headerLeftText && (
          <div className="bg-primary-gradient text-transparent bg-clip-text">{headerLeftText}</div>
        )}
        {title && <div className="flex-1 text-center">{title}</div>}
        <button onClick={() => setIsOpen && setIsOpen(false)}>
          <CrossIcon />
        </button>
      </div>
      <div className={classes('p-4 w-full', classNames?.childrenContainer)}>{children}</div>
    </Modal>
  );
}
