import {
  InformationIcon,
  Skeleton,
  Tooltip,
  ValidatorActiveIcon,
  ValidatorAddressIcon,
  ValidatorInActiveIcon,
} from 'components';
import { classes } from 'utils';
import moment from 'moment';

interface Props {
  data: any[];
  isLoading?: boolean;
  userEncodedAddress?: string;
}

const transformToNumber = (td: string) => Number(td).toFixed(2);

export function ValidatorLeaderboardCard({ data, isLoading, userEncodedAddress }: Props) {
  return (
    <>
      <div className="flex flex-col gap-4">
        {data.length ? (
          data.map((row, index) => {
            return (
              <div
                className={classes(
                  'text-white rounded-[10px] border-solid border border-dark-gray bg-black p-[16px_14px_12px_16px]',
                  index === 0 &&
                    'md:[background:linear-gradient(140deg,_#d274f71a_16.37%,_#9a74f71a_50.02%),_#000] [background:linear-gradient(140deg,_#d274f71a_16.37%,_#9a74f71a_50.02%)#000] border-t-light-purple',
                  index === 1 &&
                    'md:[background:linear-gradient(140deg,_#d274f712_16.37%,_#9a74f712_50.02%),_#000] [background:linear-gradient(140deg,_#d274f712_16.37%,_#9a74f712_50.02%)#000] border-t-[#D274F780]',
                  index === 2 &&
                    'md:[background:linear-gradient(140deg,_#d274f70d_16.37%,_#9a74f70d_50.02%),_#000] [background:linear-gradient(140deg,_#d274f70d_16.37%,_#9a74f70d_50.02%)#000] border-t-[#D274F749]',
                  userEncodedAddress === row.address && 'showHighlighted'
                )}
                key={index}
              >
                <div className="flex flex-row justify-between pb-3 border-b border-0 border-dark-gray">
                  <div className="flex flex-row gap-[6px] items-center w-full">
                    <Skeleton.Loader className="h-8 w-8 !rounded-full">
                      <div className="bg-[#ffffff1a] h-7 w-7  rounded-full flex justify-center items-center">
                        <ValidatorAddressIcon className="h-4 w-4" />
                      </div>
                    </Skeleton.Loader>
                    <div className="flex flex-col w-[80%]">
                      <div className="text-sm truncate w-[90%] capitalize">
                        <Skeleton.Loader className="w-[200px] h-5">{row.address}</Skeleton.Loader>
                      </div>
                    </div>
                  </div>
                  {userEncodedAddress === row.address && (
                    <Skeleton.Loader className="w-[30px] h-7">
                      {' '}
                      <div className="flex justify-center items-center text-xs h-6 w-12 rounded-[28px] bg-[linear-gradient(178deg,_var(--tw-gradient-stops))] from-[#d274f780_16.37%] to-[#9a74f780_50.02%]">
                        YOU
                      </div>
                    </Skeleton.Loader>
                  )}
                </div>

                <div className="flex flex-row justify-between py-3 border-0 border-b border-solid border-dark-gray">
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-4">Rank:</Skeleton.Loader>
                    </span>{' '}
                    <Skeleton.Loader className="w-4 h-5"> {row.rank}</Skeleton.Loader>
                  </div>
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-5">Name:</Skeleton.Loader>
                    </span>
                    <Skeleton.Loader className="w-[120px] h-5"> {row.name}</Skeleton.Loader>
                  </div>
                </div>

                <div className="flex flex-row justify-between py-3 border-0 border-b border-solid border-dark-gray">
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-5">Discovered:</Skeleton.Loader>
                    </span>
                    <Skeleton.Loader className="w-[100px] h-5">
                      {moment(Number(row.discovered)).fromNow()}
                    </Skeleton.Loader>
                  </div>
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-5">Status:</Skeleton.Loader>
                    </span>{' '}
                    <Skeleton.Loader className="w-[60px] h-5">
                      {row.status === 'Active' ? (
                        <ValidatorActiveIcon />
                      ) : (
                        <ValidatorInActiveIcon />
                      )}{' '}
                      {row.status}
                    </Skeleton.Loader>
                  </div>
                </div>

                <div className="flex flex-row justify-between py-3 border-0 border-b border-solid border-dark-gray">
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[90px] h-5">Valid:</Skeleton.Loader>
                    </span>{' '}
                    <div
                      className={classes(
                        'flex items-center gap-1',
                        !row.valid ? 'text-red-500' : ''
                      )}
                    >
                      <Skeleton.Loader className="w-[100px] h-5">
                        {' '}
                        <div
                          {...(!row.valid && {
                            'data-tooltip-id': `invalid-tooltip-${index}`,
                          })}
                          className="relative flex items-center gap-1 text-sm cursor-default"
                        >
                          <Skeleton.Loader className="w-7">
                            {!row.valid && <InformationIcon stroke="#ef4444" />}
                            {row.valid ? 'Valid' : 'Invalid'}
                            <Tooltip
                              id={`invalid-tooltip-${index}`}
                              content={row.inValidReason}
                              delay={0}
                              classNames="max-w-[90vw] break-words !bg-[#232224] !border-none"
                              classNameArrow="!border-none"
                              rest={{ arrowColor: '#232224' }}
                            />
                          </Skeleton.Loader>
                        </div>
                      </Skeleton.Loader>
                    </div>
                  </div>
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-4">FAULTS</Skeleton.Loader>
                    </span>{' '}
                    <Skeleton.Loader className="w-4 h-5"> {row.faults}</Skeleton.Loader>
                  </div>
                </div>

                <div className="flex flex-row justify-between py-3 border-0 border-b border-solid border-dark-gray">
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-4">INCLUSION</Skeleton.Loader>
                    </span>{' '}
                    <Skeleton.Loader className="w-4 h-5"> {row.inclusion}</Skeleton.Loader>
                  </div>
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-5">Score:</Skeleton.Loader>
                    </span>
                    <Skeleton.Loader className="w-[60px] h-5">
                      {' '}
                      {transformToNumber(row.score)}
                    </Skeleton.Loader>
                  </div>
                </div>

                <div className="flex flex-row justify-between py-3 border-none">
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[90px] h-5">Commission (10%):</Skeleton.Loader>
                    </span>{' '}
                    <Skeleton.Loader className="w-[100px] h-5">
                      {' '}
                      {transformToNumber(row.commission)} TANLOG
                    </Skeleton.Loader>
                  </div>
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[40px] h-5">Rewards:</Skeleton.Loader>
                    </span>
                    <Skeleton.Loader className="w-[100px] h-5">
                      {transformToNumber(row.rewards)} TANLOG
                    </Skeleton.Loader>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-white flex justify-center md:text-lg text-base">
            {isLoading ? 'Loading...' : 'No Data Found'}
          </div>
        )}
      </div>
    </>
  );
}
