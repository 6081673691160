import { useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Button,
  CustomCalendarIcon,
  Card,
  EyeIcon,
  GraphIcon,
  Header,
  Modal,
  WaveImage,
  NoSession,
} from 'components';
import { classes, customToast } from 'utils';
import { useEndVotingSessionMutation, useUpdateVotingSessionMutation } from 'gql';
import { useSessions } from 'context';
import { CreateSessionModal } from './CreateSessionModal';
import { Setter, VoidFn } from 'types';
import { useCookies } from 'react-cookie';
import { useSessionCountdown } from 'hooks';

export enum ModalType {
  PAUSE = 'pause',
  EDIT = 'edit',
  CLOSE = 'close',
  CREATE = 'create',
}

const CountdownTimer = () => {
  const { countdown } = useSessionCountdown();
  return <div className="text-white text-2xl lg:text-3xl">{countdown}</div>;
};

const ConfirmationModal = ({
  isOpen,
  setIsOpen,
  message,
  confirmLoading,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: Setter<ModalType | undefined>;
  message: string;
  confirmLoading?: boolean;
  onConfirm: VoidFn;
}) => {
  const setModalState = useCallback((state: boolean) => {
    if (!state) setIsOpen(undefined);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setModalState as Setter<boolean>}
      classNames={{ content: 'gap-5 text-xl text-center' }}
    >
      {message}
      <div className="w-full flex justify-center gap-4 text-base">
        <Button onClick={() => setModalState(false)} classNames={{ container: 'text-base' }}>
          Cancel
        </Button>
        <Button
          variant="primary"
          isLoading={confirmLoading}
          onClick={onConfirm}
          classNames={{ container: 'text-base' }}
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};

const EditSessionModal = ({
  isOpen,
  setIsOpen,
  confirmLoading,
  sessionData,
  setSessionData,
  onConfirm,
}: {
  isOpen: boolean;
  setIsOpen: Setter<ModalType | undefined>;
  confirmLoading: boolean;
  sessionData: { name: string | undefined; endDate: string | undefined };
  setSessionData: Setter<{ name: string | undefined; endDate: string | undefined }>;
  onConfirm: VoidFn;
}) => {
  const setModalState = useCallback((state: boolean) => {
    if (!state) setIsOpen(undefined);
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      setIsOpen={setModalState as Setter<boolean>}
      classNames={{ content: 'items-start overflow-hidden', base: 'md:max-w-[unset] max-w-[90vw]' }}
    >
      <Header classNames={{ header: 'text-2xl capitalize' }}>Update active session</Header>
      <div className="py-4 w-full flex flex-col gap-2">
        <div className="flex justify-center items-center gap-2 mt-1 w-full">
          <div className="text-base min-w-[120px]">Session Name:</div>
          <input
            className="w-52 p-2 rounded-md border border-solid border-steel-gray flex-1 h-10"
            name="trip-start"
            autoComplete="off"
            value={sessionData?.name}
            onChange={(e) => {
              let name = e.target.value;
              if (name.length > 0 && name.charAt(0) !== name.charAt(0).toUpperCase()) {
                name = name.charAt(0).toUpperCase() + name.slice(1);
              }
              setSessionData((prev) => ({ ...prev, name: name }));
            }}
          />
        </div>
        <div className="flex justify-center items-center gap-2 mt-1 w-full">
          <div className="text-base min-w-[120px]">Session End Date:</div>
          <input
            className="flex-1 h-10"
            type="datetime-local"
            name="trip-start"
            min={moment(new Date()).format('YYYY-MM-DDTHH:mm')}
            value={sessionData?.endDate}
            onChange={(e) => {
              if (moment(new Date()).isBefore(moment(e.target.value)))
                setSessionData((prev) => ({ ...prev, endDate: e.target.value }));
            }}
          />
        </div>
      </div>
      <Button
        variant="primary"
        classNames={{ base: 'w-full h-[unset] !py-2', container: '!text-base' }}
        isDisabled={!sessionData.name?.length || !sessionData.endDate?.length}
        isLoading={confirmLoading}
        onClick={onConfirm}
      >
        Update Session
      </Button>
    </Modal>
  );
};

const SessionPanel = () => {
  const [{ accessToken }] = useCookies(['accessToken']);

  const { activeSession } = useSessions();
  const [endSession, { loading: endSessionLoading }] = useEndVotingSessionMutation();
  const [updateSession, { loading: updateSessionLoading }] = useUpdateVotingSessionMutation();

  const [modalState, setModalState] = useState<ModalType | undefined>();
  const [updateSessionData, setUpdateSessionData] = useState<{
    name: string | undefined;
    endDate: string | undefined;
  }>({ name: '', endDate: '' });
  const sessionDetails = useMemo(
    () => [
      { Icon: GraphIcon, value: activeSession?._count?.votes, label: 'No. of Votes' },
      { Icon: EyeIcon, value: activeSession?._count?.views, label: 'Views' },
      {
        Icon: CustomCalendarIcon,
        value: moment(activeSession?.startDate).format('MMMM Do, h:mm:ss a'),
        label: 'Start date',
      },
      {
        Icon: CustomCalendarIcon,
        value: moment(activeSession?.endDate).format('MMMM Do, h:mm:ss a'),
        label: 'End date',
      },
    ],
    [activeSession]
  );

  const handleSessionState = useCallback(() => {
    switch (modalState) {
      case ModalType.PAUSE:
        //TODO - Pause and resume API integration
        break;
      case ModalType.EDIT:
        updateSession &&
          activeSession &&
          updateSession({
            variables: {
              updateVotingSessionId: activeSession.id,
              name: updateSessionData.name,
              endDate: moment(updateSessionData.endDate).toISOString(),
              accessToken,
            },
            onCompleted: () => {
              customToast('Session updated successfully');
              setModalState(undefined);
            },
            onError: (err) => {
              customToast(err.message, true);
            },
          });
        break;
      case ModalType.CLOSE:
        endSession &&
          endSession({
            variables: {
              accessToken,
            },
            onCompleted: () => {
              customToast('Session killed successfully', true);
              setModalState(undefined);
            },
            onError: (err) => {
              customToast(err.message, true);
            },
          });
        break;
    }
    setModalState(undefined);
  }, [modalState, updateSessionData, activeSession]);

  useEffect(() => {
    setUpdateSessionData({
      name: activeSession?.name as string,
      endDate: moment(activeSession?.endDate).format('YYYY-MM-DDTHH:mm'),
    });
  }, [activeSession]);

  return (
    <div className="w-full">
      <div className="flex w-full justify-between">
        <Header className="bg-primary-gradient bg-clip-text text-transparent">Sessions</Header>
        <Button
          variant="primary"
          onClick={() => setModalState(ModalType.CREATE)}
          isDisabled={!!activeSession?.id}
        >
          Create New Session
        </Button>
      </div>
      {activeSession?.id ? (
        <>
          <Card className="border-[0.5px] border-b-0 border-solid border-[#D274F7] rounded-b-none relative z-50 mt-5">
            <div className="h-10 w-full flex justify-center items-center bg-primary-gradient-light overflow-hidden">
              <span className="bg-primary-gradient bg-clip-text text-transparent text-sm md:text-base font-text-bold">
                Active
              </span>
            </div>
            <div className="relative overflow-hidden">
              <WaveImage className="absolute !h-full lg:w-full w-auto" />
              <div className="grid grid-cols-[1fr_1fr_1fr]  items-center p-4 md:p-6 gap-4">
                <div className="flex items-center gap-[14px] text-base lg:text-xl">
                  <img
                    src="/SessionIcon.svg"
                    alt="session-icon"
                    className="min-h-12 min-w-12 lg:min-h-[68px] lg:min-w-[68px]"
                  />
                  <div className="break-all line-clamp-1">{activeSession.name}</div>
                </div>
                <div className="flex text-center flex-col  text-[#ffffff99] ">
                  <CountdownTimer />
                  Countdown
                </div>
                <div className="flex items-center justify-end gap-3 ">
                  <Button
                    isDisabled
                    classNames={{ base: 'h-[unset] !py-2 rounded-t-none' }}
                    onClick={() => setModalState(ModalType.PAUSE)}
                  >
                    Pause
                  </Button>

                  <Button
                    variant="primary"
                    classNames={{ base: 'h-[unset] !py-2 rounded-t-none' }}
                    onClick={() => setModalState(ModalType.EDIT)}
                  >
                    Edit
                  </Button>
                </div>
              </div>
              <div className="border-0 border-t border-solid border-[#282131] grid grid-cols-2 lg:grid-cols-[25%_25%_25%_25%]">
                {sessionDetails.map(({ Icon, value, label }, index) => {
                  return (
                    <div
                      className={classes(
                        'flex flex-col justify-center items-center gap-[1px] md:gap-1 pt-[14px] pb-[10px] md:py-[23px] border-0 border-b lg:border-0 border-solid border-[#282131]',
                        index > 0 && 'border-l'
                      )}
                      key={index}
                    >
                      <div className="flex items-center gap-2 !text-lg lg:text-2xl">
                        <Icon className="h-6 w-6" /> {value}
                      </div>
                      <div className="text-sm text-gray lg:text-base">{label}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </Card>
          <Button
            variant="plain"
            classNames={{
              base: 'h-[unset] !py-2 rounded-t-none border border-[#931b1c] !bg-[#1c0b0b] !bg-none w-full !bg-clip-border rounded-b-[10px]',
              container: 'text-base !text-[#e75f5fd1] uppercase',
            }}
            onClick={() => setModalState(ModalType.CLOSE)}
          >
            Kill Session
          </Button>
        </>
      ) : (
        <NoSession classNames={{ base: 'mt-4' }} />
      )}
      <ConfirmationModal
        isOpen={modalState === ModalType.CLOSE}
        setIsOpen={setModalState}
        message={`Are you sure you want to end active session?`}
        confirmLoading={endSessionLoading}
        onConfirm={handleSessionState}
      />
      <EditSessionModal
        isOpen={modalState === ModalType.EDIT}
        setIsOpen={setModalState}
        confirmLoading={updateSessionLoading}
        sessionData={updateSessionData}
        setSessionData={setUpdateSessionData}
        onConfirm={handleSessionState}
      />
      <CreateSessionModal isOpen={modalState === ModalType.CREATE} setIsOpen={setModalState} />
    </div>
  );
};

export default SessionPanel;
