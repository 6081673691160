import {
  BillIcon,
  ChainAnalyticsIcon,
  CheckIcon,
  Clock2Icon,
  CollateralValueTrackingIcon,
  CrossChainDefiIcon,
  CrossChainNFTIcon,
  CrossChainTokenSwapIcon,
  CustomLink,
  DAOAnalyticsIcon,
  DEXEngagementIcon,
  DecentralizedContentDistributionIcon,
  DecentralizedGamingIcon,
  DecentralizedIdentityIcon,
  DecentralizedSocialMediaIcon,
  DollarIcon,
  EntryIcon,
  EyeIcon,
  GameIcon,
  GovernanceAnalyticsIcon,
  HistoricalPriceIcon,
  KeyIcon,
  LeaderboardIcon,
  LiquidityPoolOptimizationIcon,
  MarketplaceInsightsIcon,
  NFTCollectionDataAnalyticsIcon,
  NFTMarketplaceDataIcon,
  NFTMetadataQueriesIcon,
  PortfolioManagementIcon,
  Profile2Icon,
  ProfileIcon,
  QuestionIcon,
  RealEstateTokenizationIcon,
  RebasingMechanismIcon,
  SearchIcon,
  StarIcon,
  SupplyChainTransparencyIcon,
  TokenAnalyticsIcon,
  TokenGateCryptoIcon,
  TradingStrategyOptimizationIcon,
  TrophyIcon,
  UserAnalyticsIcon,
  WalletAnalyticsIcon,
} from 'components';
import { pathTo } from './routes';

export const UseCases = [
  { name: 'Liquidity Pool Optimization', icon: LiquidityPoolOptimizationIcon },
  { name: 'Rebasing Mechanism', icon: RebasingMechanismIcon },
  { name: 'DEX Engagement Tracking', icon: DEXEngagementIcon },
  { name: 'Trading Strategy Optimization', icon: TradingStrategyOptimizationIcon },
  { name: 'Collateral Value Tracking', icon: CollateralValueTrackingIcon },
  { name: 'Historical Price Analysis', icon: HistoricalPriceIcon },
  { name: 'NFT Marketplace Data', icon: NFTMarketplaceDataIcon },
  { name: 'Governance Analytics', icon: GovernanceAnalyticsIcon },
  { name: 'Cross-Chain DeFi Insights', icon: CrossChainDefiIcon },
  { name: 'Chain Analytics', icon: ChainAnalyticsIcon },
  { name: 'User Analytics', icon: UserAnalyticsIcon },
  { name: 'Wallet Analytics', icon: WalletAnalyticsIcon },
  { name: 'Token Analytics', icon: TokenAnalyticsIcon },
  { name: 'DAO Analytics', icon: DAOAnalyticsIcon },
  { name: 'Marketplace Insights Aggregator', icon: MarketplaceInsightsIcon },
  { name: 'Portfolio Management Dashboard', icon: PortfolioManagementIcon },
  { name: 'Decentralized Identity Verification', icon: DecentralizedIdentityIcon },
  { name: 'Supply Chain Transparency Tracker', icon: SupplyChainTransparencyIcon },
  { name: 'Real Estate Tokenization Platform', icon: RealEstateTokenizationIcon },
  { name: 'Decentralized Gaming Platform', icon: DecentralizedGamingIcon },
  { name: 'Cross-Chain Token Swap Aggregator', icon: CrossChainTokenSwapIcon },
  { name: 'Decentralized Social Media Analytics Platform', icon: DecentralizedSocialMediaIcon },
  {
    name: 'Decentralized Content Distribution Network (CDN)',
    icon: DecentralizedContentDistributionIcon,
  },
  { name: 'Cross-Chain NFT Analytics', icon: CrossChainNFTIcon },
  { name: 'NFT Collection Data Analytics', icon: NFTCollectionDataAnalyticsIcon },
  { name: 'NFT Metadata Queries', icon: NFTMetadataQueriesIcon },
  { name: 'Token Gate Crypto Communities', icon: TokenGateCryptoIcon },
];

export const FAQ_DATA = [
  {
    section: 'Incentivized Testnet',
    faq: [
      {
        title: 'What is Analog Incentivized Testnet?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>
              Analog provides a suite of interoperability protocols designed to address multi-chain
              and cross-chain challenges, catering to the requirements of native DApp development.
              Before deploying the mainnet, it’s crucial to thoroughly test the key features of the
              products we intend to launch, starting with the{' '}
              <CustomLink
                link={import.meta.env.VITE_WATCH_FRONTEND_URL}
                onClick={(e) => e.stopPropagation()}
              >
                Analog Watch
              </CustomLink>
              .
            </div>
            <div>
              The Analog Incentivized Testnet is a crucial phase in our product development. It’s a
              version of the Analog Network where we encourage developers, users, and node operators
              to participate actively. Your participation is not just about testing but also about
              shaping the future of our products.
            </div>
          </div>
        ),
        icon: Clock2Icon,
      },
      {
        title: 'Who is eligible?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            Participation in the Analog Incentivized Testnet is open to all—whether participating as
            a technical (i.e., developer or validator) or non-technical specialist. However, to
            ensure a smooth onboarding process and maintain the quality of feedback, we’re limiting
            the number of users at any given time. So,{' '}
            <CustomLink link={pathTo('SignUp')} useLink onClick={(e) => e.stopPropagation()}>
              sign up
            </CustomLink>{' '}
            now and wait for your exclusive email invitation to join the program.
          </div>
        ),
        icon: EntryIcon,
      },
      {
        title: 'Why should you join the Analog Incentivized Testnet?',
        description:
          'By joining the Analog Incentivized Testnet, you will have the opportunity to experience the features of the products we’re developing firsthand. Based on your feedback, we’ll be better positioned to implement the necessary changes before going live. The best part is that you’ll earn ANLOG Testnet Points (ATPs) in the process.',
        icon: QuestionIcon,
      },
      {
        title: 'What is ATP?',
        description:
          'ATP, or ANLOG Testnet Points, are points that are distributed to various participants, such as community members, developers, and validators) for completing tasks on the various products we intend to launch. ',
        icon: DollarIcon,
      },
      {
        title: 'Why am I not getting my points after completing a quest?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            It will take up to 5 minutes for certain quest points to refresh. If you don’t see the
            updated points after this time, please submit a ticket on our{' '}
            <CustomLink
              link="https://discord.com/invite/analog"
              onClick={(e) => e.stopPropagation()}
            >
              Discord server
            </CustomLink>
            .
          </div>
        ),
        icon: QuestionIcon,
      },
      {
        title:
          'How does changing the wallet address affect my earned points (i.e., ATP)? Will my earned ATP be retained in the new wallet if I change the wallet address?',
        description: (
          <div>
            It’s important to note that both the Watch Game and Analog Watch will use the same
            wallet to accumulate and process your earned ATP. If you’ve previously used Analog
            Watch, you should ensure that you’ve connected to the same wallet. If you connect a
            different wallet, you won’t be able to switch back to the old one without{' '}
            <CustomLink
              link="https://discord.com/invite/analog"
              onClick={(e) => e.stopPropagation()}
            >
              submitting a request to the Analog team
            </CustomLink>
            . Please be aware that this process will automatically erase the accumulated ATP
            associated with the current wallet.
          </div>
        ),
        icon: BillIcon,
      },
      {
        title: 'Where can I access FAQs for Watch?',
        description: (
          <div>
            Watch FAQs are available in the Watch documentation, accessible{' '}
            <CustomLink
              link="https://docs.analog.one/documentation/analog-network/faq"
              onClick={(e) => e.stopPropagation()}
            >
              here.
            </CustomLink>
          </div>
        ),
        icon: StarIcon,
      },
      {
        title: 'How do I get the testnet tokens?',
        description: (
          <div>
            Refer{' '}
            <CustomLink
              link="https://docs.analog.one/documentation/resources/testnet-faucet"
              onClick={(e) => e.stopPropagation()}
            >
              {' '}
              here
            </CustomLink>{' '}
            for further information about acquiring testnet tokens.{' '}
          </div>
        ),
        icon: SearchIcon,
      },
      {
        title: 'How long does it take for my points to update?',
        description:
          'Points typically take up to 5 minutes to reflect changes on the Quests and Leaderboard screens.',
        icon: QuestionIcon,
      },
      {
        title: 'Why am I not getting Galxe quest points?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>
              To redeem your Galxe quest points, go to the{' '}
              <CustomLink link={`/#${pathTo('Quests')}`} onClick={(e) => e.stopPropagation()}>
                Quests page
              </CustomLink>
              , locate the “Complete the Galxe Campaign” section, navigate to Galxe’s platform to
              complete tasks then click on “Claim” on the bottom of the campaign page.
            </div>
            <div>
              Navigate back to Analog’s Incentivized Testnet’s Quest page, and click the “Claim”
              button on the Galxe quest. You’ll be prompted to connect to a wallet. Ensure you’ve
              connected to the right EVM wallet (i.e., the one you used on the Galxe platform) to
              redeem your ATP. Please note that your Galxe quest points won’t show up unless you
              click on the “Claim” button.
            </div>
          </div>
        ),
        icon: DollarIcon,
      },
      {
        title: 'How does the referral system work?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>
              Our referral system is a great way to boost your ATP earnings. For every person you
              invite to participate, you earn ¼ of the total ATP they accumulate throughout the
              Incentivized Testnet. The more friends you invite, the more ATP you can potentially
              earn!
            </div>
            <div className="mt-2">To get started:</div>
            <ul className="[list-style:decimal] pl-4">
              <li>
                Head over to the home page of the Incentivized Testnet Page and locate the “Referral
                Link” section.
              </li>
              <li>
                Click on the “Create Referral Link” button. This will create a unique referral link.
              </li>
              <li>
                Copy that link and click the “Done” button. You can now share this link with your
                friends.
              </li>
            </ul>
            <div className="mt-1">
              To earn referral points from your invited friends, two conditions need to be
              fulfilled:
            </div>
            <ul className="[list-style:disc] pl-4">
              <li>
                Your invited friends need to sign in to the Analog Incentivized Testnet App using
                the shared link. They will be required to sign in with their email and connect their
                wallets to confirm.
              </li>
              <li>
                Your invited friends need to complete the various quests that qualify them to earn
                ATP.
              </li>
            </ul>
          </div>
        ),
        icon: BillIcon,
      },
    ],
  },
  {
    section: 'Analog Watch Game',
    faq: [
      {
        title: 'What is the Watch Game?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            The Watch Game is a testnet version of the{' '}
            <CustomLink
              link={import.meta.env.VITE_WATCH_FRONTEND_URL}
              onClick={(e) => e.stopPropagation()}
            >
              Analog Watch
            </CustomLink>{' '}
            that provides a fun way for Voters and Data Designers to explore and evaluate the
            protocol’s functionalities. It provides a gamified experience, incentivizing Voters to
            vote for useful Views and rewarding Data Designers for creating such Views.
          </div>
        ),
        icon: GameIcon,
      },
      {
        title: 'What are the required activities for different participants in the Watch Game?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            There are two categories of users: <span className="font-medium">Voters</span> and{' '}
            <span className="font-medium">Data Designers</span>. Voters will earn ATP based on
            participation (i.e., how many voting sessions they have participated in) and performance
            (i.e., how the Views they have voted for have performed). Data Designers will earn ATP
            based on participation (i.e., how many of their Views get shortlisted in the Analog
            Watch Game) and performance (i.e., how their Views have performed).
          </div>
        ),
        icon: ProfileIcon,
      },
      {
        title: 'Who is a voter?',
        description:
          'A voter is any user who participates in the Analog Watch Game by voting on Views they deem useful. ',
        icon: Profile2Icon,
      },
      {
        title: 'What can I win as a voter?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <ul className="[list-style:disc] pl-4">
              <li>For each session in which you participate as a voter, you earn 10 ATP.</li>
              <li>If the View you voted for wins in a session, you earn 20 ATP.</li>
              <li>
                If you have a winning streak (i.e., the View you voted for wins in successive
                sessions), you earn twice the ATP in the current session. In other words, you double
                your ATP earnings from 20 to 40.
              </li>
              <li>If your voted View does not win, you earn 0 ATP. </li>
            </ul>
          </div>
        ),
        icon: TrophyIcon,
      },
      {
        title: 'How can I vote for my favorite View?',
        description: `Voting is a very simple process. Navigate to the View you'd like to vote for in the current active Voting Session and "Vote" button to vote for it.`,
        icon: CheckIcon,
      },
      {
        title: 'Who is a Data Designer?',
        description:
          'A Data Designer is any user that participates in the Analog Watch Game by creating Views that get shortlisted for voting sessions.',
        icon: ProfileIcon,
      },
      {
        title: 'What can I win as a Data Designer?',
        description:
          'If your View gets shortlisted in a voting session, you earn 10 ANLOG Testnet Points (ATP). Additionally, each time your View wins in a session, you earn 80 ATP. You can also earn ATP via a referral program. For example, if you refer a user or friend to the network, you earn ¼ of the total ATP they accumulate throughout the entire duration of the Watch Game.',
        icon: TrophyIcon,
      },
      {
        title: 'How do I create Views?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>You can create Views as follows:</div>
            <div>
              - Launch the Analog Watch app by visiting{' '}
              <CustomLink
                link={import.meta.env.VITE_WATCH_FRONTEND_URL}
                onClick={(e) => e.stopPropagation()}
              >
                https://watch.testnet.analog.one
              </CustomLink>
            </div>
            <div>- Connect your Substrate wallet.</div>
            <div>- Click the “View Builder.”</div>
            <div>
              - Build and deploy your View. For more details, check our extensive documentation at{' '}
              <CustomLink
                link="https://docs.analog.one/documentation/developers/analog-watch/developers-guide/deploying-views"
                onClick={(e) => e.stopPropagation()}
              >
                https://docs.analog.one/documentation/developers/analog-watch/developers-guide/deploying-views
              </CustomLink>
              .
            </div>
            <div>- Approve the View for shortlisting on the Incentivized Testnet app.</div>
          </div>
        ),
        icon: EyeIcon,
      },
      {
        title: 'How long does a voting session last?',
        description:
          'Each voting session lasts for ten days. The session will proceed without interruption for seven consecutive days, with an allotted 3-day period for the Analog team to pause and reset the system.',
        icon: QuestionIcon,
      },
    ],
  },
  {
    section: 'Validator',
    faq: [
      {
        title: 'How do I apply to become a Time Node Operator for the Incentivized Testnet?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            For now, registering to become a Time Node operator is permissioned. To join, please
            fill out this{' '}
            <CustomLink
              link={'https://l5d87lam6fy.typeform.com/to/kwlADm6U'}
              onClick={(e) => e.stopPropagation()}
            >
              form
            </CustomLink>{' '}
            to be added to the waitlist.
          </div>
        ),
        icon: ProfileIcon,
      },
      {
        title: 'What are the criteria for ranking/scoring Time Nodes in the Incentivized Testnet?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>
              Each Time Node has a score displayed in the Validator Leaderboard, which expresses the
              node’s overall reliability, responsiveness, and performance on the Analog Testnet.
              Other metrics that the network uses to compute the score include:
            </div>
            <div className="text-sm text-gray whitespace-pre-line">
              <ul className="[list-style:disc] pl-4">
                <li>Running the Time Node in a unique region/location.</li>
                <li>Triggering the payouts regularly.</li>
                <li>
                  Ensuring the node software is up to date (i.e., following{' '}
                  <CustomLink
                    link={'https://discord.com/channels/860069399627825163/1242304290740244561'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Discord’s #validator-support
                  </CustomLink>{' '}
                  for regular updates).
                </li>
              </ul>
            </div>
            <div className="mt-1">
              The score determines how many TANLOG tokens a Time Node operator will receive as a
              reward after the Testnet phase. When Time Nodes initially join the network after
              staking and are placed in an active set, they start with an initial score of 0.
            </div>
            <div className="mt-1">
              They can gain the score in each epoch depending on their role in that round—by
              authoring valid blocks—and their behavior within the assigned role. For example, a
              performant Time Node will receive a higher score and thus greater rewards than a Time
              Node that sometimes fails to contribute or is not consistently online.
            </div>
          </div>
        ),
        icon: LeaderboardIcon,
      },
      {
        title: 'What are the minimum hardware specifications for the Time Node?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            Check out our{' '}
            <CustomLink
              link={
                'https://docs.analog.one/documentation/node-operators/running-a-timechain-node#prerequisites'
              }
              onClick={(e) => e.stopPropagation()}
            >
              docs
            </CustomLink>{' '}
            to learn more about hardware requirements.
          </div>
        ),
        icon: EntryIcon,
      },
      {
        title:
          'If I were to take on the role of a Time Node operator for the Timechain, what long-term return on investment (ROI) can I anticipate?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>
              When the mainnet launches, ANLOG holders can anticipate an annual staking reward of
              around 8%. Currently, the optimal staking ratio stands at 60%. Any deviation from this
              ideal ratio, whether an increase or decrease, will result in a corresponding
              adjustment in the reward rate.
            </div>
            <div className="mt-1">
              Refer to the{' '}
              <CustomLink
                link={
                  'https://docs.google.com/document/d/e/2PACX-1vRCpMd7VmDxqGYXYZfj-1RyHdWRU6DfBf1tTad4vlpmmvp4Coavwh9mmT42Te665A/pub'
                }
                onClick={(e) => e.stopPropagation()}
              >
                Tokenomics Paper
              </CustomLink>{' '}
              for more details.
            </div>
          </div>
        ),
        icon: QuestionIcon,
      },
      {
        title: 'What is the minimum stake necessary to qualify as an active Time Node?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>
              The Timechain, built on the Substrate SDK, operates on an NPoS consensus algorithm.
              The minimum stake required to become an active Time Node is not fixed, but changes
              from era to era. The minimum stake is influenced by three factors:
            </div>
            <div>
              <ul className="[list-style:disc] pl-4">
                <li>
                  The amount of stake bonded behind each Time Node (i.e., every Time Node’s own
                  stake plus their nominated stake).
                </li>
                <li>The size of the active set</li>
                <li>The number of Time Nodes that are waiting in the queue.</li>
              </ul>
            </div>
            <div className="mt-1">
              One way to estimate the minimum stake is through Polkadot.js. Simply launch
              Polkadot.js and navigate to{' '}
              <CustomLink
                link={
                  'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.testnet.analog.one#/staking'
                }
                onClick={(e) => e.stopPropagation()}
              >
                Network&gt;Staking&gt;Targets
              </CustomLink>
              . The amount displayed at the top of the screen indicating{' '}
              <span className="font-bold">“lowest / avg staked”</span> should provide an approximate
              estimation of the Time Node with the lowest stake.
            </div>
            <div className="mt-1">
              You will be required to stake at least this amount plus 1 ANLOG token to join the
              active set. If you have less ANLOG tokens and still wish to participate in staking,
              you have the option to nominate other Time Nodes and share the rewards accordingly.
            </div>
          </div>
        ),
        icon: EyeIcon,
      },
      {
        title: 'How can I nominate a Time Node?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            <div>
              Before you nominate any Time Node, it is important you do your own research and
              nominate reputable Time Nodes. This is because active Time Nodes that misbehave face
              penalties in the form of slashed ANLOG tokens, comprising both their own stake and
              your nominated stake.
            </div>
            <div className="mt-1">
              To nominate a Time Node on the Timechain, you need the following:{' '}
              <ul className="[list-style:disc] pl-4">
                <li>
                  Have an account with a non-custodial wallet such as{' '}
                  <CustomLink
                    link={'https://www.talisman.xyz/'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Talisman
                  </CustomLink>
                  ,{' '}
                  <CustomLink
                    link={'https://fearlesswallet.io/'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Fearless
                  </CustomLink>
                  , or{' '}
                  <CustomLink
                    link={'https://www.subwallet.app/'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    SubWallet
                  </CustomLink>
                  . Analog recommends{' '}
                  <CustomLink
                    link={'https://www.talisman.xyz/'}
                    onClick={(e) => e.stopPropagation()}
                  >
                    Talisman
                  </CustomLink>
                  .
                </li>
                <li>
                  Have more ANLOG tokens than the minimum threshold. Read this for more details.{' '}
                </li>
                <li>
                  Bond and nominate your ANLOG tokens to Time Nodes that are accepting nominations.
                  You can nominate your Time Node by visiting Polkadot.js and navigating to{' '}
                  <CustomLink
                    link={
                      'https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.testnet.analog.one#/staking'
                    }
                    onClick={(e) => e.stopPropagation()}
                  >
                    Network&gt;Staking&gt;Targets
                  </CustomLink>
                  .
                </li>
              </ul>
            </div>
            <div className="mt-1">
              Note that rewards will only be earned if your nominated stake is applied to one or
              more Time Nodes that become part of the active set starting from the next era. You can
              nominate up to 16 Time Nodes.
            </div>
          </div>
        ),
        icon: SearchIcon,
      },
      {
        title: 'When are rewards computed for the Incentivized Testnet?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            Your score will be used to compute your rewards on a per-era basis (approximately 24
            hours). This information will be displayed on the{' '}
            <CustomLink
              link={pathTo('ValidatorLeaderboard')}
              onClick={(e) => e.stopPropagation()}
              useLink
            >
              Validator Leaderboard
            </CustomLink>
            .
          </div>
        ),
        icon: DollarIcon,
      },
      {
        title: 'How does the slashing mechanism work on the Timechain?',
        description: (
          <div className="text-sm text-gray whitespace-pre-line">
            How does the slashing mechanism work on the Timechain? Your Time Node will be slashed if
            it misbehaves (e.g., runs a modified software, attacks the network, goes offline, etc.).
            It is worth noting that slashing in Timechain affects the total stake of the Time Node,
            which includes both its own stake and nominated stake. Penalties can range from 0.01% to
            100%, depending on the nature of the misconduct. As a nominator, you can minimize the
            slashing risks by only nominating reputable Time Nodes.
          </div>
        ),
        icon: KeyIcon,
      },
    ],
  },
];
