import { Profile2Icon, WinCup, WinMedal, WinStar } from 'components';
import { classes } from 'utils';
import { usePageFilter } from 'hooks';
import { Skeleton } from '../../components/Skeleton';

interface Props {
  data: any[];
  showPagination?: boolean;
  defaultPageSize?: number;
  isLoading?: boolean;
  myRank?: any;
}

export function LeaderboardCard({
  data,
  showPagination,
  defaultPageSize,
  isLoading,
  myRank,
}: Props) {
  const { controller, currentPage } = usePageFilter(data, defaultPageSize, [
    '10',
    '20',
    '30',
    '100',
  ]);

  return (
    <>
      <div className="flex flex-col gap-4">
        {(showPagination ? currentPage : data).length ? (
          (showPagination ? currentPage : data).map((row, index) => {
            return (
              <div
                className={classes(
                  'text-white rounded-[10px] border-solid border  border-dark-gray p-[16px_14px_12px_16px]',
                  index === 0 &&
                    'md:[background:linear-gradient(140deg,_#d274f71a_16.37%,_#9a74f71a_50.02%),_#000] [background:linear-gradient(140deg,_#d274f71a_16.37%,_#9a74f71a_50.02%)] border-t-light-purple',
                  index === 1 &&
                    'md:[background:linear-gradient(140deg,_#d274f712_16.37%,_#9a74f712_50.02%),_#000] [background:linear-gradient(140deg,_#d274f712_16.37%,_#9a74f712_50.02%)] border-t-[#D274F780]',
                  index === 2 &&
                    'md:[background:linear-gradient(140deg,_#d274f70d_16.37%,_#9a74f70d_50.02%),_#000] [background:linear-gradient(140deg,_#d274f70d_16.37%,_#9a74f70d_50.02%)] border-t-[#D274F749]',
                  row.showYou &&
                    'border border-solid border-black !bg-[linear-gradient(178deg,_var(--tw-gradient-stops))] !from-[#d274f761_16.37%] !to-[#9a74f761_50.02%]',
                  myRank == row.rank && 'showHighlighted'
                )}
                key={index}
              >
                <div className="flex justify-center items-center w-full">
                  <div className="flex flex-row gap-[6px] items-center w-full">
                    <Skeleton.Loader className="h-7 w-7 !rounded-full">
                      <Profile2Icon className="min-h-7 min-w-7" />
                    </Skeleton.Loader>
                    <div className="flex flex-col w-[80%]">
                      <div className="text-sm truncate w-[90%] capitalize">
                        <Skeleton.Loader className="w-[122px]">{row.name}</Skeleton.Loader>
                      </div>
                    </div>
                  </div>
                  {myRank === row.rank && (
                    <div className="flex justify-center items-center text-xs h-6 w-12 rounded-[28px] bg-[linear-gradient(178deg,_var(--tw-gradient-stops))] from-[#d274f780_16.37%] to-[#9a74f780_50.02%]">
                      YOU
                    </div>
                  )}
                </div>
                <div className=" w-full h-[0.5px] my-3 bg-[#202020]"></div>
                <div className="flex flex-row justify-between mt-3">
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-10"> Place:</Skeleton.Loader>
                    </span>
                    <Skeleton.Loader className="w-[6px] h-5">
                      <span
                        className={classes(
                          (row?.rank === 1 || row?.rank === 2 || row?.rank === 3) &&
                            'bg-[linear-gradient(140deg,_var(--tw-gradient-stops))] from-[#D274F7_16.37%] to-[#9A74F7_50.02%]  bg-clip-text text-transparent'
                        )}
                      >
                        #{row?.rank}
                      </span>{' '}
                      {row?.rank === 1 && (
                        <div className=" p-1 rounded-full bg-[#ffffff0d]">
                          <WinCup className="w-3 h-3" />
                        </div>
                      )}
                      {row?.rank === 2 && (
                        <div className=" p-1 rounded-full bg-[#ffffff0d] ">
                          <WinStar className="w-3 h-3" />
                        </div>
                      )}
                      {row?.rank === 3 && (
                        <div className=" p-1 rounded-full bg-[#ffffff0d] ">
                          <WinMedal className="w-3 h-3" />
                        </div>
                      )}
                    </Skeleton.Loader>
                  </div>
                  {(row?.wins || row.wins == 0) && (
                    <div className="text-sm flex flex-row items-center gap-1">
                      <span className="text-xs text-[#ffffff80] uppercase">
                        <Skeleton.Loader className="w-[60px] h-4">No. of Wins:</Skeleton.Loader>
                      </span>{' '}
                      <Skeleton.Loader className="w-4 h-5"> {row.wins}</Skeleton.Loader>
                    </div>
                  )}
                  <div className="text-sm flex flex-row items-center gap-1">
                    <span className="text-xs text-[#ffffff80] uppercase">
                      <Skeleton.Loader className="w-[60px] h-4">Total Points:</Skeleton.Loader>
                    </span>{' '}
                    <Skeleton.Loader className="h-5 w-[50px]">
                      <div className="flex items-baseline gap-[3px]">
                        {row.points}
                        <div className="text-[#ffffff80] text-[10px] leading-[27px]">ATP</div>
                      </div>
                    </Skeleton.Loader>
                  </div>
                  {row.showYou && (
                    <div className="flex justify-center items-center text-xs h-6 w-12 rounded-[28px] bg-[linear-gradient(178deg,_var(--tw-gradient-stops))] from-[#d274f780_16.37%] to-[#9a74f780_50.02%]">
                      YOU
                    </div>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-white flex justify-center md:text-lg text-base">
            {isLoading ? 'Loading...' : 'No Data Found'}
          </div>
        )}
      </div>
      {showPagination && controller}
    </>
  );
}
