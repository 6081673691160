import copyToClipboard from 'copy-to-clipboard';
import { Button } from './Button';
import { classes } from 'utils';
import { HTMLAttributes } from 'types';
import { CopyIcon, CheckIcon, GradientCopyIcon, GradientCheckIcon } from 'components';
import { useCallback, useState } from 'react';
import { Tooltip } from 'components';

interface Props extends HTMLAttributes<HTMLButtonElement> {
  value?: string | null;
  id: string;
  gradientIcon?: boolean;
}

export function CopyButton({ className, value, id, gradientIcon }: Props) {
  const [isCopied, setIsCopied] = useState(false);

  const copyHandler = useCallback(() => {
    if (value && !isCopied) {
      copyToClipboard(value);
      setIsCopied(true);
    }
  }, [value, isCopied]);

  const afterHideHandler = useCallback(() => {
    isCopied && setIsCopied(false);
  }, [isCopied]);

  return (
    <Button
      data-tooltip-id={id}
      variant="plain"
      isIcon
      className={classes('h-auto text-blue-500 hover:text-blue-300', className)}
      onClick={copyHandler}
    >
      {isCopied ? (
        gradientIcon ? (
          <GradientCheckIcon />
        ) : (
          <CheckIcon />
        )
      ) : gradientIcon ? (
        <GradientCopyIcon />
      ) : (
        <CopyIcon />
      )}
      <Tooltip
        id={id}
        content={isCopied ? 'Copied' : 'Copy'}
        afterHide={afterHideHandler}
        delay={1000}
      />
    </Button>
  );
}
