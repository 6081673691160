/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable import/no-unresolved */
import { createRoot } from 'react-dom/client';
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  split,
} from '@apollo/client';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { RetryLink } from '@apollo/client/link/retry';
import TagManager from 'react-gtm-module';
import './index.css';
import App from './App';
import { load, defaultEndpoint, defaultScriptUrlPattern } from '@fingerprintjs/fingerprintjs-pro';

const tagManagerArgs = {
  gtmId: import.meta.env.VITE_WATCH_FRONTEND_GTMID,
};

TagManager.initialize(tagManagerArgs);

const wsLink = new GraphQLWsLink(
  createClient({
    url: import.meta.env.VITE_SUBSCRIPTION_ENDPOINT,
  })
);

const httpLink = new HttpLink({
  uri: import.meta.env.VITE_BACKEND_ENDPOINT + 'graphql',
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

export const client = new ApolloClient({
  link: ApolloLink.from([new RetryLink(), splitLink]),
  cache: new InMemoryCache(),
});

export const fpPromise = load({
  apiKey: 'r59nfy8ZqQg1tZWmIyS8',
  endpoint: ['https://fp.analog.one', defaultEndpoint],
  scriptUrlPattern: [
    'https://fp.analog.one/web/v<version>/<apiKey>/loader_v<loaderVersion>.js',
    defaultScriptUrlPattern,
  ],
  region: 'eu',
});

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(
    // <StrictMode>
    <ApolloProvider client={client}>
      <App />
    </ApolloProvider>
    // </StrictMode>
  );
}
