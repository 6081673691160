import { HTMLAttributes } from 'types';

export function DefaultIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M25.3337 12.667H12.667V25.3337H25.3337V12.667Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3337 12.667H12.667V25.3337H25.3337V12.667Z"
        stroke="url(#paint0_linear_2525_113840)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.43555 31.6505C4.08471 33.503 5.85807 34.833 7.91641 34.833C10.5289 34.833 12.6664 32.6955 12.6664 30.083V25.333H7.91641"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.43555 31.6505C4.08471 33.503 5.85807 34.833 7.91641 34.833C10.5289 34.833 12.6664 32.6955 12.6664 30.083V25.333H7.91641"
        stroke="url(#paint1_linear_2525_113840)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91699 12.667H12.667V7.91699C12.667 5.30449 10.5295 3.16699 7.91699 3.16699C5.30449 3.16699 3.16699 5.30449 3.16699 7.91699C3.16699 10.5295 5.30449 12.667 7.91699 12.667Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91699 12.667H12.667V7.91699C12.667 5.30449 10.5295 3.16699 7.91699 3.16699C5.30449 3.16699 3.16699 5.30449 3.16699 7.91699C3.16699 10.5295 5.30449 12.667 7.91699 12.667Z"
        stroke="url(#paint2_linear_2525_113840)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.333 12.667H30.083C32.6955 12.667 34.833 10.5295 34.833 7.91699C34.833 5.30449 32.6955 3.16699 30.083 3.16699C27.4705 3.16699 25.333 5.30449 25.333 7.91699V12.667Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.333 12.667H30.083C32.6955 12.667 34.833 10.5295 34.833 7.91699C34.833 5.30449 32.6955 3.16699 30.083 3.16699C27.4705 3.16699 25.333 5.30449 25.333 7.91699V12.667Z"
        stroke="url(#paint3_linear_2525_113840)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.083 34.833C32.6955 34.833 34.833 32.6955 34.833 30.083C34.833 27.4705 32.6955 25.333 30.083 25.333H25.333V30.083C25.333 32.6955 27.4705 34.833 30.083 34.833Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.083 34.833C32.6955 34.833 34.833 32.6955 34.833 30.083C34.833 27.4705 32.6955 25.333 30.083 25.333H25.333V30.083C25.333 32.6955 27.4705 34.833 30.083 34.833Z"
        stroke="url(#paint4_linear_2525_113840)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2525_113840"
          x1="14.1412"
          y1="14.1247"
          x2="18.5612"
          y2="19.3742"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2525_113840"
          x1="4.50987"
          y1="26.4263"
          x2="7.83983"
          y2="30.2691"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2525_113840"
          x1="4.27263"
          y1="4.26028"
          x2="7.58768"
          y2="8.19739"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2525_113840"
          x1="26.4386"
          y1="4.26028"
          x2="29.7537"
          y2="8.19739"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2525_113840"
          x1="26.4386"
          y1="26.4263"
          x2="29.7537"
          y2="30.3634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function ChainAnalyticsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.178 17.3213C24.7405 20.8838 24.7405 26.6471 21.178 30.1938C17.6155 33.7405 11.8523 33.7563 8.30559 30.1938C4.75892 26.6313 4.74309 20.868 8.30559 17.3213"
        stroke="url(#paint0_linear_2377_120428)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.2036 5.01855C26.5628 5.06605 28.9061 6.00022 30.6953 7.80522C34.4003 11.5102 34.4003 17.5269 30.6953 21.2477"
        stroke="url(#paint1_linear_2377_120428)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.2689 21.2325C13.564 17.5275 13.564 11.5109 17.2689 7.79004"
        stroke="url(#paint2_linear_2377_120428)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120428"
          x1="7.75902"
          y1="19.1095"
          x2="12.9739"
          y2="26.3679"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120428"
          x1="25.2825"
          y1="6.88625"
          x2="30.6226"
          y2="10.509"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120428"
          x1="14.8136"
          y1="9.33704"
          x2="17.0181"
          y2="9.87823"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function CollateralValueTrackingIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.7083 3.16699C27.0208 3.16699 33.75 9.89616 33.75 18.2087C33.75 26.5212 27.0208 33.2503 18.7083 33.2503C10.3958 33.2503 3.66663 26.5212 3.66663 18.2087C3.66663 12.3503 7.00746 7.28366 11.9 4.79783"
        stroke="url(#paint0_linear_2377_120395)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 21.2087C16 22.502 17 23.542 18.2267 23.542H20.7333C21.8 23.542 22.6667 22.6353 22.6667 21.502C22.6667 20.2887 22.1333 19.8487 21.3467 19.5687L17.3333 18.1687C16.5467 17.8887 16.0133 17.462 16.0133 16.2353C16.0133 15.1153 16.88 14.1953 17.9467 14.1953H20.4533C21.68 14.1953 22.68 15.2353 22.68 16.5287"
        stroke="url(#paint1_linear_2377_120395)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.3335 12.875V24.875"
        stroke="url(#paint2_linear_2377_120395)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3333 34.8337L32.1666 31.667"
        stroke="url(#paint3_linear_2377_120395)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120395"
          x1="7.16783"
          y1="6.62908"
          x2="17.6655"
          y2="19.0966"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120395"
          x1="16.7774"
          y1="15.271"
          x2="20.0434"
          y2="18.0431"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120395"
          x1="19.4499"
          y1="14.256"
          x2="20.2829"
          y2="14.3384"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120395"
          x1="32.5352"
          y1="32.0314"
          x2="33.6402"
          y2="33.3438"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function CrossChainDefiIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.00008 14.9997C6.84103 14.9997 8.33341 13.5073 8.33341 11.6663C8.33341 9.82539 6.84103 8.33301 5.00008 8.33301C3.15913 8.33301 1.66675 9.82539 1.66675 11.6663C1.66675 13.5073 3.15913 14.9997 5.00008 14.9997Z"
        stroke="url(#paint0_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.0001 14.9997C36.8411 14.9997 38.3334 13.5073 38.3334 11.6663C38.3334 9.82539 36.8411 8.33301 35.0001 8.33301C33.1591 8.33301 31.6667 9.82539 31.6667 11.6663C31.6667 13.5073 33.1591 14.9997 35.0001 14.9997Z"
        stroke="url(#paint1_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6667 11.667H25"
        stroke="url(#paint2_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9999 11.667H8.33325"
        stroke="url(#paint3_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5001 27.5V30.8333C12.5001 31.85 11.8834 32.7333 11.0167 33.1C10.7001 33.25 10.3667 33.3333 10.0001 33.3333H6.66675C5.28341 33.3333 4.16675 32.2167 4.16675 30.8333V27.5C4.16675 26.1167 5.28341 25 6.66675 25H10.0001C11.3834 25 12.5001 26.1167 12.5001 27.5Z"
        stroke="url(#paint4_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.8333 27.5V30.8333C35.8333 32.2167 34.7167 33.3333 33.3333 33.3333H30C29.6333 33.3333 29.3 33.25 28.9833 33.1C28.1167 32.7333 27.5 31.85 27.5 30.8333V27.5C27.5 26.1167 28.6167 25 30 25H33.3333C34.7167 25 35.8333 26.1167 35.8333 27.5Z"
        stroke="url(#paint5_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 9.16699V14.167C25 15.5337 23.8667 16.667 22.5 16.667H17.5C16.1333 16.667 15 15.5337 15 14.167V9.16699C15 7.80033 16.1333 6.66699 17.5 6.66699H22.5C23.8667 6.66699 25 7.80033 25 9.16699Z"
        stroke="url(#paint6_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25 12.8828C27.1833 13.9828 28.9833 15.7828 30.1667 18.0162"
        stroke="url(#paint7_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.38325 24.9995C8.34992 24.7162 8.33325 24.4495 8.33325 24.1662C8.33325 19.1828 11.0499 14.8828 14.9999 12.8828"
        stroke="url(#paint8_linear_2377_120418)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120418"
          x1="2.44264"
          y1="9.10023"
          x2="4.76898"
          y2="11.8631"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120418"
          x1="32.4426"
          y1="9.10023"
          x2="34.769"
          y2="11.8631"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120418"
          x1="25.7759"
          y1="11.7821"
          x2="25.8639"
          y2="12.4792"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120418"
          x1="9.10914"
          y1="11.7821"
          x2="9.19719"
          y2="12.4792"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120418"
          x1="5.13661"
          y1="25.959"
          x2="8.04454"
          y2="29.4126"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120418"
          x1="28.4699"
          y1="25.959"
          x2="31.3778"
          y2="29.4126"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2377_120418"
          x1="16.1638"
          y1="7.81782"
          x2="19.6534"
          y2="11.9621"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2377_120418"
          x1="25.6013"
          y1="13.4736"
          x2="27.3906"
          y2="15.6124"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_2377_120418"
          x1="9.10914"
          y1="14.2772"
          x2="13.0388"
          y2="16.8451"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function CrossChainNFTIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.0832 12.667H6.33317C4.5915 12.667 3.1665 11.242 3.1665 9.50033V6.33366C3.1665 4.59199 4.5915 3.16699 6.33317 3.16699H11.0832C12.8248 3.16699 14.2498 4.59199 14.2498 6.33366V9.50033C14.2498 11.242 12.8248 12.667 11.0832 12.667Z"
        stroke="url(#paint0_linear_2458_94011)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9335 11.0833H27.2335C26.1885 11.0833 25.3335 10.2283 25.3335 9.18332V6.65002C25.3335 5.60502 26.1885 4.75 27.2335 4.75H32.9335C33.9785 4.75 34.8335 5.60502 34.8335 6.65002V9.18332C34.8335 10.2283 33.9785 11.0833 32.9335 11.0833Z"
        stroke="url(#paint1_linear_2458_94011)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9335 22.9583H27.2335C26.1885 22.9583 25.3335 22.1033 25.3335 21.0583V18.525C25.3335 17.48 26.1885 16.625 27.2335 16.625H32.9335C33.9785 16.625 34.8335 17.48 34.8335 18.525V21.0583C34.8335 22.1033 33.9785 22.9583 32.9335 22.9583Z"
        stroke="url(#paint2_linear_2458_94011)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 7.91699H25.3333"
        stroke="url(#paint3_linear_2458_94011)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7915 25.9033V28.5C19.7915 30.2417 21.2165 31.6667 22.9582 31.6667H25.3332"
        stroke="url(#paint4_linear_2458_94011)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7915 7.91699V19.507"
        stroke="url(#paint5_linear_2458_94011)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.3332 19.792H19.7915"
        stroke="url(#paint6_linear_2458_94011)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.9335 34.8333H27.2335C26.1885 34.8333 25.3335 33.9783 25.3335 32.9333V30.4C25.3335 29.355 26.1885 28.5 27.2335 28.5H32.9335C33.9785 28.5 34.8335 29.355 34.8335 30.4V32.9333C34.8335 33.9783 33.9785 34.8333 32.9335 34.8333Z"
        stroke="url(#paint7_linear_2458_94011)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2458_94011"
          x1="4.45642"
          y1="4.26028"
          x2="7.6493"
          y2="8.6843"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2458_94011"
          x1="26.4391"
          y1="5.47886"
          x2="28.3538"
          y2="8.8897"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2458_94011"
          x1="26.4391"
          y1="17.3539"
          x2="28.3538"
          y2="20.7647"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2458_94011"
          x1="15.5399"
          y1="8.03208"
          x2="15.5934"
          y2="8.73626"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2458_94011"
          x1="20.4365"
          y1="26.5666"
          x2="22.4595"
          y2="28.8769"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2458_94011"
          x1="19.9079"
          y1="9.25081"
          x2="20.7403"
          y2="9.3361"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2458_94011"
          x1="20.4365"
          y1="19.9071"
          x2="20.5416"
          y2="20.5993"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2458_94011"
          x1="26.4391"
          y1="29.2289"
          x2="28.3538"
          y2="32.6397"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function CrossChainTokenSwapIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.8335 8.15448V12.4295C34.8335 15.992 33.4085 17.417 29.846 17.417H25.571C22.0085 17.417 20.5835 15.992 20.5835 12.4295V8.15448C20.5835 4.59198 22.0085 3.16699 25.571 3.16699H29.846C33.4085 3.16699 34.8335 4.59198 34.8335 8.15448Z"
        stroke="url(#paint0_linear_2377_120480)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.4165 25.5705V29.8455C17.4165 33.408 15.9915 34.833 12.429 34.833H8.154C4.5915 34.833 3.1665 33.408 3.1665 29.8455V25.5705C3.1665 22.008 4.5915 20.583 8.154 20.583H12.429C15.9915 20.583 17.4165 22.008 17.4165 25.5705Z"
        stroke="url(#paint1_linear_2377_120480)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1855 10.6873C14.8346 10.5448 15.5946 10.4814 16.4337 10.4814H20.5821V12.429C20.5821 15.9914 22.0071 17.4164 25.5696 17.4164H27.5171V21.5647C27.5171 25.8239 25.8229 27.5181 21.5637 27.5181H17.4154V25.5706C17.4154 22.0081 15.9904 20.5831 12.428 20.5831H10.4805V16.4347"
        stroke="url(#paint2_linear_2377_120480)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120480"
          x1="22.242"
          y1="4.80693"
          x2="27.2145"
          y2="10.7126"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120480"
          x1="4.82497"
          y1="22.2229"
          x2="9.79753"
          y2="28.1286"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120480"
          x1="12.4632"
          y1="12.4421"
          x2="18.4082"
          y2="19.5026"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DAOAnalyticsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34.0428 8.47051C34.0428 9.91134 33.362 11.1938 32.317 12.0172C31.557 12.6188 30.5912 12.983 29.5303 12.983C27.0287 12.983 25.0178 10.9722 25.0178 8.47051C25.0178 7.40967 25.382 6.45967 25.9837 5.68384H25.9995C26.807 4.63884 28.0895 3.95801 29.5303 3.95801"
        stroke="url(#paint0_linear_2377_120439)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9833 8.47051C12.9833 10.9722 10.9724 12.983 8.47075 12.983C7.40992 12.983 6.45992 12.6188 5.68409 12.0172C4.63909 11.1938 3.95825 9.91134 3.95825 8.47051C3.95825 5.96884 5.96909 3.95801 8.47075 3.95801C9.91159 3.95801 11.1941 4.63884 12.0174 5.68384C12.6191 6.45967 12.9833 7.40967 12.9833 8.47051Z"
        stroke="url(#paint1_linear_2377_120439)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0428 29.5291C34.0428 32.0308 32.032 34.0416 29.5303 34.0416C28.0895 34.0416 26.807 33.3608 25.9995 32.3158H25.9837C25.382 31.5558 25.0178 30.5899 25.0178 29.5291C25.0178 27.0274 27.0287 25.0166 29.5303 25.0166C30.5912 25.0166 31.5412 25.3808 32.317 25.9824V25.9983C33.362 26.8058 34.0428 28.0883 34.0428 29.5291Z"
        stroke="url(#paint2_linear_2377_120439)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.9833 29.5291C12.9833 30.5899 12.6191 31.5399 12.0174 32.3158C11.1941 33.3766 9.91159 34.0416 8.47075 34.0416C5.96909 34.0416 3.95825 32.0308 3.95825 29.5291C3.95825 28.0883 4.63909 26.8058 5.68409 25.9983V25.9824C6.44409 25.3808 7.40992 25.0166 8.47075 25.0166C10.9724 25.0166 12.9833 27.0274 12.9833 29.5291Z"
        stroke="url(#paint3_linear_2377_120439)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.0416 18.9997C34.0416 21.533 33.4241 23.8922 32.3158 25.9822C31.5558 25.3805 30.5899 25.0163 29.5291 25.0163C27.0274 25.0163 25.0166 27.0272 25.0166 29.5288C25.0166 30.5897 25.3808 31.5397 25.9824 32.3155C23.8924 33.4238 21.5333 34.0413 18.9999 34.0413C16.4824 34.0413 14.1074 33.4238 12.0174 32.3155C12.6191 31.5555 12.9833 30.5897 12.9833 29.5288C12.9833 27.0272 10.9724 25.0163 8.47075 25.0163C7.40992 25.0163 6.45992 25.3805 5.68409 25.9822C4.57575 23.8922 3.95825 21.533 3.95825 18.9997C3.95825 16.4822 4.57575 14.1072 5.68409 12.0172C6.45992 12.6188 7.40992 12.983 8.47075 12.983C10.9724 12.983 12.9833 10.9722 12.9833 8.47051C12.9833 7.40967 12.6191 6.45967 12.0174 5.68384C14.1074 4.57551 16.4824 3.95801 18.9999 3.95801C21.5333 3.95801 23.8924 4.57551 25.9824 5.68384C25.3808 6.44384 25.0166 7.40967 25.0166 8.47051C25.0166 10.9722 27.0274 12.983 29.5291 12.983C30.5899 12.983 31.5399 12.6188 32.3158 12.0172C33.4241 14.1072 34.0416 16.4822 34.0416 18.9997Z"
        stroke="url(#paint4_linear_2377_120439)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120439"
          x1="26.0682"
          y1="4.99663"
          x2="29.2175"
          y2="8.73688"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120439"
          x1="5.00861"
          y1="4.99663"
          x2="8.1579"
          y2="8.73688"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120439"
          x1="26.0682"
          y1="26.0552"
          x2="29.2175"
          y2="29.7955"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120439"
          x1="5.00861"
          y1="26.0552"
          x2="8.1579"
          y2="29.7955"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120439"
          x1="7.45945"
          y1="7.42009"
          x2="17.9571"
          y2="19.8876"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DecentralizedContentDistributionIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.9998 34.8337C27.7443 34.8337 34.8332 27.7448 34.8332 19.0003C34.8332 10.2558 27.7443 3.16699 18.9998 3.16699C10.2553 3.16699 3.1665 10.2558 3.1665 19.0003C3.1665 27.7448 10.2553 34.8337 18.9998 34.8337Z"
        stroke="url(#paint0_linear_2458_94003)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.937 19C11.937 23.8133 12.7128 28.6267 14.2487 33.25H12.6653"
        stroke="url(#paint1_linear_2458_94003)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6671 4.75H14.2505C13.4746 7.06167 12.9046 9.42083 12.5088 11.8117"
        stroke="url(#paint2_linear_2458_94003)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5392 25.9033C25.1433 28.3733 24.5575 30.8433 23.75 33.25"
        stroke="url(#paint3_linear_2458_94003)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.75 4.75C25.2858 9.37333 26.0617 14.1867 26.0617 19"
        stroke="url(#paint4_linear_2458_94003)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 25.3333V23.75C13.9967 26.8375 24.0033 26.8375 33.25 23.75V25.3333"
        stroke="url(#paint5_linear_2458_94003)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 14.2502C13.9967 11.1627 24.0033 11.1627 33.25 14.2502"
        stroke="url(#paint6_linear_2458_94003)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2458_94003"
          x1="6.85198"
          y1="6.81129"
          x2="17.9021"
          y2="19.935"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2458_94003"
          x1="12.2061"
          y1="20.6399"
          x2="14.0809"
          y2="21.0012"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2458_94003"
          x1="12.7115"
          y1="5.56268"
          x2="14.0607"
          y2="5.95789"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2458_94003"
          x1="23.9582"
          y1="26.7488"
          x2="25.347"
          y2="27.1505"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2458_94003"
          x1="24.019"
          y1="6.38993"
          x2="25.8939"
          y2="6.75115"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2458_94003"
          x1="8.06693"
          y1="24.0165"
          x2="8.1786"
          y2="25.6489"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2458_94003"
          x1="8.06693"
          y1="12.2011"
          x2="8.1786"
          y2="13.8335"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DecentralizedGamingIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.1519 19.7285L10.3228 24.5577"
        stroke="url(#paint0_linear_2377_120471)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.3721 19.7754L15.2012 24.6046"
        stroke="url(#paint1_linear_2377_120471)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.4214 22.167H21.4372"
        stroke="url(#paint2_linear_2377_120471)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.6621 22.167H27.6779"
        stroke="url(#paint3_linear_2377_120471)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5415 25.3012V25.2695"
        stroke="url(#paint4_linear_2377_120471)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5415 19.0629V19.0312"
        stroke="url(#paint5_linear_2377_120471)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.1665 23.75C3.1665 31.6667 6.33317 34.8333 14.2498 34.8333H23.7498C31.6665 34.8333 34.8332 31.6667 34.8332 23.75V20.5833C34.8332 12.6667 31.6665 9.5 23.7498 9.5H14.2498C8.31234 9.5 5.05067 11.2733 3.784 15.5167"
        stroke="url(#paint6_linear_2377_120471)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.5999 3.16699L20.5841 4.76616C20.5682 5.63699 19.8716 6.33366 19.0007 6.33366H18.9532C18.0824 6.33366 17.3857 7.04616 17.3857 7.91699C17.3857 8.78783 18.0982 9.50033 18.9691 9.50033H20.5524"
        stroke="url(#paint7_linear_2377_120471)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120471"
          x1="10.8848"
          y1="20.2843"
          x2="12.5699"
          y2="22.2856"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120471"
          x1="10.9341"
          y1="20.3311"
          x2="12.6193"
          y2="22.3325"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120471"
          x1="21.4232"
          y1="22.2821"
          x2="21.4365"
          y2="22.2823"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120471"
          x1="27.664"
          y1="22.2821"
          x2="27.6773"
          y2="22.2823"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120471"
          x1="24.6579"
          y1="25.2732"
          x2="24.6585"
          y2="25.2956"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120471"
          x1="24.6579"
          y1="19.0349"
          x2="24.6585"
          y2="19.0573"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2377_120471"
          x1="6.85198"
          y1="12.4154"
          x2="15.1657"
          y2="24.7576"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2377_120471"
          x1="17.7598"
          y1="3.89585"
          x2="19.743"
          y2="5.09116"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DecentralizedIdentityIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.6665 20.5203V23.7503C3.6665 31.667 6.83317 34.8337 14.7498 34.8337H24.2498C32.1665 34.8337 35.3332 31.667 35.3332 23.7503V14.2503C35.3332 6.33366 32.1665 3.16699 24.2498 3.16699H14.7498C6.83317 3.16699 3.6665 6.33366 3.6665 14.2503"
        stroke="url(#paint0_linear_2377_120456)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.25 16.4347L26.0233 14.6455"
        stroke="url(#paint1_linear_2377_120456)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.978 19L17.3164 23.3542L21.3539 19.3325"
        stroke="url(#paint2_linear_2377_120456)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120456"
          x1="7.35198"
          y1="6.81129"
          x2="18.4021"
          y2="19.935"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120456"
          x1="24.4564"
          y1="14.8514"
          x2="25.0816"
          y2="15.5874"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120456"
          x1="13.9528"
          y1="19.5011"
          x2="15.0856"
          y2="22.0891"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DecentralizedSocialMediaIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.4165 12.667C11.0398 12.667 13.1665 10.5403 13.1665 7.91699C13.1665 5.29365 11.0398 3.16699 8.4165 3.16699C5.79316 3.16699 3.6665 5.29365 3.6665 7.91699C3.6665 10.5403 5.79316 12.667 8.4165 12.667Z"
        stroke="url(#paint0_linear_2377_120484)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5835 23.75C33.2069 23.75 35.3335 21.6234 35.3335 19C35.3335 16.3766 33.2069 14.25 30.5835 14.25C27.9601 14.25 25.8335 16.3766 25.8335 19C25.8335 21.6234 27.9601 23.75 30.5835 23.75Z"
        stroke="url(#paint1_linear_2377_120484)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.4165 34.833C11.0398 34.833 13.1665 32.7064 13.1665 30.083C13.1665 27.4596 11.0398 25.333 8.4165 25.333C5.79316 25.333 3.6665 27.4596 3.6665 30.083C3.6665 32.7064 5.79316 34.833 8.4165 34.833Z"
        stroke="url(#paint2_linear_2377_120484)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4998 19.0003H14.7498C11.2665 19.0003 8.4165 17.417 8.4165 12.667V25.3337"
        stroke="url(#paint3_linear_2377_120484)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120484"
          x1="4.77215"
          y1="4.26028"
          x2="8.08719"
          y2="8.19739"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120484"
          x1="26.9391"
          y1="15.3433"
          x2="30.2542"
          y2="19.2804"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120484"
          x1="4.77215"
          y1="26.4263"
          x2="8.08719"
          y2="30.3634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120484"
          x1="10.0556"
          y1="14.1247"
          x2="14.3732"
          y2="19.8261"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function DEXEngagementIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.5833 25.3333V10.2917C30.5833 8.55 29.1583 7.125 27.4166 7.125H18.7083"
        stroke="url(#paint0_linear_2377_120386)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.6667 3.16699L17.9167 7.12533L22.6667 11.0837"
        stroke="url(#paint1_linear_2377_120386)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.5833 34.833C33.2067 34.833 35.3333 32.7064 35.3333 30.083C35.3333 27.4596 33.2067 25.333 30.5833 25.333C27.9599 25.333 25.8333 27.4596 25.8333 30.083C25.8333 32.7064 27.9599 34.833 30.5833 34.833Z"
        stroke="url(#paint2_linear_2377_120386)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.41669 19V27.7083C8.41669 29.45 9.84169 30.875 11.5834 30.875H20.2917"
        stroke="url(#paint3_linear_2377_120386)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.3333 34.8337L21.0833 30.8753L16.3333 26.917"
        stroke="url(#paint4_linear_2377_120386)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.41669 12.667C11.04 12.667 13.1667 10.5403 13.1667 7.91699C13.1667 5.29365 11.04 3.16699 8.41669 3.16699C5.79334 3.16699 3.66669 5.29365 3.66669 7.91699C3.66669 10.5403 5.79334 12.667 8.41669 12.667Z"
        stroke="url(#paint5_linear_2377_120386)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120386"
          x1="20.0904"
          y1="9.22047"
          x2="26.3335"
          y2="14.0562"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120386"
          x1="18.4695"
          y1="4.07807"
          x2="21.1194"
          y2="5.96635"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120386"
          x1="26.939"
          y1="26.4263"
          x2="30.254"
          y2="30.3634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120386"
          x1="9.79874"
          y1="20.3666"
          x2="13.9425"
          y2="25.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120386"
          x1="16.8861"
          y1="27.8281"
          x2="19.536"
          y2="29.7164"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120386"
          x1="4.77233"
          y1="4.26028"
          x2="8.08737"
          y2="8.19739"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function GovernanceAnalyticsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="37"
      height="36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M27.4561 33H33.5011"
        stroke="url(#paint0_linear_2377_120413)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.5 3V28.5C3.5 30.99 5.51 33 8 33H21.47"
        stroke="url(#paint1_linear_2377_120413)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.4854 12.27L32.0004 10.5"
        stroke="url(#paint2_linear_2377_120413)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 25.4998L14.885 17.4598C16.025 16.1398 18.05 16.0498 19.28 17.2948L20.705 18.7198C21.935 19.9498 23.96 19.8748 25.1 18.5548L26.825 16.5298"
        stroke="url(#paint3_linear_2377_120413)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120413"
          x1="28.1596"
          y1="33.1151"
          x2="28.2564"
          y2="33.8099"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120413"
          x1="5.59141"
          y1="6.45249"
          x2="15.6276"
          y2="13.5923"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120413"
          x1="30.6617"
          y1="10.7037"
          x2="31.2884"
          y2="11.3408"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120413"
          x1="10.1909"
          y1="17.4588"
          x2="12.4358"
          y2="22.9821"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function HistoricalPriceIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.5416 21.7707C15.5416 23.3066 16.7291 24.5416 18.1858 24.5416H21.1625C22.4291 24.5416 23.4583 23.4649 23.4583 22.1191C23.4583 20.6782 22.825 20.1557 21.8908 19.8232L17.125 18.1607C16.1908 17.8282 15.5575 17.3216 15.5575 15.8649C15.5575 14.5349 16.5866 13.4424 17.8533 13.4424H20.83C22.2866 13.4424 23.4741 14.6774 23.4741 16.2132"
        stroke="url(#paint0_linear_2377_120401)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 11.875V26.125"
        stroke="url(#paint1_linear_2377_120401)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 3.16699C10.76 3.16699 3.66663 10.2603 3.66663 19.0003C3.66663 25.2387 7.27664 30.6378 12.5333 33.2187"
        stroke="url(#paint2_linear_2377_120401)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3333 19C35.3333 27.74 28.24 34.8333 19.5 34.8333"
        stroke="url(#paint3_linear_2377_120401)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3333 9.50033V3.16699H29"
        stroke="url(#paint4_linear_2377_120401)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4166 11.0837L35.3333 3.16699"
        stroke="url(#paint5_linear_2377_120401)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120401"
          x1="16.4648"
          y1="14.7197"
          x2="20.3431"
          y2="18.0116"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120401"
          x1="19.6164"
          y1="13.5149"
          x2="20.4517"
          y2="13.5846"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120401"
          x1="5.50936"
          y1="6.62543"
          x2="15.0802"
          y2="12.6142"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120401"
          x1="21.3427"
          y1="20.8221"
          x2="26.8678"
          y2="27.384"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120401"
          x1="29.7371"
          y1="3.89585"
          x2="31.9471"
          y2="6.52059"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120401"
          x1="28.338"
          y1="4.07807"
          x2="31.1005"
          y2="7.35899"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function LiquidityPoolOptimizationIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="43"
      height="42"
      viewBox="0 0 43 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 14.875C4 8.75 7.5 6.125 12.75 6.125H30.25C35.5 6.125 39 8.75 39 14.875V27.125C39 33.25 35.5 35.875 30.25 35.875H12.75C7.5 35.875 4 33.25 4 27.125V21.9276"
        stroke="url(#paint0_linear_2377_120366)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.75 21C26.75 18.095 24.405 15.75 21.5 15.75C18.595 15.75 16.25 18.095 16.25 21C16.25 23.905 18.595 26.25 21.5 26.25C21.9725 26.25 22.4275 26.1975 22.8475 26.075"
        stroke="url(#paint1_linear_2377_120366)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.125 16.625V25.375"
        stroke="url(#paint2_linear_2377_120366)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.875 16.625V25.375"
        stroke="url(#paint3_linear_2377_120366)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120366"
          x1="8.07342"
          y1="9.54872"
          x2="18.0455"
          y2="23.4821"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120366"
          x1="17.472"
          y1="16.9584"
          x2="21.136"
          y2="21.3099"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120366"
          x1="10.2414"
          y1="17.632"
          x2="11.0673"
          y2="17.7441"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120366"
          x1="32.9914"
          y1="17.632"
          x2="33.8173"
          y2="17.7441"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function MarketplaceInsightsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.26538 24.8738C5.26538 31.983 8.11538 34.833 15.2245 34.833H23.7588C30.8679 34.833 33.7179 31.983 33.7179 24.8738V17.7646"
        stroke="url(#paint0_linear_2377_120445)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5009 19.0003C22.3984 19.0003 24.5359 16.6412 24.2509 13.7437L23.2059 3.16699H15.8117L14.7508 13.7437C14.4658 16.6412 16.6034 19.0003 19.5009 19.0003Z"
        stroke="url(#paint1_linear_2377_120445)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.492 19.0003C32.6903 19.0003 35.0337 16.4037 34.717 13.2212L34.2737 8.86699C33.7037 4.75033 32.1203 3.16699 27.972 3.16699H23.1428L24.2512 14.2662C24.5203 16.8787 26.8795 19.0003 29.492 19.0003Z"
        stroke="url(#paint2_linear_2377_120445)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.43067 19.0003C12.0432 19.0003 14.4023 16.8787 14.6557 14.2662L15.004 10.767L15.764 3.16699H10.9348C6.7865 3.16699 5.20317 4.75033 4.63317 8.86699L4.20567 13.2212C3.889 16.4037 6.23234 19.0003 9.43067 19.0003Z"
        stroke="url(#paint3_linear_2377_120445)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5001 26.917C16.8559 26.917 15.5417 28.2312 15.5417 30.8753V34.8337H23.4584V30.8753C23.4584 28.2312 22.1442 26.917 19.5001 26.917Z"
        stroke="url(#paint4_linear_2377_120445)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120445"
          x1="8.57678"
          y1="19.7289"
          x2="13.4416"
          y2="29.3603"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120445"
          x1="15.8369"
          y1="4.98914"
          x2="21.1459"
          y2="8.7926"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120445"
          x1="24.4932"
          y1="4.98914"
          x2="30.0465"
          y2="9.82232"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120445"
          x1="5.52554"
          y1="4.98914"
          x2="11.0778"
          y2="9.81481"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120445"
          x1="16.4631"
          y1="27.8281"
          x2="19.2257"
          y2="31.109"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function NFTCollectionDataAnalyticsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.4998 26.9167V30.685C19.4998 33.6458 18.3123 34.8333 15.3357 34.8333H7.81484C4.854 34.8333 3.6665 33.6458 3.6665 30.685V23.1642C3.6665 20.1875 4.854 19 7.81484 19H11.5832V22.7525C11.5832 25.7292 12.7707 26.9167 15.7315 26.9167H19.4998Z"
        stroke="url(#paint0_linear_2377_120508)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.4168 18.9997V22.7522C27.4168 25.7288 26.2293 26.9163 23.2527 26.9163H15.7318C12.771 26.9163 11.5835 25.7288 11.5835 22.7522V15.2313C11.5835 12.2705 12.771 11.083 15.7318 11.083H19.5002V14.8355C19.5002 17.8122 20.6877 18.9997 23.6485 18.9997H27.4168Z"
        stroke="url(#paint1_linear_2377_120508)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.3333 14.8362C35.3333 17.8128 34.1458 19.0003 31.1692 19.0003H23.6483C20.6875 19.0003 19.5 17.8128 19.5 14.8362V7.31533C19.5 4.35449 20.6875 3.16699 23.6483 3.16699H31.1692C34.1458 3.16699 35.3333 4.35449 35.3333 7.31533"
        stroke="url(#paint2_linear_2377_120508)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120508"
          x1="5.50924"
          y1="20.8221"
          x2="11.0343"
          y2="27.384"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120508"
          x1="13.4262"
          y1="12.9052"
          x2="18.9513"
          y2="19.467"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120508"
          x1="21.3427"
          y1="4.98914"
          x2="26.8678"
          y2="11.551"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function NFTMarketplaceDataIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0001 36.6663C25.9834 36.6663 30.8334 32.4497 30.8334 27.2497V21.083C30.8334 26.283 25.9834 29.9997 20.0001 29.9997C14.0167 29.9997 9.16675 26.283 9.16675 21.083V27.2497C9.16675 30.0997 10.6167 32.6497 12.9167 34.3663"
        stroke="url(#paint0_linear_2377_120409)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8334 12.7497C30.8334 14.2663 30.4167 15.6663 29.6834 16.8663C27.9001 19.7997 24.2334 21.6663 20.0001 21.6663C15.7667 21.6663 12.1001 19.7997 10.3167 16.8663C9.58341 15.6663 9.16675 14.2663 9.16675 12.7497C9.16675 10.1497 10.3834 7.79967 12.3334 6.09967C14.3001 4.38301 17.0001 3.33301 20.0001 3.33301C23.0001 3.33301 25.7001 4.38301 27.6667 6.08301C29.6167 7.79967 30.8334 10.1497 30.8334 12.7497Z"
        stroke="url(#paint1_linear_2377_120409)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.8334 12.7497V21.083C30.8334 26.283 25.9834 29.9997 20.0001 29.9997C14.0167 29.9997 9.16675 26.283 9.16675 21.083V12.7497C9.16675 7.54967 14.0167 3.33301 20.0001 3.33301C23.0001 3.33301 25.7001 4.38301 27.6667 6.08301C29.6167 7.79967 30.8334 10.1497 30.8334 12.7497Z"
        stroke="url(#paint2_linear_2377_120409)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120409"
          x1="11.6884"
          y1="22.8764"
          x2="16.5787"
          y2="30.9517"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120409"
          x1="11.6884"
          y1="5.44286"
          x2="17.8246"
          y2="14.0556"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120409"
          x1="11.6884"
          y1="6.40189"
          x2="21.1257"
          y2="15.5086"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function NFTMetadataQueriesIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M28.5 11.337C28.405 11.3212 28.2941 11.3212 28.1991 11.337C26.0141 11.2578 24.2725 9.46866 24.2725 7.25199C24.2725 4.98783 26.0933 3.16699 28.3575 3.16699C30.6216 3.16699 32.4425 5.00366 32.4425 7.25199C32.4266 9.46866 30.685 11.2578 28.5 11.337Z"
        stroke="url(#paint0_linear_2377_120512)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.8683 22.8629C29.0375 23.2271 31.4283 22.8471 33.1066 21.7229C35.3391 20.2346 35.3391 17.7962 33.1066 16.3079C31.4125 15.1838 28.99 14.8038 26.8208 15.1838"
        stroke="url(#paint1_linear_2377_120512)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.45031 11.337C9.54531 11.3212 9.65615 11.3212 9.75115 11.337C11.9361 11.2578 13.6778 9.46866 13.6778 7.25199C13.6778 4.98783 11.857 3.16699 9.59281 3.16699C7.32865 3.16699 5.50781 5.00366 5.50781 7.25199C5.52365 9.46866 7.26531 11.2578 9.45031 11.337Z"
        stroke="url(#paint2_linear_2377_120512)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0826 22.8629C8.91346 23.2271 6.52263 22.8471 4.8443 21.7229C2.6118 20.2346 2.6118 17.7962 4.8443 16.3079C6.53846 15.1838 8.96096 14.8038 11.1301 15.1838"
        stroke="url(#paint3_linear_2377_120512)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 23.1642C18.905 23.1484 18.7942 23.1484 18.6992 23.1642C16.5142 23.085 14.7725 21.2959 14.7725 19.0792C14.7725 16.815 16.5933 14.9941 18.8575 14.9941C21.1217 14.9941 22.9425 16.8309 22.9425 19.0792C22.9267 21.2959 21.185 23.1009 19 23.1642Z"
        stroke="url(#paint4_linear_2377_120512)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.6086 28.1513C21.0911 26.473 16.9269 26.473 14.3936 28.1513C12.1611 29.6397 12.1611 32.078 14.3936 33.5663C16.9269 35.2605 21.0753 35.2605 23.6086 33.5663"
        stroke="url(#paint5_linear_2377_120512)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120512"
          x1="25.2233"
          y1="4.10722"
          x2="28.0742"
          y2="7.49313"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120512"
          x1="27.7472"
          y1="15.9566"
          x2="30.5231"
          y2="19.2552"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120512"
          x1="6.45866"
          y1="4.10722"
          x2="9.3096"
          y2="7.49313"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120512"
          x1="4.09636"
          y1="15.9566"
          x2="6.87219"
          y2="19.2552"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120512"
          x1="15.7233"
          y1="15.9344"
          x2="18.5743"
          y2="19.3203"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120512"
          x1="13.9866"
          y1="27.8068"
          x2="16.496"
          y2="31.8919"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function RealEstateTokenizationIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.1665 34.833H34.8332"
        stroke="url(#paint0_linear_2377_120464)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.75 15.7858C4.75 14.82 5.20917 13.9017 5.96917 13.3L17.0525 4.67086C18.1925 3.78419 19.7917 3.78419 20.9475 4.67086L32.0308 13.2842C32.8067 13.8859 33.25 14.8041 33.25 15.7858V34.8333"
        stroke="url(#paint1_linear_2377_120464)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66943 34.833L4.71695 22.2139"
        stroke="url(#paint2_linear_2377_120464)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5418 17.417H13.4585C12.1443 17.417 11.0835 18.4778 11.0835 19.792V34.8337H26.9168V19.792C26.9168 18.4778 25.856 17.417 24.5418 17.417Z"
        stroke="url(#paint3_linear_2377_120464)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.8335 25.7295V28.1045"
        stroke="url(#paint4_linear_2377_120464)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.625 11.875H21.375"
        stroke="url(#paint5_linear_2377_120464)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120464"
          x1="6.85198"
          y1="34.9481"
          x2="6.8708"
          y2="35.6558"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120464"
          x1="8.06693"
          y1="7.55358"
          x2="18.9362"
          y2="19.4878"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120464"
          x1="4.67496"
          y1="23.6661"
          x2="4.71493"
          y2="23.6663"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120464"
          x1="12.9262"
          y1="19.4214"
          x2="19.0758"
          y2="26.061"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120464"
          x1="15.9499"
          y1="26.0028"
          x2="16.6228"
          y2="26.3393"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120464"
          x1="17.1778"
          y1="11.9901"
          x2="17.2995"
          y2="12.6768"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function RebasingMechanismIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.66669 34.833H35.3334"
        stroke="url(#paint0_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 3.16699C22.0333 4.18033 24.8833 4.18033 27.4167 3.16699V7.91699C24.8833 8.93032 22.0333 8.93032 19.5 7.91699V3.16699Z"
        stroke="url(#paint1_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 7.91699V12.667"
        stroke="url(#paint2_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.83331 25.1911V34.8337H32.1666V17.417C32.1666 14.2503 30.5833 12.667 27.4166 12.667H11.5833C8.41665 12.667 6.83331 14.2503 6.83331 17.417V19.0003"
        stroke="url(#paint3_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.83331 19H31.2483"
        stroke="url(#paint4_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.1508 19V34.8333"
        stroke="url(#paint5_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M19.4842 19V34.8333"
        stroke="url(#paint6_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <path
        d="M25.8175 19V34.8333"
        stroke="url(#paint7_linear_2377_120374)"
        strokeMiterlimit="10"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120374"
          x1="7.35216"
          y1="34.9481"
          x2="7.37098"
          y2="35.6558"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120374"
          x1="20.4214"
          y1="3.8011"
          x2="22.1237"
          y2="6.70594"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120374"
          x1="19.6164"
          y1="8.46364"
          x2="20.408"
          y2="8.66158"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120374"
          x1="9.78169"
          y1="15.218"
          x2="17.2789"
          y2="25.394"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120374"
          x1="9.67481"
          y1="19.1151"
          x2="9.69921"
          y2="19.8225"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120374"
          x1="13.2672"
          y1="20.8221"
          x2="14.1036"
          y2="20.8849"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_2377_120374"
          x1="19.6006"
          y1="20.8221"
          x2="20.437"
          y2="20.8849"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_2377_120374"
          x1="25.9339"
          y1="20.8221"
          x2="26.7703"
          y2="20.8849"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function SupplyChainTransparencyIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.47086 9.60795L5.77336 10.5879C4.81086 11.1479 4.00586 12.5129 4.00586 13.6329V30.6605C4.00586 33.9855 6.36836 35.3505 9.23836 33.7055L13.3509 31.3605C14.2434 30.853 15.7309 30.8005 16.6584 31.273L25.8459 35.8755C26.7734 36.3305 28.2609 36.2955 29.1534 35.788L36.7309 31.448C37.6934 30.888 38.4984 29.523 38.4984 28.403V11.3579C38.4984 8.03295 36.1359 6.66795 33.2659 8.31295L29.1534 10.6579C28.2609 11.1654 26.7734 11.2179 25.8459 10.7454L16.6584 6.16045C15.7309 5.70545 14.2434 5.74045 13.3509 6.24795"
        stroke="url(#paint0_linear_2377_120460)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.981 7V29.75"
        stroke="url(#paint1_linear_2377_120460)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.5283 11.585V34.9999"
        stroke="url(#paint2_linear_2377_120460)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120460"
          x1="8.02022"
          y1="9.33484"
          x2="18.303"
          y2="23.213"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120460"
          x1="15.0973"
          y1="9.61814"
          x2="15.9362"
          y2="9.66193"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120460"
          x1="27.6447"
          y1="14.2796"
          x2="28.4837"
          y2="14.3222"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function TradingStrategyOptimizationIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.25 4.75V31.25C5.25 32.3546 6.14543 33.25 7.25 33.25H33.75M21.0833 26.9167V14.25M29 26.9167V7.91667M13.1667 26.9167V22.1667"
        stroke="url(#paint0_linear_2377_120393)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120393"
          x1="8.56693"
          y1="8.02987"
          x2="18.512"
          y2="19.8412"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function WalletAnalyticsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="39"
      height="38"
      viewBox="0 0 39 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.4584 25.333H15.5417"
        stroke="url(#paint0_linear_2377_120432)"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.9238 3.9903L20.8763 4.10114L16.2847 14.757H11.7722C10.6955 14.757 9.66634 14.9786 8.73218 15.3745L11.503 8.75614L11.5663 8.5978L11.6772 8.34447C11.7088 8.24947 11.7405 8.15447 11.788 8.0753C13.8622 3.2778 16.2055 2.1853 20.9238 3.9903Z"
        stroke="url(#paint1_linear_2377_120432)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M29.4602 15.0736C28.7477 14.8519 27.9877 14.7569 27.2277 14.7569H16.2869L20.8785 4.10107L20.926 3.99023C21.1635 4.0694 21.3852 4.18023 21.6227 4.27523L25.1219 5.74773C27.0694 6.55523 28.431 7.3944 29.2544 8.40773C29.4127 8.59773 29.5394 8.7719 29.6502 8.97773C29.7927 9.1994 29.9035 9.42107 29.9669 9.65857C30.0302 9.80107 30.0777 9.94357 30.1094 10.0702C30.5369 11.4002 30.2835 13.0311 29.4602 15.0736Z"
        stroke="url(#paint2_linear_2377_120432)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.5 34.8335H13.325C12.945 34.8335 12.565 34.8019 12.2008 34.7544C7.16583 34.4219 4.47417 31.7302 4.14167 26.6952C4.09417 26.331 4.0625 25.9511 4.0625 25.5711V22.4835C4.0625 19.301 5.99417 16.5619 8.74917 15.3743C9.69917 14.9785 10.7125 14.7568 11.7892 14.7568H27.2425C28.0183 14.7568 28.7783 14.8677 29.475 15.0735C32.6417 16.0235 34.9533 18.9844 34.9533 22.4677V25.5552C34.9533 25.8719 34.9375 26.1886 34.9217 26.5052C34.6208 32.0469 31.5333 34.8335 25.675 34.8335"
        stroke="url(#paint3_linear_2377_120432)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5046 8.75586L8.73379 15.3742C5.97879 16.5616 4.04712 19.3008 4.04712 22.4833V17.8441C4.04712 13.3475 7.24545 9.59503 11.5046 8.75586Z"
        stroke="url(#paint4_linear_2377_120432)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.9547 17.844V22.4832C34.9547 18.9999 32.643 16.039 29.4763 15.089C30.2997 13.0307 30.5372 11.4157 30.1413 10.0699C30.1097 9.92737 30.0622 9.78487 29.9988 9.6582C32.9438 11.1782 34.9547 14.2974 34.9547 17.844Z"
        stroke="url(#paint5_linear_2377_120432)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2377_120432"
          x1="16.4631"
          y1="25.4481"
          x2="16.5376"
          y2="26.1484"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2377_120432"
          x1="10.1511"
          y1="4.57446"
          x2="14.4106"
          y2="9.62797"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2377_120432"
          x1="17.9194"
          y1="5.26574"
          x2="21.5395"
          y2="10.7071"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2377_120432"
          x1="7.65768"
          y1="17.0673"
          x2="13.6458"
          y2="28.0097"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_2377_120432"
          x1="4.91505"
          y1="10.3357"
          x2="9.34419"
          y2="13.1933"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_2377_120432"
          x1="30.1139"
          y1="11.1341"
          x2="33.7788"
          y2="12.9934"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function TokenAnalyticsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M29.6367 29.3942L33.1517 27.6367V23.235L29.6367 29.3942ZM22.5909 15.3183L19.0759 17.0758L22.5909 15.3183ZM19.0759 17.0758L15.5608 15.3183L19.0759 17.0758ZM19.0759 17.0758V21.4775V17.0758ZM33.1517 10.03L29.6367 11.7875L33.1517 10.03ZM33.1517 10.03L29.6367 8.2725L33.1517 10.03ZM33.1517 10.03V14.4317V10.03ZM22.5909 4.7575L19.0759 3L15.5608 4.7575H22.5909ZM5 10.03L8.515 8.2725L5 10.03ZM5 10.03L8.515 11.7875L5 10.03ZM5 10.03V14.4317V10.03ZM19.0759 34.6667L15.5608 32.9092L19.0759 34.6667ZM19.0759 34.6667L22.5909 32.9092L19.0759 34.6667ZM19.0759 34.6667V30.265V34.6667ZM8.515 29.3942L5 27.6367V23.235L8.515 29.3942Z"
        stroke="url(#paint0_linear_2641_101644)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2641_101644"
          x1="8.27639"
          y1="6.6443"
          x2="19.4738"
          y2="18.4668"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function UserAnalyticsIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M24.8256 6.27033C25.5856 7.39449 26.0289 8.74033 26.0289 10.197C26.0131 13.997 23.0206 17.0845 19.2522 17.2112C19.0939 17.1953 18.9039 17.1953 18.7297 17.2112C15.2306 17.1003 12.3964 14.4245 12.0164 11.0045C11.5573 6.93533 14.8981 3.16699 18.9831 3.16699"
        stroke="url(#paint0_linear_2641_101641)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.0686 23.053C7.23691 25.618 7.23691 29.798 11.0686 32.3472C15.4227 35.2605 22.5636 35.2605 26.9178 32.3472C30.7494 29.7822 30.7494 25.6022 26.9178 23.053C22.5953 20.1555 15.4544 20.1555 11.0686 23.053Z"
        stroke="url(#paint1_linear_2641_101641)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2641_101641"
          x1="13.6099"
          y1="4.78324"
          x2="18.51"
          y2="10.6072"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2641_101641"
          x1="10.7083"
          y1="22.451"
          x2="14.7187"
          y2="29.9856"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function PortfolioManagementIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M23.595 3C21.21 3 20.25 3.9 20.25 6.15V16.35C20.25 18.6 21.21 19.5 23.595 19.5H29.655C32.04 19.5 33 18.6 33 16.35V6.15C33 3.9 32.04 3 29.655 3"
        stroke="url(#paint0_linear_2641_101646)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33 29.85V27.15C33 24.9 32.04 24 29.655 24H23.595C21.21 24 20.25 24.9 20.25 27.15V29.85C20.25 32.1 21.21 33 23.595 33H29.655C32.04 33 33 32.1 33 29.85Z"
        stroke="url(#paint1_linear_2641_101646)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.345 33C3.96 33 3 32.1 3 29.85V19.65C3 17.4 3.96 16.5 6.345 16.5H12.405C14.79 16.5 15.75 17.4 15.75 19.65V29.85C15.75 32.1 14.79 33 12.405 33"
        stroke="url(#paint2_linear_2641_101646)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.75 6.15V8.85C15.75 11.1 14.79 12 12.405 12H6.345C3.96 12 3 11.1 3 8.85V6.15C3 3.9 3.96 3 6.345 3H12.405C14.79 3 15.75 3.9 15.75 6.15Z"
        stroke="url(#paint3_linear_2641_101646)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2641_101646"
          x1="21.7339"
          y1="4.89887"
          x2="27.5555"
          y2="10.2415"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2641_101646"
          x1="21.7339"
          y1="25.0357"
          x2="24.5335"
          y2="29.7461"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2641_101646"
          x1="4.48389"
          y1="18.3989"
          x2="10.3055"
          y2="23.7415"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2641_101646"
          x1="4.48389"
          y1="4.03575"
          x2="7.28347"
          y2="8.74605"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export function TokenGateCryptoIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="38"
      height="38"
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M30.0832 25.3333V10.2917C30.0832 8.55 28.6582 7.125 26.9165 7.125H23.75"
        stroke="url(#paint0_linear_2641_101941)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.91667 25.3333V10.2917C7.91667 8.55 9.34167 7.125 11.0833 7.125H14.25"
        stroke="url(#paint1_linear_2641_101941)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.9165 34.833C10.5399 34.833 12.6665 32.7064 12.6665 30.083C12.6665 27.4597 10.5399 25.333 7.9165 25.333C5.29315 25.333 3.1665 27.4597 3.1665 30.083C3.1665 32.7064 5.29315 34.833 7.9165 34.833Z"
        stroke="url(#paint2_linear_2641_101941)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M30.0835 34.833C32.7068 34.833 34.8335 32.7064 34.8335 30.083C34.8335 27.4597 32.7068 25.333 30.0835 25.333C27.4601 25.333 25.3335 27.4597 25.3335 30.083C25.3335 32.7064 27.4601 34.833 30.0835 34.833Z"
        stroke="url(#paint3_linear_2641_101941)"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2641_101941"
          x1="24.4871"
          y1="9.22047"
          x2="29.0377"
          y2="11.1003"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2641_101941"
          x1="13.5129"
          y1="9.22047"
          x2="8.96218"
          y2="11.1004"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_2641_101941"
          x1="4.27215"
          y1="26.4263"
          x2="7.58719"
          y2="30.3634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_2641_101941"
          x1="26.4391"
          y1="26.4263"
          x2="29.7542"
          y2="30.3634"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1249" stopColor="#D274F7" />
          <stop offset="1" stopColor="#9A74F7" />
        </linearGradient>
      </defs>
    </svg>
  );
}
