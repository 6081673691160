import ShortlistView from './ShortlistView';
import SessionPanel from './SessionPanel';

export function AdminPanel() {
  return (
    <div className="md:py-[60px] py-[36px] mt-5 flex flex-col gap-[60px]">
      <SessionPanel />
      <ShortlistView />
    </div>
  );
}
