import { Dialog } from '@headlessui/react';
import { useCallback } from 'react';
import { ClassNames, HTMLAttributes, Setter } from 'types';
import { classes } from 'utils';
import { CrossIcon } from 'components';

export interface Props extends HTMLAttributes<HTMLDivElement> {
  classNames?: ClassNames<'closeIcon' | 'content' | 'modalBase'>;
  isOpen: boolean;
  setIsOpen?: Setter<boolean>;
  isCloseInside?: boolean;
  isCloseHidden?: boolean;
}

export function Modal({
  children,
  className,
  classNames,
  isOpen,
  setIsOpen,
  isCloseInside = false,
  isCloseHidden,
}: Props) {
  const onClose = useCallback(() => !!setIsOpen && setIsOpen(false), [setIsOpen]);

  return (
    <Dialog
      open={isOpen}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClose={() => {}}
      className={classes("relative z-[100] font-['Chakra_Petch']")}
    >
      <div className="fixed inset-0 bg-[#000000B2] backdrop-blur-sm" aria-hidden="true" />

      <div
        className={classes(
          'fixed inset-0 flex items-center justify-center p-4',
          classNames?.modalBase
        )}
      >
        <Dialog.Panel
          className={classes(
            'relative mx-auto flex max-w-xl flex-col p-6 text-white bg-black rounded-[10px] border-[0.5px] border-solid border-steel-gray [box-shadow:0px_4px_10px_0px_rgba(255,_255,_255,_0.03)]',
            classNames?.base,
            className
          )}
        >
          {!!setIsOpen && !isCloseHidden && (
            <button
              className={classes(
                'absolute [outline:none]',
                isCloseInside
                  ? 'right-[25px] md:top-[25px] top-[19px]'
                  : '-top-[42px] right-0 flex h-8 w-8 rounded-full bg-[#ffffff1a] items-center justify-center hover:bg-gray',
                classNames?.closeIcon
              )}
              onClick={onClose}
            >
              <CrossIcon />
            </button>
          )}
          <div
            className={classes(
              'flex h-full w-full flex-1 flex-col items-center justify-center',
              classNames?.content
            )}
          >
            {children}
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
