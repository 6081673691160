import React from 'react';
import { CopyButton } from './CopyButton';
import { ClassNames } from 'types';
import { classes } from 'utils';

export function ReferralLink({
  referralCode,
  classNames,
}: {
  referralCode: string;
  classNames?: ClassNames<'copyButton' | 'text'>;
}) {
  return (
    <div
      className={classes(
        'bg-black w-full flex justify-between gap-[13px] md:rounded-lg rounded-md md:py-[9px] py-2 px-3 md:mt-5 mt-3 border-[0.5px] border-solid border-[#393939] text-sm shadow-primary-shadow',
        classNames?.base
      )}
    >
      <div
        className={classes(
          '[-webkit-line-clamp:1] [-webkit-box-orient:vertical] [display:-webkit-box] break-all overflow-hidden whitespace-pre-line',
          classNames?.text
        )}
      >
        {document.location.origin}/#/?signup&referral={referralCode}
      </div>
      <CopyButton
        value={`${document.location.origin}/#/?signup&referral=${referralCode}`}
        id="referral-copy"
        className={classes(classNames?.copyButton)}
        gradientIcon
      />
    </div>
  );
}
