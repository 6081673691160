import { HTMLAttributes } from 'types';

export function ValidatorAddressIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="vuesax/broken/link">
        <g id="link">
          <path
            id="Vector"
            d="M9.00001 8.00033C9.00001 10.1203 7.28668 11.8337 5.16668 11.8337C3.04668 11.8337 1.33334 10.1203 1.33334 8.00033C1.33334 5.88033 3.04668 4.16699 5.16668 4.16699"
            stroke="white"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_2"
            d="M14.6667 8C14.6667 10.2067 12.8733 12 10.6667 12"
            stroke="white"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector_3"
            d="M6.66666 8C6.66666 5.79333 8.45999 4 10.6667 4C11.6867 4 12.62 4.38 13.32 5.00667"
            stroke="white"
            strokeWidth="0.8"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
}

export function ValidatorActiveIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      {...props}
    >
      <circle cx="4" cy="4" r="3.75" stroke="#75FF83" strokeWidth="0.5" />
      <circle cx="4" cy="4" r="1.5" fill="#75FF83" stroke="#75FF83" />
    </svg>
  );
}
export function ValidatorInActiveIcon(props: HTMLAttributes<HTMLOrSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      {...props}
    >
      <circle cx="4" cy="4" r="3.75" stroke="#ff0000" strokeWidth="0.5" />
      <circle cx="4" cy="4" r="1.5" fill="#ff0000" stroke="#ff0000" />
    </svg>
  );
}
